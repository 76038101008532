import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemAnnouncements } from '@src/app/cprs/shared/interfaces/system-announcements';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { EnvService } from '../../shared/services/env/env.service';

@Injectable({
  providedIn: 'root',
})
export class SystemAnnouncementsService {
  public baseUrl: string;

  constructor(private http: HttpClient, private envService: EnvService) {
    this.baseUrl = this.envService.systemAnnouncementServiceUrl;
  }

  get applicationState() {
    return this.envService.internal ? 'Internal user public search' : 'External user public search';
  }

  getSystemAnnouncements(): Observable<SystemAnnouncements> {
    return this.http.get(this.baseUrl, {
      params: {
        application: this.applicationState,
        size: 'all',
        page: '0',
        clientTimestamp: moment.tz('America/New_York').format('YYYY-MM-DDTHH:mm:ss'),
      },
    });
  }
}
