import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { LoadingOverlayComponent } from '@src/app/shared/components/loading-overlay/loading-overlay.component';

@Directive({
  selector: '[appLoading]',
})
export class LoadingDirective implements OnInit, OnDestroy {
  @Input() appLoading: any;

  private wrapperContainer: ComponentRef<LoadingOverlayComponent> | undefined;

  constructor(
    private el: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const width = this.el.nativeElement.parentElement.offsetWidth;
    const height = this.el.nativeElement.parentElement.offsetHeight;

    const containerFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingOverlayComponent);
    this.wrapperContainer = this.viewContainerRef.createComponent(containerFactory);
    this.wrapperContainer.instance.template = this.templateRef;

    this.wrapperContainer.instance.loadingKey = this.appLoading;

    this.wrapperContainer.instance.width = width;
    this.wrapperContainer.instance.height = height;
  }

  // eslint-disable-next-line no-empty-function
  ngOnDestroy() {}
}
