import { Component, OnInit, Renderer2 } from '@angular/core';

// Used by search results
@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss'],
})
export class BackToTopComponent implements OnInit {
  public windowScrolled = false;

  constructor(public renderer: Renderer2) {}

  ngOnInit() {
    window.addEventListener('scroll', this.onWindowScroll, true);
  }

  public onWindowScroll(event: any): void {
    if (!event || !event.srcElement) {
      return;
    }

    const number = window.scrollY;
    const el = document.querySelector('#scroll-to-top');
    if (number > 1000) {
      this.windowScrolled = true;
      if (!el) {
        return;
      }
      let classNames = el.className;
      if (!classNames.includes('show-scrollTop')) {
        classNames += 'show-scrollTop';
      }

      el.className = classNames;
    } else if (this.windowScrolled && number < 1000) {
      this.windowScrolled = false;
      if (!el) {
        return;
      }
      let classNames = el.className;
      classNames = classNames.replace('show-scrollTop', '');

      el.className = classNames;
    }
  }

  addClass(el: Element, key: string) {
    if (!el) {
      return;
    }
    let classNames = el.className;
    if (!classNames.includes(key)) {
      classNames += key;
    }

    el.className = classNames;
  }

  removeClass(el: Element, key: string) {
    if (!el) {
      return;
    }
    let classNames = el.className;
    classNames = classNames.replace(key, '');

    el.className = classNames;
  }

  // Once the CDS 3.0 templating conversion is complete, this method may require review.
  scrollToTop() {
    (function smoothscroll() {
      const thing = document.querySelector('body');
      if (thing) {
        thing.scrollIntoView();
      }
    })();
  }
}
