import { Component } from '@angular/core';
import { DialogService } from '@src/app/cprs/modules/dialogs/services/dialog.service';
import { LocalStorageService } from '@src/app/cprs/services/local-storage.service';

@Component({
  selector: 'app-disclaimer-dialog',
  templateUrl: './disclaimer-dialog.component.html',
  styleUrls: ['./disclaimer-dialog.component.scss'],
})
export class DisclaimerDialogComponent {
  public disclaimerShown = true;

  constructor(public dialogService: DialogService, public localStorageService: LocalStorageService) {
  }

  close() {
    this.disclaimerShown = false;
  }
}
