<div id="cprs-advanced-search" class="container p-4">
  <div class="row">
    <cd-breadcrumb
      [trail]="this.breadcrumbService.breadcrumbs"
      [current]="this.breadcrumbService.currentBreadcrumbTextOnly"
    ></cd-breadcrumb>
  </div>

  <div class="row">
    <h4 class="display-4 mb-3">Advanced Search</h4>

    <ng-container>
      <div class="display-6 mb-3">Select record type to begin advanced search</div>

      <div class="d-flex">
        <cprs-record-type-selection
          (recordTypeSelected)="recordTypeSelected()"
          class="d-block mb-4"
        ></cprs-record-type-selection>
      </div>
    </ng-container>

    <ng-container *ngFor="let form of rows; let first = first; let last = last">
      <cprs-advanced-search-row
        class="mb-3"
        [formGroup]="form"
        [showOperator]="!first"
        [showButtons]="last"
        (clear)="clear()"
        (search)="search()"
      >
      </cprs-advanced-search-row>

      <hr *ngIf="first && this.rows.length > 1" class="my-4" />
    </ng-container>

    <!-- Row Controls-->
    <div class="cprs-advanced-search-row-controls mt-3">
      <cd-button class="pe-2" size="small" mode="text" (btnClick)="addRow($event)" [disabled]="this.rows.length >= 4">
        <!-- TODO: square-plus is not a valid icon, setting to plus-->
        <cd-icon class="pe-2" [cdsIcon]="'plus'"></cd-icon>Add a row
      </cd-button>

      <cd-button *ngIf="this.rows.length > 1" class="pe-2" size="small" mode="text" (btnClick)="removeRow()">
        <!-- TODO: square-minus is not a valid icon, setting to minus-->
        <cd-icon class="pe-2" [cdsIcon]="'minus'"></cd-icon>Remove row
      </cd-button>
      <!-- <app-share style="display: block; height: 24px; text-align: right"></app-share> -->
    </div>

    <!-- Additional -->
  </div>
  <div>
    <div class="d-flex align-items-center justify-content-end py-2 mb-3 border-bottom">
      <div *ngIf="current_search && hasSearched">
        <ng-container *ngIf="isLoggedIn">
          <div *ngIf="this.featureFlagService.isSavedRecordSearchEnabled() | async">
            <ng-container *ngIf="isSaved() && !isSaving">
              <cd-button mode="text" size="small" (btnClick)="removeSearch()">
                <cd-icon [cdsIcon]="'save'"></cd-icon> Remove Search
              </cd-button>
            </ng-container>
            <ng-container *ngIf="!isSaved() && !isSaving">
              <cd-button mode="text" size="small" (btnClick)="saveSearch()">
                <cd-icon [cdsIcon]="'save'"></cd-icon> Save Search
              </cd-button>
            </ng-container>
            <ng-container *ngIf="isSaving">
              <cd-button mode="text" size="small">
                <cd-icon [cdsIcon]="'spinner'" [classes]="'fa-spin'"></cd-icon> {{ savingLabel }}
              </cd-button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <app-share class="d-block text-align-right h-25"></app-share>
    </div>
    <div *ngIf="!hasSearched" class="cprs-additional-advanced-search">
      <h5 class="display-5">Additional search tools/filters</h5>

      <form [formGroup]="this.stateService.selectionLists">
        <div class="container row">
          <div class="filter-column col px-3 py-3 me-4 mb-4">
            <h5 class="display-5 mb-3">Registration Filters</h5>
            <cprs-module-selection-list
              title="Registration Status"
              class="d-block mb-3"
              mode="default"
              [histogram]="false"
              [formGroup]="this.stateService.selectionLists"
              [formArray]="this.getFormArray('registration_status')"
            >
            </cprs-module-selection-list>

            <cprs-module-selection-list
              title="Registration Class"
              class="d-block mb-3"
              mode="default"
              [histogram]="false"
              [formGroup]="this.stateService.selectionLists"
              [formArray]="this.getFormArray('registration_class')"
            >
            </cprs-module-selection-list>

            <cprs-module-selection-list
              title="Registration Item Type"
              class="d-block mb-3"
              mode="default"
              [histogram]="false"
              [formGroup]="this.stateService.selectionLists"
              [formArray]="this.getFormArray('type_of_work')"
            >
            </cprs-module-selection-list>
          </div>

          <!-- Recordation -->
          <div class="filter-column col px-3 py-3 me-4 mb-4">
            <h5 class="display-5 mb-3">Recordation Filters</h5>
            <cprs-module-selection-list
              title="Recordation Item Type"
              class="d-block mb-3"
              mode="default"
              [histogram]="false"
              [formGroup]="this.stateService.selectionLists"
              [formArray]="this.getFormArray('recordation_item_type')"
            >
            </cprs-module-selection-list>
          </div>

          <!-- Acquisition -->
          <ng-container *ngIf="this.envService.internal">
            <div class="filter-column col px-3 py-3 me-4 mb-4">
              <h5 class="display-5 mb-3">Acquisition Filters</h5>

              <cprs-module-selection-list
                title="Acquisitions Type"
                class="d-block mb-3"
                mode="default"
                [histogram]="false"
                [formGroup]="this.stateService.selectionLists"
                [formArray]="this.getFormArray('type_of_acquisition')"
              >
              </cprs-module-selection-list>
            </div>
          </ng-container>
          <!-- Collection -->
          <ng-container>
            <div class="filter-column col-md-12 px-3 py-3 me-4 mb-4">
              <h5 class="display-5 mb-3">Collection Filters</h5>

              <cprs-module-selection-list
                title="Collections Type"
                class="d-block mb-3"
                mode="default"
                [histogram]="false"
                [formGroup]="this.stateService.selectionLists"
                [formArray]="this.getFormArray('system_of_origin')"
              >
              </cprs-module-selection-list>
            </div>
          </ng-container>

          <div class="filter-column col-md-12 px-3 py-3 me-1 mb-4">
            <h5 class="display-5 mb-3">Filter by date</h5>

            <cprs-module-date-picker [showControls]="false"></cprs-module-date-picker>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-container *ngIf="this.hasSearched">
    <h1 class="display-4">Search Results</h1>
  </ng-container>

  <div id="cprs-search-results-on-advanced-search" *ngIf="hasSearched">
    <cprs-module-search-results></cprs-module-search-results>
  </div>
</div>
