import { VoyagerProperty } from '../../../services/transformer.service';
import { VoyagerHelper } from '../voyager.helper';

export class VoyagerStandardIdentifiers extends VoyagerHelper {
    get isbn(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('ISBN', 'isbn');
    }

    get issn_list(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('ISSN', ['issn_list', 'issn_contribution']);
    }

    get isrc(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('ISRC', 'isrc');
    }

    get ismn(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('ISMN', 'ismn');
    }

    // DOES NOT EXIST
    get upc(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('UPC', 'upc');
    }

    get ean(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('EAN', 'ean');
    }

    get ipn_number(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('IPN Number', 'ipn_number', ['internal']);
    }

    get record_id(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Record ID', 'control_number', ['internal']);
    }

    get system_control_number(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('System Control Number', 'system_control_number', ['internal', 'no-export']);
    }

    // DOES NOT EXIST
    get control_number_identifier(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Control Number Identifier', 'control_number_identifier');
    }

    get originating_control_number(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Originating System Control Number', 'originating_control_number', ['internal', 'no-export']);
    }
}
