import { Component, Input, OnInit } from '@angular/core';
import { CprsError, ErrorService } from '../../../../services/error.service';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';

@Component({
  selector: 'cprs-errors',
  templateUrl: './cprs-errors.component.html',
  styleUrls: ['./cprs-errors.component.scss'],
})
export class CprsErrorsComponent implements OnInit {
  @Input() key: string;

  public errors: CprsError[] = [];

  public debug: boolean = false;

  constructor(
    public errorService: ErrorService,
    private envService: EnvService,
  ) {
  }

  ngOnInit(): void {
    if (this.key) {
      const errors_subject = this.errorService.getErrors(this.key);
      if (errors_subject) {
        errors_subject.subscribe((errors) => {
          this.errors = errors;
        })
      }
    } else {
      this.errorService.getErrors().subscribe((errors) => {
        this.errors = errors;
      })
    }
  }

  remove(): void {
    this.errorService.resetErrors(this.key);
  }

  getTitle(error?: CprsError) {
    return error?.title ?? 'Unknown Error';
  }

  getMessage(error: CprsError) {
    return error.message ? error.message : error.error?.message;
  }

  get isInternal(): boolean {
    return this.envService.internal;
  }
}
