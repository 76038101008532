import { Component, Input, OnInit } from '@angular/core';

import { Deserializable, deserializableProperty } from '../../../cprs/shared/interfaces/deserializable';

export class DataTableInterface extends Deserializable {
  @deserializableProperty columnData: { label: string; key: string }[] = [];

  constructor(json: any) {
    super(json);
    if (json && this['deserializablePropertyArray']) {
      this['deserializablePropertyArray'].forEach((p: any) => {
        if (json[p] !== undefined) {
          this[p] = json[p];
        }
      });
    }

    if (!this.columnData) {
      this.columnData = [];
    }
  }
}

export class PaginatorInterface extends Deserializable {
  @deserializableProperty length: number = 0;
  @deserializableProperty pageSize: number = 0;
  @deserializableProperty pageSizeOptions: number[] = [];
  @deserializableProperty pageIndex: number = 0;
}

// Used by record component
@Component({
  selector: 'app-data-table',
  styleUrls: ['data-table.component.scss'],
  templateUrl: 'data-table.component.html',
})
export class DataTableComponent implements OnInit {
  @Input() data: any;
  @Input() columns: { label: string; key: string }[];

  public dataSource: any;
  public interface: DataTableInterface = new DataTableInterface({});
  public paginator: PaginatorInterface = new PaginatorInterface({});
  public displayedColumns: any[] = [];

  public depositColumns = [
    { label: 'Deposit Tracking Number', key: 'deposit_tracking_number' },
    { label: 'Deposit Dispatch/Custody', key: 'deposit_dispatch_custody' },
    { label: 'Deposit Box Number', key: 'deposit_box_number' },
    { label: 'Deposit Oversize Indicator', key: 'deposit_oversize_indicator' },
    { label: 'Deposit Copy Agreement MPA', key: 'deposit_copy_agreement_mpa' },
    { label: 'Deposit Format', key: 'deposit_format' },
    { label: 'Deposit Receipt Date', key: 'deposit_receipt_date' },
    { label: 'Deposit Physical Location', key: 'deposit_physical_location' },
    { label: 'Deposit Date Offered to LC', key: 'deposit_date_offered_to_lc' },
    { label: 'Deposit Employee Barcode Location', key: 'deposit_employee_barcode_location' },
    { label: 'Deposit Room Code', key: 'deposit_room_code' },
    { label: 'Deposit Sublocation', key: 'deposit_sub_location' },
    { label: 'Deposit Retention', key: 'deposit_retention' },
    { label: 'Deposit URL', key: 'deposit_url' },
    { label: 'Deposit Copy Number', key: 'deposit_copy_number' },
    { label: 'Deposit Copy ISBN', key: 'isbn_deposit_copy' },
    { label: 'Deposit Enumeration', key: 'deposit_enumeration' },
    { label: 'Deposit Chronology', key: 'deposit_chronology' },
  ];

  ngOnInit() {
    this.interface = new DataTableInterface({
      columnData: this.columns,
    });

    this.paginator = new PaginatorInterface({
      length: this.data && this.data.length ? this.data.length : 0,
      pageSize: 10,
      pageSizeOptions: [10, 25, 50, 100],
      pageIndex: 0,
    });

    this.dataSource = this.data;

    const columns = this.getEmptyColumns();
    this.displayedColumns = this.interface.columnData.filter((col) => !columns[col.key]).map((c) => c.key);
  }

  public getLabelByKey(key: string) {
    return this.depositColumns.some((c) => c.key === key) ? this.depositColumns.find((c) => c.key === key)?.label : '';
  }

  public getEmptyColumns(): { [key: string]: boolean } {
    const columns = {};

    this.interface.columnData.forEach((col) => {
      columns[col.key] = this.dataSource.every((element: any) => {
        return !element[col.key];
      });
    });

    return columns;
  }

  get columnsToDisplay(): string[] {
    return this.displayedColumns.slice();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (typeof a === 'string') {
      a = a.toLowerCase();
    }

    if (typeof b === 'string') {
      b = b.toLowerCase();
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortData() {
    this.dataSource = this.data.sort(() => {
      return 0;
    });
  }

  pageChange($event: { pageSize: any; pageIndex: number }) {
    this.paginator.pageIndex = $event.pageIndex;

    const parsed = Number.parseInt($event.pageSize, 10);

    // If we change page size, automatically go back to first page
    if (parsed !== this.paginator.pageSize) {
      this.paginator.pageIndex = 0;
    }
    this.paginator.pageSize = parsed;

    const thing = document.querySelector('.data-table');
    if (thing) {
      thing.scrollIntoView();
    }
  }

  getValue(value: string | null) {
    return value || 'N/A';
  }

  getLabel(key: string): string {
    const found = this.interface.columnData.find((c) => c.key === key);
    if (found) {
      return found.label;
    } else {
      return '';
    }
  }
}
