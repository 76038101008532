import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchResultService {
  public viewMode: 'grid' | 'table' = 'grid';

  public expandedState: 'Collapse all' | 'Expand all' = 'Collapse all';

  public selectionListControls = [
    { value: 'type_of_record', expanded: true },
    { value: 'system_of_origin', expanded: true },

    { value: 'registration_status', expanded: true },
    { value: 'registration_class', expanded: true },
    { value: 'type_of_work', expanded: true },
    { value: 'recordation_item_type', expanded: true },
    { value: 'type_of_acquisition', expanded: true },
  ];

  constructor() {}

  setViewMode(viewMode: 'grid' | 'table') {
    return (this.viewMode = viewMode);
  }

  toggleViewmode() {
    return (this.viewMode = this.viewMode === 'grid' ? 'table' : 'grid');
  }

  isExpanded(key: string): boolean {
    const found = this.selectionListControls.find((ctrl) => ctrl.value === key);
    if (!found) {
      return false;
    }

    return found.expanded;
  }

  getSelectionListControl(value: string) {
    return this.selectionListControls.find((ctrl) => ctrl.value === value);
  }

  toggleSelectionList() {
    this.expandedState = this.expandedState === 'Collapse all' ? 'Expand all' : 'Collapse all';
    this.selectionListControls.forEach((ctrl) => (ctrl.expanded = !ctrl.expanded));
  }
}
