import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EnvService } from '../env/env.service';

/**
 * A superclass for REST services
 */
export abstract class RestService {
  protected constructor(protected http: HttpClient, protected serviceName: string, protected envService: EnvService) {}

  /**
   * Generates an errorhandling function that logs the error and returns a default Observable instead
   * @param defaultResponse the Observable response to be returned instead
   */
  protected getErrorHandler<T>(defaultResponse: T): (HttpErrorResponse: HttpErrorResponse) => Observable<T> {
    return (errResp: HttpErrorResponse) => {
      if (errResp && errResp.error instanceof ErrorEvent) {
        // A client-side or network error occurred
        console.log(`An error occurred in ${this.serviceName}:`, errResp.error.message);
      } else {
        // The backend returned an unsuccessful response code
        console.log(
          `Error in ${this.serviceName}:\n` + `\tBackend returned code ${errResp?.status}, ` + 'body was:\n',
          errResp?.error
        );
      }

      if (this.envService.enableErrorDialogs) {
        // alert the user
        // this.dialogService.alert(
        //   `There was an error in the ${this.serviceName} while communicating with the back end.` +
        //     ' Please try again later. See the console for details.'
        // );
      }

      // return the specified default response
      return of(defaultResponse);
    };
  }
}

export abstract class SimplerRestService {
  protected constructor(protected http: HttpClient, protected serviceName: string, protected envService: EnvService) {}

  /**
   * Generates an errorhandling function that logs the error and returns a default Observable instead
   * @param defaultResponse the Observable response to be returned instead
   */
  protected getErrorHandler<T>(defaultResponse: T): (HttpErrorResponse: HttpErrorResponse) => Observable<T> {
    return (errResp: HttpErrorResponse) => {
      if (errResp.error instanceof ErrorEvent) {
        // A client-side or network error occurred
        console.log(`An error occurred in ${this.serviceName}:`, errResp.error.message);
      } else {
        // The backend returned an unsuccessful response code
        console.log(
          `Error in ${this.serviceName}:\n` + `\tBackend returned code ${errResp.status}, ` + 'body was:\n',
          errResp.error
        );
      }

      if (this.envService.enableErrorDialogs) {
        // alert the user
        // this.dialogService.alert(
        //   `There was an error in the ${this.serviceName} while communicating with the back end.` +
        //     ' Please try again later. See the console for details.'
        // );
      }

      // return the specified default response
      return of(defaultResponse);
    };
  }
}
