<cd-paging [page]="$page">
  <!-- <cd-paging-progress></cd-paging-progress> -->
  <cd-paging-header *ngIf="showHeaderOnEmpty || (!showHeaderOnEmpty && content.length > 0)">
    <div left>
      <div class="d-flex chk-align">
        <ng-container *ngIf="this.mode === 'grid' && this.showSelectControls && content.length > 0">
          <div class="selection-section" [cprsSelectAll]="selectionKey"></div>
        </ng-container>

        <div class="column-fix">
          <ng-content select="[header-left]"></ng-content>
        </div>
      </div>
    </div>
    <div center>
      <!-- Paging Display -->
      <ng-content select="[header-center]"></ng-content>
      <div [class.hidden]="!showPagingDisplay || content.length === 0">
        <!-- <cd-paging-display class="noshow"></cd-paging-display> -->
        <!-- <cd-paging-display
          [pageStart]="manual.pageStart"
          [pageEnd]="manual.pageEnd"
          [total]="manual.total"
        ></cd-paging-display> -->
      </div>
    </div>
    <div right>
      <div class="column-fix d-flex">
        <ng-content select="[header-right]"></ng-content>

        <!-- Grid / Table toggle -->
        <div *ngIf="showModeToggle">
          <cd-button (click)="this.setViewMode('grid')" mode="text" size="small">
            <cd-icon [cdsIcon]="'list'"></cd-icon> Grid View
          </cd-button>
          <cd-button (click)="this.setViewMode('table')" mode="text" size="small">
            <cd-icon [cdsIcon]="'table'"></cd-icon> Table View
          </cd-button>
        </div>

        <!-- Simple Pager -->
        <div *ngIf="showSimplePager" class="simple-pager">
          <cd-button mode="text" (btnClick)="prevPage()" [disabled]="isPrevDisabled()" size="small">
            <cd-icon [cdsIcon]="'caret-left'"></cd-icon> Previous
          </cd-button>
          <cd-button mode="text" (btnClick)="nextPage()" [disabled]="isNextDisabled()" size="small">
            Next <cd-icon [cdsIcon]="'caret-right'"></cd-icon>
          </cd-button>
        </div>
      </div>
      <div class="d-flex">
        <div class="col d-flex align-items-center">
          <!-- Column Filtering by mode-->
          <ng-container *ngIf="this.mode === 'grid'">
            <form *ngIf="showColumnFiltering" [formGroup]="gridColumnForm" autocomplete="off" novalidate>
              <cd-multi-select title="Columns" [showGivenTitle]="true">
                <ng-container *ngFor="let column of this.modeColumns">
                  <li>
                    <cd-input-crs [formControlName]="column.columnKey" [label]="column.label"></cd-input-crs>
                  </li>
                </ng-container>
              </cd-multi-select>
            </form>
          </ng-container>
          <ng-container *ngIf="this.mode === 'table'">
            <form *ngIf="showColumnFiltering" [formGroup]="tableColumnForm" autocomplete="off" novalidate>
              <cd-multi-select title="Columns" [showGivenTitle]="true">
                <ng-container *ngFor="let column of this.modeColumns">
                  <li>
                    <cd-input-crs [formControlName]="column.columnKey" [label]="column.label"></cd-input-crs>
                  </li>
                </ng-container>
              </cd-multi-select>
            </form>
          </ng-container>
        </div>
      </div>

      <div *ngIf="sideColumnRef" class="side-column">
        <strong class="side-column-section">
          <ng-content select="[sideColumnHeader]"></ng-content>
        </strong>
      </div>
    </div>
  </cd-paging-header>

  <ng-container *ngIf="this.mode === 'grid'">
    <cd-table [caption]="interface.caption" [columns]="visibleColumns">
      <tbody>
        <ng-container *ngIf="pagingType === 'client'">
          <ng-container
            *ngFor="
              let data of content | paginate : { itemsPerPage: fakePage.size, currentPage: fakePage.number + 1 };
              let index = index;
              let odd = odd
            "
          >
            <div class="search-result-pane d-flex" [ngClass]="{ gray: odd }">
              <div *ngIf="showSelectControls" class="col-1" style="flex: 0">
                <td [cprsChildSelect]="selectionKey" [data]="data" [index]="index"></td>
              </div>
              <div *ngIf="showIndexColumn" class="col-1" style="flex: 0">
                <strong>{{ this.getIndex(index) }}.</strong>
              </div>
              <div class="col-8">
                <ul class="row">
                  <ng-container *ngFor="let columnKey of this.visibleColumns; let first = first; let last = last">
                    <!-- Omit anything without a value -->
                    <ng-container *ngIf="data[columnKey] && data[columnKey].value">
                      <ng-container
                        *ngIf="
                          !data[columnKey].hasOwnProperty('visible') ||
                          (data[columnKey].hasOwnProperty('visible') && data[columnKey].visible)
                        "
                      >
                        <li [class.col-md-12]="first" [class.col-md-6]="!first" [class.col-sm-12]="true">
                          <div class="control d-flex pb-1">
                            <div class="control-label pe-2">
                              <strong *ngIf="data[columnKey] && data[columnKey].label"
                                >{{ data[columnKey].label }}:</strong
                              >
                              <strong *ngIf="!data[columnKey] || !data[columnKey].label"
                                >{{ this.getColumnLabel(columnKey) }}:</strong
                              >
                            </div>
                            <div class="control-value truncate" [class.primary]="first" style="max-width: 750px">
                              <ng-container *ngIf="data[columnKey] && data[columnKey].url">
                                <a [href]="data[columnKey].url" [routerLink]="data[columnKey].url">{{
                                  data[columnKey].value
                                }}</a>
                              </ng-container>
                              <ng-container *ngIf="data[columnKey] && !data[columnKey].url">
                                {{ data[columnKey].value }}
                              </ng-container>
                            </div>
                          </div>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ul>

                <div *ngIf="headerTemplateRef" class="search-result-pane-footer mt-2">
                  <!-- fancy content projection, allows access to the iterative data items -->
                  <ng-container
                    *ngIf="headerTemplateRef"
                    [ngTemplateOutlet]="headerTemplateRef"
                    [ngTemplateOutletContext]="{ $implicit: data }"
                  >
                  </ng-container>
                </div>
              </div>

              <div *ngIf="sideColumnRef" class="side-column">
                <!-- fancy content projection, allows access to the iterative data items -->
                <ng-container
                  *ngIf="sideColumnRef"
                  [ngTemplateOutlet]="sideColumnRef"
                  [ngTemplateOutletContext]="{ $implicit: data }"
                >
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="pagingType === 'server'">
          <ng-container *ngFor="let data of content; let index = index; let odd = odd">
            <div class="search-result-pane d-flex" [ngClass]="{ gray: odd }">
              <div *ngIf="showSelectControls" class="col-1" style="flex: 0">
                <td [cprsChildSelect]="selectionKey" [data]="data" [index]="index"></td>
              </div>
              <div *ngIf="showIndexColumn" class="col-1" style="flex: 0">
                <strong>{{ this.getIndex(index) }}.</strong>
              </div>
              <div class="col-10">
                <ul class="row">
                  <ng-container *ngFor="let columnKey of this.visibleColumns; let first = first; let last = last">
                    <!-- Omit anything without a value -->
                    <ng-container *ngIf="data[columnKey] && data[columnKey].value">
                      <ng-container
                        *ngIf="
                          !data[columnKey].hasOwnProperty('visible') ||
                          (data[columnKey].hasOwnProperty('visible') && data[columnKey].visible)
                        "
                      >
                        <li [class.col-md-12]="first" [class.col-md-6]="!first" [class.col-sm-12]="true">
                          <div class="control d-flex pb-1">
                            <div class="control-label pe-2">
                              <strong *ngIf="data[columnKey] && data[columnKey].label"
                                >{{ data[columnKey].label }}:</strong
                              >
                              <strong *ngIf="!data[columnKey] || !data[columnKey].label"
                                >{{ this.getColumnLabel(columnKey) }}:</strong
                              >
                            </div>
                            <div class="control-value truncate" [class.primary]="first" style="max-width: 750px">
                              <ng-container *ngIf="data[columnKey] && data[columnKey].url">
                                <a [href]="data[columnKey].url" [routerLink]="data[columnKey].url">{{
                                  data[columnKey].value
                                }}</a>
                              </ng-container>
                              <ng-container *ngIf="data[columnKey] && !data[columnKey].url">
                                {{ data[columnKey].value }}
                              </ng-container>
                            </div>
                          </div>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ul>

                <div *ngIf="headerTemplateRef" class="search-result-pane-footer mt-2">
                  <!-- fancy content projection, allows access to the iterative data items -->
                  <ng-container
                    *ngIf="headerTemplateRef"
                    [ngTemplateOutlet]="headerTemplateRef"
                    [ngTemplateOutletContext]="{ $implicit: data }"
                  >
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="content.length === 0">
          <td class="container" colspan="12">
            <ng-content select="[no-content-grid]"></ng-content>
          </td>
        </ng-container>
      </tbody>
    </cd-table>
  </ng-container>

  <ng-container *ngIf="this.mode === 'table'">
    <cd-table [caption]="interface.caption" [columns]="visibleColumns">
      <thead>
        <tr>
          <th
            *ngIf="showSelectControls && content.length > 0"
            style="width: 36px"
            scope="col"
            [cprsSelectAll]="selectionKey"
          ></th>
          <!-- <th cdExpandAll></th> -->
          <th *ngIf="showIndexColumn" scope="col" cdResizable>#</th>
          <ng-container *ngFor="let column of this.modeColumns">
            <!-- a bunch of states here, sortable, resizable etc... -->
            <ng-container *ngIf="!column.sortable">
              <!-- <th class="display-7 fw-bold" [cdDisplayColumn]="column.columnKey">{{ column.label }}</th> -->
              <th [cdDisplayColumn]="column.columnKey">{{ column.label }}</th>
            </ng-container>

            <ng-container *ngIf="column.sortable">
              <th
                [cdDisplayColumn]="column.columnKey"
                [cdSort]="column.columnKey"
                [cdSortDefault]="getSortDefault(column)"
                [cdSortResizable]="isResizable(column)"
                (sort)="sortTable($event)"
              >
                {{ column.label }}
              </th>
            </ng-container>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="pagingType === 'client'">
          <tr
            *ngFor="
              let data of content | paginate : { itemsPerPage: fakePage.size, currentPage: fakePage.number + 1 };
              let index = index
            "
          >
            <td *ngIf="showSelectControls" [cprsChildSelect]="selectionKey" [data]="data" [index]="index"></td>
            <td *ngIf="showIndexColumn">{{ this.getIndex(index) }}</td>
            <!-- <td cdExpand></td> -->
            <ng-container *ngFor="let column of this.modeColumns">
              <td [cdDisplayColumn]="column.columnKey">
                <ng-container *ngIf="data[column.columnKey] && data[column.columnKey].url">
                  <a
                    [href]="data[column.columnKey].url"
                    (click)="
                      $event.preventDefault(); navigate(data[column.columnKey].url, data[column.columnKey]?.params)
                    "
                    >{{ data[column.columnKey].value }}</a
                  >
                </ng-container>
                <ng-container *ngIf="data[column.columnKey] && !data[column.columnKey].url">
                  {{ data[column.columnKey].value }}
                </ng-container>
              </td>
            </ng-container>
          </tr>
        </ng-container>
        <ng-container *ngIf="pagingType === 'server'">
          <tr *ngFor="let data of content; let index = index">
            <td *ngIf="showSelectControls" [cprsChildSelect]="selectionKey" [data]="data" [index]="index"></td>
            <td *ngIf="showIndexColumn">{{ this.getIndex(index) }}</td>
            <!-- <td cdExpand></td> -->
            <ng-container *ngFor="let column of this.modeColumns">
              <td [cdDisplayColumn]="column.columnKey">
                <ng-container *ngIf="data[column.columnKey] && data[column.columnKey].url">
                  <a
                    [href]="data[column.columnKey].url"
                    (click)="
                      $event.preventDefault(); navigate(data[column.columnKey].url, data[column.columnKey]?.params)
                    "
                    >{{ data[column.columnKey].value }}</a
                  >
                </ng-container>
                <ng-container *ngIf="data[column.columnKey] && !data[column.columnKey].url">
                  {{ data[column.columnKey].value }}
                </ng-container>
              </td>
            </ng-container>
          </tr>
        </ng-container>

        <ng-container *ngIf="content.length === 0">
          <td class="container" colspan="12">
            <ng-content select="[no-content-table]"></ng-content>
          </td>
        </ng-container>
      </tbody>
    </cd-table>
  </ng-container>

  <!-- Paging Footer -->
  <div [class.noshow]="content.length === 0">
    <cd-paging-footer>
      <ng-container ngProjectAs="[left]">
        <cd-paging-rows [pageSizes]="pageSizes" (pageChange)="goto($event)"></cd-paging-rows>
      </ng-container>
      <ng-container ngProjectAs="[center]">
        <cd-paging-range (pageChange)="goto($event)"></cd-paging-range>
      </ng-container>
      <ng-container ngProjectAs="[right]">
        <cd-paging-goto #goToRef (pageChange)="goto($event)"></cd-paging-goto>
      </ng-container>
    </cd-paging-footer>
  </div>
</cd-paging>
