import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, Type } from '@angular/core';
import { DisclaimerDialogComponent } from '../components/disclaimer-dialog/disclaimer-dialog.component';

/*
    this really should be in the DSA lib
*/

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  public disclaimerShown = true;

  public disclaimerRef: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  disclaimer() {
    if (this.disclaimerRef) {
      this.disclaimerRef.instance.close();
      this.disclaimerRef = null;
      this.disclaimerRef = this.open(DisclaimerDialogComponent, { data: {} });
    } else {
      this.disclaimerRef = this.open(DisclaimerDialogComponent, { data: {} });
    }
  }

  open(component: Type<any>, data?: any) {
    // Get the ComponentFactory for the dialog component
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    // Create an instance of the dialog component
    const componentRef = componentFactory.create(this.injector);

    // Pass data to the dialog component
    if (data) {
      componentRef.instance.data = data['data'];
    }

    // Attach the component to the body of the document
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.append(domElem);

    // Close the dialog
    componentRef.instance.close = () => {
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    };

    return componentRef;
  }
}
