import { Component, OnDestroy, OnInit } from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CprsService } from '@src/app/cprs/services/cprs.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-search-relevancy-survey',
  templateUrl: './search-relevancy-survey.component.html',
  styleUrls: ['./search-relevancy-survey.component.scss'],
})
export class SearchRelevancySurveyComponent implements OnDestroy, OnInit {
  subscriptions: Subscription[] = [];
  faTimesCircle = faTimesCircle;

  public hidden = true;

  public surveyOptions = [
    {
      value: 1,
      label: 'Not at all relevant',
    },
    {
      value: 2,
      label: 'Somewhat relevant',
    },
    {
      value: 3,
      label: 'Fairly relevant',
    },
    {
      value: 4,
      label: 'Mostly relevant',
    },
    {
      value: 5,
      label: 'Extremely relevant',
    },
  ];

  constructor(public cprsService: CprsService) {}

  ngOnInit(): void {
    const subscription = this.cprsService.loading.subscribe((_loaded) => (this.hidden = false));

    this.subscriptions.push(subscription);
  }

  submit() {
    this.hidden = true;
  }

  dismiss() {
    this.hidden = true;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }
}
