import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DesignComponentsModule } from '@cop/design/components';
import { DesignFormsModule } from '@cop/design/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { FooterComponent } from '../../../dead_code/shared_components/accounts/footer/footer.component';
import { NgxPaginationModule } from 'ngx-pagination';

// import { AccountSetupComponent } from '../../../dead_code/shared_components/accounts/account-setup/account-setup.component';
import { BackToTopComponent } from './components/back-to-top/back-to-top.component';
// import { CdsChipComponent } from '../../../dead_code/shared_components/accounts/cds-chip/cds-chip.component';
// import { CDSHeaderComponent } from './components/cds-header/cds-header.component';
// import { CDSPaginatorComponent } from '../../../dead_code/shared_components/accounts/cds-paginator/cds-paginator.component';
import { DataTableComponent } from './components/data-table/data-table.component';
// import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
// import { OpenSeaDragonComponent } from './components/open-seadragon/open-seadragon.component';
import { LoadingDirective } from './directives/loading/loading.directive';
// import { PhoneFormatPipe } from '../../../dead_code/pipes/phone-format.pipe';
// import { ZipCodePipe } from '../../../dead_code/pipes/zipcode-format.pipe';
import { EnvServiceProvider } from '../cprs/modules/shared/services/env/env.service.provider';
import { SharedModule as CprsShared } from '../cprs/modules/shared/shared.module';
import { DesignTableModule } from '@cop/design/table';
import { SystemAnnouncementsComponent } from './components/system-announcements/system-announcements.component';

@NgModule({
  declarations: [
    LoadingDirective,
    // FooterComponent,
    // CDSPaginatorComponent,
    // CDSHeaderComponent,
    LoadingOverlayComponent,
    BackToTopComponent,
    // AccountSetupComponent,
    DataTableComponent,
    // CdsChipComponent,
    // DatePickerComponent,
    // OpenSeaDragonComponent,
    SystemAnnouncementsComponent
  ],
  imports: [
    DesignFormsModule,
    CdkTableModule,
    CommonModule,
    DesignComponentsModule,
    NgxPaginationModule,
    FontAwesomeModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CprsShared,
    DesignTableModule,
  ],
  exports: [
    LoadingDirective,
    // FooterComponent,
    // CDSPaginatorComponent,
    // CDSHeaderComponent,
    LoadingOverlayComponent,
    BackToTopComponent,
    DataTableComponent,
    // CdsChipComponent,
    // DatePickerComponent,
    // OpenSeaDragonComponent,
    // PhoneFormatPipe,
    SystemAnnouncementsComponent
  ],
  // PhoneFormatPipe, ZipCodePipe,
  providers: [EnvServiceProvider],
})
export class SharedModule {}
