import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CprsSearch, CprsService } from '@src/app/cprs/services/cprs.service';
import { ParametersService } from '@src/app/cprs/services/parameters.service';
import { ElasticSearchResponse } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { StateService } from '@src/app/cprs/state/state.service';
import { concat } from 'rxjs';
import { RecentFeatureService } from '../../../recent/services/recent.service';
import { SavedFeatureService } from '../../../saved/services/saved.feature.service';

@Component({
  selector: 'cprs-record-navigation-text',
  templateUrl: './cprs-record-navigation-text.component.html',
  styleUrls: ['./cprs-record-navigation-text.component.scss']
})
export class CprsRecordNavigationTextComponent implements OnInit {
  public control_number: string | null;
  public current_search: CprsSearch;
  public recent_record: any;

  public paginationDetails = {
    currentPage: 0,
    totalPages: 0
  };

  constructor(
    public route: ActivatedRoute,
    public cprsService: CprsService,
    public stateService: StateService,
    public recentService: RecentFeatureService,
    public parameterService: ParametersService,
    public savedFeatureService: SavedFeatureService
  ) { }

  ngOnInit(): void {
    const currentScreen: string = this.cprsService.getCurrentSearchResults();
    concat(this.cprsService.getCurrentSearch(), this.cprsService.onFacet).subscribe(search => {
      if (search && currentScreen === 'search_results') {
        this.current_search = search;
        this.getDisplayResults();
      }
    });

    this.cprsService.associated_record_search.subscribe(search => {
      if (search && currentScreen === 'associated_records') {
        this.current_search = search;
        this.getDisplayResults();
      }
    });

    this.route.paramMap.subscribe(params => {
      this.control_number = params.get('id')
      if (this.control_number) {
        this.getDisplayResults();
      }
    });

    if (this.cprsService.getRecentRecordSearch()) {
      const search = this.cprsService.getRecentRecordSearch();
      if (search?.data) {
        const updatedParams = this.parameterService.simpleSearchParameters();
        const response = new ElasticSearchResponse({});
        response.data = search.data;
        response.metadata = search.metadata;
        if (this.control_number && currentScreen === 'recent_records') {
          this.current_search = new CprsSearch('simple', updatedParams, response, '');
          this.getDisplayResults();
        }
      }
    }
    if (currentScreen === 'saved_records') {
      this.savedFeatureService.getElasticSavedRecords().subscribe((resp: any) => {
        const updatedParams = this.parameterService.simpleSearchParameters();
        const response = new ElasticSearchResponse({});
        response.data = resp.data?.sort((a: any, b: any) => {
          const timeA = a.saved?.data.timestamp ?? '';
          const timeB = b.saved?.data.timestamp ?? '';

          return timeB.localeCompare(timeA);
        });
        response.metadata = resp.metadata;
        this.current_search = new CprsSearch('simple', updatedParams, response, ''); ;
        this.getDisplayResults();
      })
    }
  }

  getDisplayResults() {
    const currentScreen: string = this.cprsService.getCurrentSearchResults();
    if (this.control_number && this.current_search) {
      const current_index = this.current_search?.response.data.findIndex(
        (d) => d.get('control_number') === this.control_number
      );
      const rows_per_page = this.stateService.pagination.get('rows_per_page')?.value;
      const page_number = this.stateService.pagination.get('page_number')?.value;
      let abs_index = current_index + 1 + rows_per_page * (page_number - 1);
      if (currentScreen === 'recent_records' || currentScreen === 'saved_records') {
        abs_index = current_index + 1;
      }
      this.paginationDetails = {
        currentPage: abs_index,
        totalPages: this.current_search?.response?.metadata.hit_count
      }
    }
  }

  getDisplayNavigations() {
    if (!this.paginationDetails.currentPage && this.control_number && this.recent_record) {
      const current_index = this.recent_record.findIndex(
        (d: any) => d.get('control_number') === this.control_number
      );
      const rows_per_page = this.stateService.pagination.get('rows_per_page')?.value;
      const page_number = this.stateService.pagination.get('page_number')?.value;
      const abs_index = current_index + 1 + rows_per_page * (page_number - 1);
      this.paginationDetails = {
        currentPage: abs_index,
        totalPages: this.recent_record?.length
      }
    }
  }
}
