enum Hostnames {
  'localhost',
  'dev.publicrecords.usco.lctl.gov',
  'staff-dev.publicrecords.usco.lctl.gov',
  'test.publicrecords.usco.lctl.gov',
  'staff-test.publicrecords.usco.lctl.gov',
  'staging.publicrecords.copyright.gov',
  'staff-staging.publicrecords.publicrecords.copyright.gov',
}

export enum UrlType {
  addressServiceUrl = 'addressServiceUrl',
  reviewerUsersUrl = 'reviewerUsersUrl',
  claimServiceUrl = 'claimServiceUrl',
  registrationSearchClaimsUrl = 'registrationSearchClaimsUrl',
  registrationSearchUrl = 'registrationSearchUrl',
  claimsIndexServiceUrl = 'claimsIndexServiceUrl',
  notificationServiceUrl = 'notificationServiceUrl',
  reminderServiceUrl = 'reminderServiceUrl',
  systemAnnouncementServiceUrl = 'systemAnnouncementServiceUrl',
  tagsServiceUrl = 'tagsServiceUrl',
  templateServiceUrl = 'templateServiceUrl',
  attachmentServiceUrl = 'attachmentServiceUrl',
  commentsServiceUrl = 'commentsServiceUrl',
  preferencesServiceUrl = 'preferencesServiceUrl',
  filtersServiceUrl = 'filtersServiceUrl',
  correspondenceServiceUrl = 'correspondenceServiceUrl',
  remitterOrgServiceUrl = 'remitterOrgServiceUrl',
  configurationsUrl = 'configurationsUrl',
  activityTrackerServiceUrl = 'activityTrackerServiceUrl',
}

const ServiceHost = {
  platform: {
    test: 'test.platform.usco.lctl.gov',
    dev: 'dev.platform.usco.lctl.gov',
    stage: 'stage.platform.usco.lctl.gov',
    prod: 'platform.copyright.gov',
  },
  publicrecords: {
    test: 'test.publicrecords.usco.lctl.gov',
    dev: 'dev.publicrecords.usco.lctl.gov',
    stage: 'staging.publicrecords.usco.lctl.gov',
    prod: 'publicrecords.copyright.gov',
  },
};

export const URL_LOOKUP = {
  localhost: {
    keycloakConfig: {
      url: 'https://test.platform.usco.lctl.gov/auth',
      realm: 'copyright',
      clientId: 'public-records-client',
    },
    [UrlType.systemAnnouncementServiceUrl]: `https://${ServiceHost.platform.test}/Platform/SystemAnnouncements`,
    [UrlType.addressServiceUrl]: `https://${ServiceHost.platform.test}/Platform/Addresses`,
    [UrlType.remitterOrgServiceUrl]: `https://${ServiceHost.platform.test}/Platform/RemitterOrg`,
    [UrlType.reviewerUsersUrl]: `https://${ServiceHost.platform.test}/Platform/Reviewers`,
    [UrlType.commentsServiceUrl]: `https://${ServiceHost.platform.test}/Platform/Comments`,
    [UrlType.correspondenceServiceUrl]: `https://${ServiceHost.platform.test}/Platform/Correspondence`,
    [UrlType.attachmentServiceUrl]: `https://${ServiceHost.platform.test}/Platform/Attachments`,
    [UrlType.activityTrackerServiceUrl]: `https://${ServiceHost.platform.test}/Platform/ActivityTracker`,
    [UrlType.notificationServiceUrl]: `https://${ServiceHost.platform.test}/Platform/Notifications`,
  },
  'dev.publicrecords.usco.lctl.gov': {
    keycloakConfig: {
      url: 'https://dev.platform.usco.lctl.gov/auth',
      realm: 'copyright',
      clientId: 'public-records-client',
    },
    [UrlType.systemAnnouncementServiceUrl]: `https://${ServiceHost.platform.dev}/Platform/SystemAnnouncements`,
    [UrlType.addressServiceUrl]: `https://${ServiceHost.platform.dev}/Platform/Addresses`,
    [UrlType.remitterOrgServiceUrl]: `https://${ServiceHost.platform.dev}/Platform/RemitterOrg`,
    [UrlType.reviewerUsersUrl]: `https://${ServiceHost.platform.dev}/Platform/Reviewers`,
    [UrlType.commentsServiceUrl]: `https://${ServiceHost.platform.dev}/Platform/Comments`,
    [UrlType.correspondenceServiceUrl]: `https://${ServiceHost.platform.dev}/Platform/Correspondence`,
    [UrlType.attachmentServiceUrl]: `https://${ServiceHost.platform.dev}/Platform/Attachments`,
    [UrlType.activityTrackerServiceUrl]: `https://${ServiceHost.platform.dev}/Platform/ActivityTracker`,
    [UrlType.notificationServiceUrl]: `https://${ServiceHost.platform.dev}/Platform/Notifications`,
  },

  'test.publicrecords.usco.lctl.gov': {
    keycloakConfig: {
      url: 'https://test.platform.usco.lctl.gov/auth',
      realm: 'copyright',
      clientId: 'public-records-client',
    },
    [UrlType.systemAnnouncementServiceUrl]: `https://${ServiceHost.platform.test}/Platform/SystemAnnouncements`,
    [UrlType.addressServiceUrl]: `https://${ServiceHost.platform.test}/Platform/Addresses`,
    [UrlType.remitterOrgServiceUrl]: `https://${ServiceHost.platform.test}/Platform/RemitterOrg`,
    [UrlType.reviewerUsersUrl]: `https://${ServiceHost.platform.test}/Platform/Reviewers`,
    [UrlType.commentsServiceUrl]: `https://${ServiceHost.platform.test}/Platform/Comments`,
    [UrlType.correspondenceServiceUrl]: `https://${ServiceHost.platform.test}/Platform/Correspondence`,
    [UrlType.attachmentServiceUrl]: `https://${ServiceHost.platform.test}/Platform/Attachments`,
    [UrlType.activityTrackerServiceUrl]: `https://${ServiceHost.platform.test}/Platform/ActivityTracker`,
    [UrlType.notificationServiceUrl]: `https://${ServiceHost.platform.test}/Platform/Notifications`,
  },
  'staging.publicrecords.copyright.gov': {
    keycloakConfig: {
      url: 'https://stage.platform.usco.lctl.gov/auth',
      realm: 'copyright',
      clientId: 'public-records-client',
    },
    [UrlType.systemAnnouncementServiceUrl]: `https://${ServiceHost.platform.stage}/Platform/SystemAnnouncements`,
    [UrlType.addressServiceUrl]: `https://${ServiceHost.platform.stage}/Platform/Addresses`,
    [UrlType.remitterOrgServiceUrl]: `https://${ServiceHost.platform.stage}/Platform/RemitterOrg`,
    [UrlType.reviewerUsersUrl]: `https://${ServiceHost.platform.stage}/Platform/Reviewers`,
    [UrlType.commentsServiceUrl]: `https://${ServiceHost.platform.stage}/Platform/Comments`,
    [UrlType.correspondenceServiceUrl]: `https://${ServiceHost.platform.stage}/Platform/Correspondence`,
    [UrlType.attachmentServiceUrl]: `https://${ServiceHost.platform.stage}/Platform/Attachments`,
    [UrlType.activityTrackerServiceUrl]: `https://${ServiceHost.platform.stage}/Platform/ActivityTracker`,
    [UrlType.notificationServiceUrl]: `https://${ServiceHost.platform.stage}/Platform/Notifications`,
  },
  'publicrecords.copyright.gov': {
    keycloakConfig: {
      url: 'https://platform.copyright.gov/auth',
      realm: 'copyright',
      clientId: 'public-records-client',
    },
    [UrlType.systemAnnouncementServiceUrl]: `https://${ServiceHost.platform.prod}/Platform/SystemAnnouncements`,
    [UrlType.remitterOrgServiceUrl]: `https://${ServiceHost.platform.prod}/Platform/RemitterOrg`,
    [UrlType.addressServiceUrl]: `https://${ServiceHost.platform.prod}/Platform/Addresses`,
    [UrlType.reviewerUsersUrl]: `https://${ServiceHost.platform.prod}/Platform/Reviewers`,
    [UrlType.commentsServiceUrl]: `https://${ServiceHost.platform.prod}/Platform/Comments`,
    [UrlType.correspondenceServiceUrl]: `https://${ServiceHost.platform.prod}/Platform/Correspondence`,
    [UrlType.attachmentServiceUrl]: `https://${ServiceHost.platform.prod}/Platform/Attachments`,
    [UrlType.activityTrackerServiceUrl]: `https://${ServiceHost.platform.prod}/Platform/ActivityTracker`,
    [UrlType.notificationServiceUrl]: `https://${ServiceHost.platform.prod}/Platform/Notifications`,
  },
};

export function lookupKeycloakConfig(): string {
  const hostname = window.location.hostname.replace('staff-', '');
  if (!(hostname in Hostnames)) {
    window.document.write('No Hostname cannot continue');
    throw new Error('No Hostname cannot continue');
  }
  const result = URL_LOOKUP[hostname].keycloakConfig;
  if (!result) {
    window.document.write('No Hostname cannot continue');
    throw new Error('No Hostname cannot continue');
  }
  return result;
}

export function lookupURL(url: UrlType): string {
  const hostname = window.location.hostname.replace('staff-', '');
  if (!(hostname in Hostnames)) {
    window.document.write('No domain for microservice cannot continue');
    alert('No domain for microservice cannot continue');
    throw new Error('No domain for microservice  cannot continue');
  }
  const result = URL_LOOKUP[hostname][url];
  if (!result) {
    window.document.write(`URL_LOOKUP[${hostname}][${url}] No domain for microservice cannot continue`);
    alert('No domain for microservice cannot continue');
    throw new Error('No domain for microservice cannot continue');
  }
  return result;
}
