import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SavedRecordsComponent } from './components/saved-records/saved-records.component';
import { SavedSearchesComponent } from './components/saved-searches/saved-searches.component';
import { SharedModule } from '../../../shared/shared.module';

import { DesignTableModule } from '@cop/design/table';
import { DesignFormsModule } from '@cop/design/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { DesignComponentsModule } from '@cop/design/components';
import { SharedModule as CprsSharedModule } from '@src/app/cprs/modules/shared/shared.module';
import { SearchResultsModule } from '../search-results/search-results.module';
import { SelectionModule } from '../selection/selection.module';
import { TableModule as CprsTableModule } from '../table/table.module';
import { BrowserModule } from '@angular/platform-browser';
@NgModule({
  declarations: [SavedRecordsComponent, SavedSearchesComponent],
  imports: [
    BrowserModule,
    CommonModule,
    SharedModule,
    DesignTableModule,
    DesignFormsModule,
    NgxPaginationModule,
    DesignComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CprsSharedModule,
    SearchResultsModule,
    SelectionModule,
    CprsTableModule,
  ],
  exports: [SavedRecordsComponent, SavedSearchesComponent],
})
export class SavedModule {}
