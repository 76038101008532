import { Component, OnInit } from '@angular/core';
import { CprsSearch, CprsService } from '@src/app/cprs/services/cprs.service';
import { StateService } from '@src/app/cprs/state/state.service';

@Component({
  selector: 'cprs-facet-chips',
  templateUrl: './facet-chips.component.html',
  styleUrls: ['./facet-chips.component.scss'],
})
export class FacetChipsComponent implements OnInit {
  public current_search: CprsSearch;

  constructor(
    public stateService: StateService,
    public cprsService: CprsService,
  ) {}

  ngOnInit(): void {
    // Nothing here yet

    this.cprsService.getCurrentSearch().subscribe((current_search) => {
      this.current_search = current_search;
    });
  }

  facet() {
    this.stateService.pagination.get('page_number')?.setValue(1);
    this.stateService.pagination.get('rows_per_page')?.setValue(10);

    this.cprsService.search(this.current_search.type);
  }
}
