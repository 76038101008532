import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { StateService } from '../state/state.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService implements OnDestroy {
  subscriptions: Subscription[] = [];

  // Missting Crumbs
  public breadcrumbs: any[] = [];
  public currentBreadcrumbTextOnly = '';

  public _crumbs: any[] = [{ name: 'Home', link: '/', linkType: 'routerLink' }];

  public _current_crumb = '';

  constructor(public router: Router, public route: ActivatedRoute, public stateService: StateService) {
    const subscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe((event: NavigationEnd) => {
        this.buildBreadcrumbs(event.url);
      });
    this.subscriptions.push(subscription);
  }

  buildBreadcrumbs(url: string) {
    if (this.isOnSimpleSearch(url)) {
      this._crumbs = [{ name: 'Home', link: '/', linkType: 'routerLink' }];

      const query = this.stateService._state.get('query')?.value;
      if (query) {
        this.crumbs.push({ name: 'Search', link: '/', linkType: 'routerLink' });
        this._current_crumb = `Search Results for "${query}"`;
      } else {
        this._current_crumb = 'Search';
      }
    }

    if (this.isOnAdvancedSearch(url)) {
      this._crumbs = [{ name: 'Home', link: '/', linkType: 'routerLink' }];

      this._current_crumb = 'Advanced Search';
    }
  }

  get crumbs() {
    return this._crumbs;
  }

  get current_crumb() {
    return this._current_crumb;
  }

  isOnSimpleSearch(url: string): boolean {
    return url.includes('search');
  }

  isOnAdvancedSearch(url: string): boolean {
    return url.includes('advanced-search');
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }
}
