import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { VoyagerProperty } from '../../../services/transformer.service';
import { VoyagerHelper } from '../voyager.helper';
import { PreviousRegistrationNumber } from '@src/app/cprs/shared/interfaces/types/types';

export class VoyagerClaimDescription extends VoyagerHelper {
    get basis_of_claim(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Basis of Claim', 'material_included_statement');
    }

    get rights_and_permissions(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Rights and Permissions', 'rights_and_permissions_statement');
    }

    get preexisting_material(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Material Excluded', 'material_excluded_statement');
    }

    // these can just be text, or they can have hyperlinks inline replaced within the text...
    // TODO multiple sources, supplementary_rsa_list
    get amplifications_explained(): VoyagerProperty {
        return {
            label: 'Amplifications Explained',
            value: (record: ElasticSearchResult) => {
                const rsc_list: PreviousRegistrationNumber[] = record.get('supplementary_rsa_list');

                return rsc_list?.map(rsc => {
                    if (rsc.previous_registration_number_hyperlinks && rsc.previous_registration_statement_registration_hyperlinks) {
                        const links = record.createLink(rsc.previous_registration_number_hyperlinks);

                        let toReplace = rsc.previous_registration_statement;

                        if (links) {
                            if (Array.isArray(links)) {
                                links.forEach(link => {
                                    if (link && link.value && link.url) {
                                        toReplace = toReplace.replace(link.value, `<a href='${link.url}'>${link.value}</a>`)
                                    }
                                })
                            } else {
                                if (links.value && links.url) {
                                    toReplace = toReplace.replace(links.value, `<a href='${links.url}'>${links.value}</a>`)
                                }
                            }
                        }

                        return toReplace;
                    } else {
                        return rsc.previous_registration_statement;
                    }
                });
            },
            tags: ['html']
        }
    }

    // TODO supplementary_registration_statement_corrections
    get corrections_explained(): VoyagerProperty {
        return {
            label: 'Corrections Explained',
            value: (record: ElasticSearchResult) => {
                const rsc_list: PreviousRegistrationNumber[] = record.get('supplementary_rsc_list');

                return rsc_list?.map(rsc => {
                    if (rsc.previous_registration_number_hyperlinks && rsc.previous_registration_statement_registration_hyperlinks) {
                        const links = record.createLink(rsc.previous_registration_number_hyperlinks);

                        let toReplace = rsc.previous_registration_statement;

                        if (links) {
                            if (Array.isArray(links)) {
                                links.forEach(link => {
                                    if (link && link.value && link.url) {
                                        toReplace = toReplace.replace(link.value, `<a href='${link.url}'>${link.value}</a>`)
                                    }
                                })
                            } else {
                                if (links.value && links.url) {
                                    toReplace = toReplace.replace(links.value, `<a href='${links.url}'>${links.value}</a>`)
                                }
                            }
                        }

                        return toReplace;
                    } else {
                        return rsc.previous_registration_statement;
                    }
                });
            },
            tags: ['html']
        }
    }

    get reproduction_rights_code(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Reproduction Rights Code', 'reproduction_rights_code', ['internal']);
    }

    get publication_status(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Publication Status', 'publication_status', ['internal']);
    }

    // DOES NOT EXIST
    get place_of_publication_code(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Place of Publication Code', 'place_of_publication_code', ['internal']);
    }
}
