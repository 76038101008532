<div *ngIf="this.formGroup" class="cprs-date-picker">
  <form [formGroup]="this.formGroup">
    
    <cd-select
      class="d-block mb-2"
      [label]="'Date type'"
      formControlName="date_type"
      [options]="date_type_options"
      [errorMessage]="'Error State'"
    ></cd-select>
    <cd-date-picker
      class="d-block mb-2"
      [label]="'Start date:'"
      [minDate]="start_min_date"
      [maxDate]="max_date"
      [errorMessage]="'Invalid date'"
      formControlName="start_date"
      (focusout)="adjustStartDate()"
    >
    </cd-date-picker>
    <cd-date-picker
      class="d-block mb-2"
      [label]="'End date:'"
      [minDate]="min_date"
      [maxDate]="max_date"
      [errorMessage]="'Invalid date'"
      formControlName="end_date"
      (focusout)="adjustEndDate()"
    >
    </cd-date-picker>

    <span *ngIf="this.formGroup.hasError('required')" class="text-danger">Please select either a start or end date.</span>

    <div *ngIf="this.showControls" class="mt-3">
      <cd-button class="me-2" (click)="onApply()" mode="primary" [disabled]="!this.formGroup.valid">Apply</cd-button>
      <cd-button class="me-2" (click)="onClear()" mode="secondary">Clear</cd-button>
    </div>
  </form>
</div>
