import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../services/breadcrumb.service';

@Component({
  selector: 'app-unsubscribe-page',
  templateUrl: './unsubscribe-page.component.html',
  styleUrls: ['./unsubscribe-page.component.scss'],
})
export class UnsubscribePageComponent implements OnInit {
  public email: string | null = null;

  public state: 'unsubscribe' | 'resubscribe' = 'unsubscribe';

  constructor(public route: ActivatedRoute, public breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.state = this.route.snapshot.data['state'] ? this.route.snapshot.data['state'] : 'unsubscribe';
    this.email = this.route.snapshot.queryParamMap.get('email');

    this.breadcrumbService.breadcrumbs = [{ name: 'Home', link: '/', linkType: 'routerLink' }];
    this.breadcrumbService.currentBreadcrumbTextOnly = this.state === 'resubscribe' ? 'Resubscribe' : 'Unsubscribe';
  }
}
