import { Deserializable, deserializableProperty } from '../../../shared/interfaces/deserializable';

export class NameDirectoryRequest extends Deserializable {
  @deserializableProperty query: string | undefined;
  @deserializableProperty page_number: number = 0;
  @deserializableProperty records_per_page: number = 0;
  @deserializableProperty starting_position: number = 1;
  @deserializableProperty sort_field: string | 'number_of_records' | 'name' | 'name_type' = 'name';
  @deserializableProperty sort_order: string | 'asc' | 'desc' = 'asc';

  constructor(json: any) {
    super(json);
    if (json && this['deserializablePropertyArray']) {
      this['deserializablePropertyArray'].forEach((p: any) => {
        if (json[p] !== undefined) {
          this[p] = json[p];
        }
      });
    }

    if (json && json['starting_position']) {
      this.starting_position = json['starting_position'];
    }
  }
}

export enum NameDirectorySortFields {
  number_of_records,
  name,
  name_type,
}
