import { Injectable, NgZone } from '@angular/core';
import { StateService } from '../state/state.service';
import { DatePipe } from '@angular/common';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { CprsQueryInterface } from '../pages/advanced-search/advanced-search.component';

export interface ApiParameters {
  page_number: number;
  query: string;
  parent_query: CprsQueryInterface[];
  field_type: string;
  records_per_page: number;
  system_of_origin: string[];
  type_of_record: string[];
  sort_field: string;
  sort_order: string;
  start_date: string;
  end_date: string;
  date_field: string;
  highlight: boolean;
  model: string;
  registration_status: string[];
  registration_class: string[];
  type_of_work: string[];
  recordation_item_type: string[];
}

export const ApiParametersDefaults: ApiParameters = {
  page_number: 0,
  query: '',
  records_per_page: 10,
  system_of_origin: [],
  type_of_record: [],
  sort_field: '',
  sort_order: 'ASC',
  start_date: '',
  end_date: '',
  date_field: '',
  highlight: true,
  model: '',
  registration_status: [],
  registration_class: [],
  type_of_work: [],
  recordation_item_type: [],
  parent_query: [], // CprsQueryInterface
  field_type: '',
};

@Injectable({
  providedIn: 'root',
})
export class ParametersService {
  public params: any;

  constructor(
    public stateService: StateService,
    public datePipe: DatePipe,
    public router: Router,
    public route: ActivatedRoute,
    public ngZone: NgZone
  ) {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });
  }

  dateParameters() {
    const datePicker: {
      date_type: string;
      start_date: string | null;
      end_date: string | null;
    } = this.stateService._state.get('date_picker')?.value;

    datePicker.start_date = this.datePipe.transform(datePicker.start_date, 'y-MM-dd HH:mm:ss');
    datePicker.end_date = this.datePipe.transform(datePicker.end_date, 'y-MM-dd HH:mm:ss');

    return datePicker;
  }

  public deserializeParamsToState() {
    if (Object.keys(this.params).length === 0) {
      return;
    }
    const object: any = {};
    Object.keys(this.params).forEach((param) => (object[param] = JSON.parse(this.params[param])));
    if (object && object.query) {
      this.stateService.search_form.get('query')?.setValue(object.query);
    }
    if (object && object.field_type) {
      this.stateService.search_form.get('keywords')?.setValue(object.field_type);
    }
    if (object && object.page_number) {
      this.stateService.pagination.get('page_number')?.setValue(object.page_number);
    }
    if (object && object.records_per_page) {
      this.stateService.pagination.get('rows_per_page')?.setValue(object.records_per_page); // note the mismatch
    }
    if (object && object.highlight) {
      this.stateService.getFormGroup('highlight').get('highlight')?.setValue(object.highlight);
    }
    if (object && object.parent_query) {
      this.stateService.setQueries([this.getAdvancedSearchForm(object.parent_query)]);
    }
    if (object && object.date_field) {
      this.stateService._state.get('date_picker')?.get('date_type')?.setValue(object.date_field);
    }
    if (object && object.start_date) {
      this.stateService._state.get('date_picker')?.get('start_date')?.setValue(object.start_date);
    }
    if (object && object.end_date) {
      this.stateService._state.get('date_picker')?.get('end_date')?.setValue(object.end_date);
    }
    if (object && object.sort_field) {
      this.stateService._state.get('sort_field')?.setValue(object.sort_field);
    }
    if (object && object.sort_order) {
      this.stateService._state.get('sort_order')?.setValue(object.sort_order);
    }
    if (object && object.parent_query && object.parent_query instanceof Array) {
      const queries = object.parent_query.map((query: CprsQueryInterface) => this.getAdvancedSearchForm(query));
      this.stateService.setQueries(queries);
    }
    if (object && object.system_of_origin && object.system_of_origin instanceof Array) {
      this.deserializeSelectionList('system_of_origin', object.system_of_origin);
    }
    if (object && object.type_of_record && object.type_of_record instanceof Array) {
      this.deserializeSelectionList('type_of_record', object.type_of_record);
    }
    if (object && object.registration_status && object.registration_status instanceof Array) {
      this.deserializeSelectionList('registration_status', object.registration_status);
    }
    if (object && object.registration_class && object.registration_class instanceof Array) {
      this.deserializeSelectionList('registration_class', object.registration_class);
    }
    if (object && object.type_of_work && object.type_of_work instanceof Array) {
      this.deserializeSelectionList('type_of_work', object.type_of_work);
    }
    if (object && object.type_of_acquisition && object.type_of_acquisition instanceof Array) {
      this.deserializeSelectionList('type_of_acquisition', object.type_of_acquisition);
    }
    if (object && object.recordation_item_type && object.recordation_item_type instanceof Array) {
      this.deserializeSelectionList('recordation_item_type', object.recordation_item_type);
    }
    if (object && object.mode) {
      this.stateService._state.get('mode')?.setValue(object.mode);
    }
  }

  getAdvancedSearchForm(
    data: CprsQueryInterface = {
      operator_type: 'AND',
      column_name: 'keyword',
      type_of_query: 'phrase',
      query: '',
    } as CprsQueryInterface
  ): UntypedFormGroup {
    return new UntypedFormGroup({
      operator_type: new UntypedFormControl(data.operator_type),
      column_name: new UntypedFormControl(data.column_name),
      type_of_query: new UntypedFormControl(data.type_of_query),
      query: new UntypedFormControl(data.query),
    });
  }

  public deserializeSelectionList(selectionList: string, filters: string[]) {
    const formArray: UntypedFormArray = this.stateService.selectionLists.get(selectionList) as UntypedFormArray;

    formArray.controls.forEach((group) => {
      const value = group.get('value')?.value;
      if (filters.includes(value)) {
        group.get('selected')?.setValue(true);
      }
    });
  }

  advancedSearchParameters(): ApiParameters {
    const pagination = this.stateService.getPagination();
    const date_picker = this.dateParameters();
    const highlight = this.stateService.getFormGroup('highlight').get('highlight')?.value;

    const source = {
      page_number: pagination.page_number,
      parent_query: this.stateService.getQueries().map((row: UntypedFormGroup) => row.value),
      records_per_page: pagination.rows_per_page,
      sort_field: this.stateService._state.get('sort_field')?.value,
      sort_order: this.stateService._state.get('sort_order')?.value,
      start_date: date_picker.start_date,
      end_date: date_picker.end_date,
      date_field: date_picker.date_type,
      highlight,
      model: '',

      system_of_origin: this.stateService.getSelection('system_of_origin'),
      type_of_record: this.stateService.getSelection('type_of_record'),
      registration_status: this.stateService.getSelection('registration_status'),
      registration_class: this.stateService.getSelection('registration_class'),
      type_of_work: this.stateService.getSelection('type_of_work'),
      recordation_item_type: this.stateService.getSelection('recordation_item_type'),
      type_of_acquisition: this.stateService.getSelection('type_of_acquisition'),
    };

    return this.getParameters(source);
  }

  simpleSearchParameters(): ApiParameters {
    const pagination = this.stateService.getPagination();
    const date_picker = this.dateParameters();
    const highlight = this.stateService.getFormGroup('highlight').get('highlight')?.value;

    const source = {
      page_number: pagination.page_number,
      query: this.stateService.search_form.get('query')?.value,
      field_type: this.stateService.search_form.get('keywords')?.value,
      records_per_page: pagination.rows_per_page,
      system_of_origin: this.stateService.getSelection('system_of_origin'),
      type_of_record: this.stateService.getSelection('type_of_record'),
      sort_field: this.stateService._state.get('sort_field')?.value,
      sort_order: this.stateService._state.get('sort_order')?.value,
      start_date: date_picker.start_date,
      end_date: date_picker.end_date,
      date_field: date_picker.date_type,
      highlight,
      model: '',
      registration_status: this.stateService.getSelection('registration_status'),
      registration_class: this.stateService.getSelection('registration_class'),
      type_of_work: this.stateService.getSelection('type_of_work'),
      recordation_item_type: this.stateService.getSelection('recordation_item_type'),
      type_of_acquisition: this.stateService.getSelection('type_of_acquisition'),
    };

    return this.getParameters(source);
  }

  public addQueryParameters(json: any) {
    // Get the existing query parameters
    const queryParams = this.route.snapshot.queryParams;

    // Convert existing query parameters to a plain JavaScript object
    const queryParamsObject = { ...queryParams };

    // Iterate through the new query parameters and add them to the queryParamsObject
    if (json) {
      Object.keys(json).forEach(key => {
        queryParamsObject[key] = json[key];
      });
    }

    this.ngZone.run(() => {
      // Navigate to the same route with the updated query parameters
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: queryParamsObject,
        queryParamsHandling: 'merge' // This preserves existing query parameters
      });
    })
  }

  public serializeQueryToURL(object: Object, navigate = true, urlPrefix?: string) {
    Object.keys(object).forEach((k) => (object[k] = JSON.stringify(object[k])));
    // create navigation extras with the serialized object
    const navigationExtras: NavigationExtras = {
      queryParams: object,
    };

    const queryParams = new HttpParams({
      fromObject: object as any,
    }).toString();

    // update the current URL with the serialized object
    if (navigate) {
      this.router.navigate([], navigationExtras).then();
    }

    return urlPrefix ? `${urlPrefix}?${queryParams}` : this.router.url.split('?')[0] + '?' + queryParams.toString();
  }

  getParameters(json: any): ApiParameters {
    // This always at least returns "model": undefined. Why?
    return {
      ...(json.page_number && { page_number: json.page_number }),
      ...(json.query && { query: json.query }),
      ...(json.field_type && { field_type: json.field_type }),
      ...(json.parent_query && { parent_query: json.parent_query }),
      ...(json.records_per_page && { records_per_page: json.records_per_page }),
      ...(json.system_of_origin?.length > 0 && { system_of_origin: json.system_of_origin }),
      ...(json.type_of_record?.length > 0 && { type_of_record: json.type_of_record }),
      ...(json.sort_field && { sort_field: json.sort_field }),
      ...(json.sort_order && { sort_order: json.sort_order }),
      ...(json.highlight && { highlight: json.highlight }),
      ...(json.registration_status?.length > 0 && { registration_status: json.registration_status }),
      ...(json.registration_class?.length > 0 && { registration_class: json.registration_class }),
      ...(json.type_of_work?.length > 0 && { type_of_work: json.type_of_work }),
      ...(json.recordation_item_type?.length > 0 && { recordation_item_type: json.recordation_item_type }),
      ...(json.type_of_acquisition?.length > 0 && { type_of_acquisition: json.type_of_acquisition }),
      model: json.model,
      ...(json.date_field && (json.start_date || json.end_date) && { date_field: json.date_field }),
      ...(json.start_date && { start_date: json.start_date }),
      ...(json.end_date && { end_date: json.end_date }),
    } as ApiParameters;
  }
}
