import { Component, OnInit } from '@angular/core';
import { CprsService } from '@src/app/cprs/services/cprs.service';

@Component({
  selector: 'app-popup-survey',
  templateUrl: './popup-survey.component.html',
  styleUrls: ['./popup-survey.component.scss'],
})
export class PopupSurveyComponent implements OnInit {
  public surveyUrl = 'https://www.research.net/r/CPRS-feedback';
  public surveyTimeout = 180_000;
  public modalOpened: boolean = false;

  public firstSearch: boolean = false;

  constructor(public cprsService: CprsService) {}

  ngOnInit(): void {
    this.cprsService.getCurrentSearch().subscribe(() => {
      if (!this.firstSearch) {
        setTimeout(() => {
          this.modalOpened = true;
        }, this.surveyTimeout);

        this.firstSearch = true;
      }
    });
  }

  close() {
    this.modalOpened = false;
  }

  goToSurvey() {
    window.open(this.surveyUrl, '_blank');
    this.modalOpened = false;
  }
}
