import { Component, ViewChild, OnInit } from '@angular/core';
import { ButtonMenuComponent } from '@cop/design/components';
import { CprsService, CprsSearch } from '@src/app/cprs/services/cprs.service';
import { CprsTableGrid } from '../../../table/models/cprs-table-grid.interface';
import { SavedFeatureService } from '../../services/saved.feature.service';
import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { CprsDialogComponent } from '@src/app/cprs/modules/dialogs/components/cprs-dialog/cprs-dialog.component';
import { DownloadDialogComponent } from '@src/app/cprs/modules/dialogs/components/download-dialog/download-dialog.component';
import { CdSelectionService } from '../../../selection/services/selection.service';
import { DialogService } from '@src/app/cprs/modules/dialogs/services/dialog.service';
import { SortDirection, SortEvent } from '@cop/design/table';
import { CprsTableGridComponent } from '../../../table/components/cprs-table-grid/cprs-table-grid.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';

@Component({
  selector: 'cprs-module-saved-records',
  templateUrl: './saved-records.component.html',
  styleUrls: ['./saved-records.component.scss'],
})
export class SavedRecordsComponent implements OnInit {
  @ViewChild('tableGrid')
  tableGrid!: CprsTableGridComponent;

  @ViewChild('actionsMenuButton')
  actionsMenuButton!: ButtonMenuComponent;

  @ViewChild('sortByMenuButton')
  sortByMenuButton!: ButtonMenuComponent;

  public selection: any[] = [];
  public mode: 'grid' | 'table' = 'grid';

  public data: any[] = [];
  public totalElements = 0;
  public current_search: CprsSearch;
  public isLoading: boolean;

  public sortByLabel = 'Sort by';

  public sort_field_options: { text: string; value: { sort_field: string; sort_order: string } }[] = [
    { text: 'Title A-Z', value: { sort_field: 'full_title', sort_order: 'asc' } },
    { text: 'Title Z-A', value: { sort_field: 'full_title', sort_order: 'desc' } },
    { text: 'Date (Oldest)', value: { sort_field: 'representative_date', sort_order: 'asc' } },
    { text: 'Date (Newest)', value: { sort_field: 'representative_date', sort_order: 'desc' } },
    { text: 'Saved on (Oldest)', value: { sort_field: 'timestamp', sort_order: 'asc' } },
    { text: 'Saved on (Newest)', value: { sort_field: 'timestamp', sort_order: 'desc' } },

    // { text: 'Registration date', value: { sort_field: 'registration_date', sort_order: 'asc' } },
    // { text: 'Recordation date', value: { sort_field: 'recordation_date', sort_order: 'desc' } },
    // { text: 'Representative date', value: { sort_field: 'representative_date', sort_order: 'desc' } },
    // { text: 'Publication date', value: { sort_field: 'publication_date', sort_order: 'desc' } },
    // { text: 'Record type', value: { sort_field: 'full_title', sort_order: 'desc' } },
    // { text: 'Type of Work', value: { sort_field: 'type_of_work', sort_order: 'desc' } },

  ];

  public table = new CprsTableGrid('savedRecordsTable', 'List of Saved Records', [
    {
      label: 'Full Title',
      columnKey: 'full_title',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-6',
    },
    {
      label: 'Copyright Number',
      columnKey: 'copyright_number',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },

    {
      label: 'Date',
      columnKey: 'representative_date',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Type',
      columnKey: 'acquisition_type',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },

    {
      label: 'Type of Work',
      columnKey: 'type_of_work',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Owner',
      columnKey: 'owner',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Party 1',
      columnKey: 'party_1',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Publisher Name',
      columnKey: 'publisher_name',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },

    {
      label: 'Claimant',
      columnKey: 'claimant',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Party 2',
      columnKey: 'party_2',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Registration Date',
      columnKey: 'registration_date',
      visibility: { grid: false, table: false, visible: false },
      visible: false,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Recordation Date',
      columnKey: 'recordation_date',
      visibility: { grid: false, table: false, visible: false },
      visible: false,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Publication Date',
      columnKey: 'publication_date',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },

    {
      label: 'Record Type',
      columnKey: 'type_of_record',
      visibility: { grid: false, table: false, visible: false },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
  ]);

  constructor(
    public cdSelectionService: CdSelectionService,
    public dialogService: DialogService,
    public cprsService: CprsService,
    public savedFeatureService: SavedFeatureService,
    public envService: EnvService
  ) { }

  ngOnInit() {
    this.displayTable();

    this.cprsService.loading.subscribe((loading) => {
      this.isLoading = loading;
    });
    this.cprsService.getCurrentSearch().subscribe((resp) => {
      this.current_search = resp;
    });
  }

  displayTable() {
    this.cprsService.getLoadingSubject('saved_records').next(true);

    this.savedFeatureService.getElasticSearchRecords().subscribe(resp => {
      this.totalElements = resp.length;
      this.data = resp.map((esr) => this.getData(esr)).sort((a, b) => {
        const timeA = a.timestamp.value ?? '';
        const timeB = b.timestamp.value ?? '';

        return timeB.localeCompare(timeA);
      });

      // update paging display
      const saved_records: UntypedFormGroup = this.cdSelectionService.getSelectionGroup('saved_records');
      const saved_records_page: UntypedFormControl = saved_records.get('page') as UntypedFormControl;
      const updatePage = saved_records_page.value;
      updatePage.totalElements = resp.length;

      saved_records_page.setValue(updatePage);
      this.cprsService.getLoadingSubject('saved_records').next(false);
      this.cprsService.setCurrentSearchResults('saved_records');
    })
    // this.savedFeatureService.getSavedRecordsSubject().subscribe(resp => {
    //   console.log(resp);

    //   this.totalElements = resp.length;
    //   this.data = resp.map((esr) => this.getData(esr));

    //   // update paging display
    //   const saved_records: FormGroup = this.cdSelectionService.getSelectionGroup('saved_records');
    //   const saved_records_page: FormControl = saved_records.get('page') as FormControl;
    //   const updatePage = saved_records_page.value;
    //   updatePage.totalElements = resp.length;

    //   saved_records_page.setValue(updatePage);
    // })

    // OLD LOCAL STORAGE
    // this.savedFeatureService.getSavedRecordsFromStorage().subscribe((resp) => {
    //   // set the content
    //   this.totalElements = resp.length;
    //   this.data = resp.map((esr) => this.getData(esr));

    //   // update paging display
    //   const saved_records: FormGroup = this.cdSelectionService.getSelectionGroup('saved_records');
    //   const saved_records_page: FormControl = saved_records.get('page') as FormControl;
    //   const updatePage = saved_records_page.value;
    //   updatePage.totalElements = resp.length;

    //   saved_records_page.setValue(updatePage);
    // });
  }

  // Format table data
  getData(esr: ElasticSearchResult) {
    let typeOfWorkVal = esr.findFirst(['type_of_work_to_english', 'cc_type_of_work']);
    if (typeOfWorkVal === 'Cancelled Registrations') {
      typeOfWorkVal = typeOfWorkVal.slice(0, -1);
    }
    return {
      control_number: {
        value: esr.get('control_number'),
      },
      full_title: {
        value: esr.getTitle(true),
        url: esr.getHref(),
      },
      copyright_number: {
        label: esr.getCopyrightNumberLabel(),
        value: esr.getCopyrightNumber(),
      },

      // this needs to have a custom label dependeant on record type
      representative_date: {
        label: esr.isRegistration() ? 'Date' : 'Date of Recordation',
        value: esr.get('representative_date'),
        visible: esr.isRecordation() || esr.isRegistration(),
      },
      acquisition_type: {
        value: esr.get('type_of_acquisition'),
        visible: esr.isAcquisition(),
      },
      type_of_work: {
        label: 'Type of Work',
        value: this.cprsService.toTitleCase(typeOfWorkVal),
      },

      // this also needs a custom label between Party 1 / Owner if GATT
      party_1: {
        label: esr.isGATT() ? 'Owner' : 'Party 1',
        value: esr.getPartyOne(),
      },
      publisher_name: {
        label: 'Publisher Name',
        value: esr.getPublisherName(),
      },
      claimant: {
        label: 'Claimant',
        value: esr.getClaimant(),
      },

      // this also needs a custom label between Party 2 / Author if GATT
      party_2: {
        label: esr.isGATT() ? 'Author' : 'Party 2',
        value: esr.getPartyTwo(),
      },
      publication_date: {
        label: 'Publication Date',
        value: esr.get('representative_date'),
        visible: esr.isAcquisition(),
      },

      service_request_number: {
        label: 'Service Request Number',
        value: esr.get('service_request_number'),
        visible: esr.isRegistration() && this.envService.internal
      },
      url: {
        value: esr.getHref(),
      },
      timestamp: {
        value: esr.saved?.data.timestamp,
      },
      simple_record: {
        value: esr.getSimpleRecord(esr),
      },
      type_of_record: {
        value: esr.get('type_of_record'),
      },
      saved_on: {
        value: esr.saved?.data.timestamp,
      },

      registration_date: {
        value: esr.get('registration_date')
      },
      recordation_date: {
        value: esr.get('recordation_date')
      },
    };
  }

  pageChange($event: any) {
    const page: UntypedFormControl = this.cdSelectionService.getSelectionGroup('saved_records').get('page');

    page.setValue($event);
  }

  sortBy(option: { text: string; value: { sort_field: string; sort_order: string } }) {
    const sortEvent: SortEvent = {
      name: option.value.sort_field,
      direction: option.value.sort_order as SortDirection,
    };

    this.sortByLabel = `Sort by ${option.text}`;

    this.tableGrid.genericSort(sortEvent);
    this.sortByMenuButton.closeDropdown();
  }

  email(): void {
    const records = this.cdSelectionService.getSelectedByKey('saved_records');

    const simple_records = records.map((r) => r.control_number.value);

    this.dialogService.open(CprsDialogComponent, { data: { title: 'Title', records: simple_records } });

    this.actionsMenuButton.closeDropdown();
  }

  download(): void {
    const records = this.cdSelectionService.getSelectedByKey('saved_records');

    const simple_records: any[] = records.map((r) => r.simple_record.value);
    const control_numbers: any[] = records.map((r) => r.control_number.value);

    this.dialogService.open(DownloadDialogComponent, {
      data: { title: 'Title', records: simple_records, control_numbers },
    });

    this.actionsMenuButton.closeDropdown();
  }

  clear() {
    const control_numbers = this.cdSelectionService.getSelectedByKey('saved_records').map(d => d?.control_number?.value);
    // this.savedFeatureService.clearRecordsByControlNumbers(control_numbers);
    this.cprsService.getLoadingSubject('saved_records').next(true);

    this.savedFeatureService.removeRecords(control_numbers).subscribe(() => {
      this.displayTable();
    });
    this.cdSelectionService.deselectAllByKey('saved_records');
    this.actionsMenuButton.closeDropdown();
  }
}
