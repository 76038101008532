import { VoyagerProperty } from '../../../services/transformer.service';
import { Voyager } from '../voyager.class';

export class VoyagerRecordation extends Voyager {
    public schema: VoyagerProperty[] = [

        this.copyright_data.recordation_number,
        this.copyright_data.document_number_display_text,
        this.copyright_data.cancelled_document_number,
        this.copyright_data.entire_copyright_document,
        this.copyright_data.type_of_document,
        this.copyright_data.registration_number_not_verified,
        this.copyright_data.service_request_number,
        this.standard_identifiers.ipn_number,
        this.standard_identifiers.record_id,
        this.standard_identifiers.system_control_number,
        // certificate
        this.copyright_data.certificate,
        this.titles.title_translated,

        // dates
        this.dates.recordation_date,
        this.dates.date_of_publication,
        this.dates.date_of_certification,
        this.dates.date_of_execution,
        // notary date missing
        // effective date missing

        // parties
        this.parties.party_1,
        this.parties.party_2,

        // additional information
        this.notes.notes,
        this.notes.copyright_note_to_staff,
        this.notes.catalog_of_copyright_entries_part_codes,
        this.notes.selection_notes,
        this.notes.regularity_for_serials,

        // affected works
        this.titles.list_of_works,
        this.titles.title,
        // registration number not verified?
        this.titles.alternative_titles,
        this.titles.variant_title,
        this.titles.other_titles,
        this.titles.other_titles_two,
        this.titles.series,
        this.titles.title_appears_in_document,
        this.titles.appears_in,
        this.titles.number_of_pages,
        this.deposit_information.deposit_employee_barcode_location,

        // claims
        this.claim_description.corrections_explained,

        // standard identifiers
        this.standard_identifiers.isbn,
        this.standard_identifiers.issn_list,
        this.standard_identifiers.ean,

        // administrative
        this.administrative.copyright_count_category,
        this.administrative.record_origin_code,
        this.dates.latest_transaction_date_and_time,
        this.administrative.service_request_row_identifier,
        this.standard_identifiers.control_number_identifier,
        this.notes.record_created_by,
        this.notes.selection_decision,
        this.standard_identifiers.originating_control_number,
        this.notes.selection_custodial_division,

        // names
        this.names.names,
        this.names.name_undifferentiated_role,

        // description
        this.description.frequency_of_publication,

    ]
}
