import { ElasticSearchResult } from './elastic-search.interface';
import { HighlightService } from '@src/app/shared/services/highlight/highlight.service';
import { Deserializable } from '@src/app/cprs/shared/interfaces/deserializable';
import { HyperlinkSchema, PreviousRegistrationNumber, RegistrationList, RegistrationNumber, RegistrationTypes } from './types/types';
import { TransformerService } from '../../modules/detailed-record/services/transformer.service';
import { EnvService } from '../../modules/shared/services/env/env.service';
import { CardCatalog } from '../../modules/detailed-record/schemas/card-catalog/card-catalog.class';

export class SchemaProperty {
  label: string = '';
  property: { label: string; value: string } | null = null;
  properties: { label: string; value: string }[] = [];
  internal: boolean = true;
  list: boolean = false;
  groupHeading: string = '';
}

export class Describer extends Deserializable {
  public record: ElasticSearchResult;
  public highlightService = new HighlightService();
  public envService = new EnvService();

  constructor(json: any) {
    super(json);

    if (json.hit) {
      this.record = json.hit;
    }
  }

  set(key: string, value: any): void {
    if (!this.record) {
      return;
    }

    this.record[key] = value;
  }

  get(key: string) {
    if (!this.record) {
      return 'record not found';
    }

    return this.record[key] ? this.record[key] : null;
  }

  get previous_registration_number_list(): PreviousRegistrationNumber[] {
    return this.get('previous_registration_number_list') as PreviousRegistrationNumber[] ?? [];
  }

  get registration_list(): RegistrationList {
    const registration_number_list: RegistrationNumber[] = this.get('registration_number_list') as RegistrationNumber[] ?? [];

    const registration_list: RegistrationList = {
      registration_number: this.findRegistrationBySource(registration_number_list, 'usco'),
      supplemented_by: this.findRegistrationByText(registration_number_list, 'Supplemented by'),
      supplement_to: this.findRegistrationByText(registration_number_list, 'Supplement to'),
      renewal_registration: this.findRegistrationByText(registration_number_list, 'Renewal registration for'),
      reregistration: this.findRegistrationByText(registration_number_list, 'Reregistered as'),
      cancelled_registration: this.findRegistrationByProperty(registration_number_list, 'registration_number_cancelled'),
      preregistration: this.findRegistrationByProperty(registration_number_list, 'preregistration_number')
    }

    return registration_list;
  }

  /// HMMM we may have to rethink hyperlinks
  // we need inline replacement sometime..
  // maybe everything should be like that?
  createLink(schema: HyperlinkSchema) {
    const collection = schema.ids.map((id, index) => {
      if (schema.tokens.length >= index) {
        const matchingToken = schema.tokens[index];

        return {
          value: matchingToken,
          url: this.createRegistrationLink({ registration_number: id } as RegistrationNumber)
        }
      }

      return null;
    })

    return collection.length === 1 ? collection[0] : collection;
  }

  createAdvancedSearchURL(query: string | string[], operator_type: string, column_name: string, type_of_query: string) {
    if (Array.isArray(query)) {
      query = query.join(' ');
    }

    const sq = {
      query,
      operator_type,
      column_name,
      type_of_query,
    };
    return '/advanced-search?parent_query=' + escape(JSON.stringify(sq));
  }

  createRegistrationLink(registration_number: RegistrationNumber) {
    const obj = {
      operator_type: 'AND',
      column_name: 'registration_numbers',
      type_of_query: 'exact',
      query: registration_number.registration_number.replace('/', '').trim().toUpperCase(),
    };

    return '/advanced-search?parent_query=' + JSON.stringify(obj);
  }

  findRegistrationByText(registration_number_list: RegistrationNumber[], text: string) {
    return registration_number_list.find(rn => rn.copyright_number_display_text?.includes(text));
  }

  findRegistrationBySource(registration_number_list: RegistrationNumber[], source: string) {
    const rn = registration_number_list.find(rn => rn.copyright_number_source?.includes(source));
    if (rn) {
      return rn;
    } else {
      return registration_number_list.find(rn => rn.registration_number);
    }
  }

  findRegistrationByProperty(registration_number_list: RegistrationNumber[], property: string) {
    return registration_number_list.find(rn => rn[property]);
  }

  getListString(key: string, seperator: string = ''): string {
    if (!this.record) {
      return 'record not found';
    }

    if (this.record[key] && !(this.record[key] instanceof Array)) {
      return 'key is not a list';
    }

    return this.record[key].join(seperator);
  }

  getImprint(): string {
    const imprint: {
      imprint_publisher_name: string;
      imprint_place_of_publication: string;
    }[] = this.get('imprint_list');

    if (imprint) {
      return imprint.map((i) => i.imprint_publisher_name).join(', ');
    }

    return '';
  }

  getPublisherName(): string {
    const imprint = this.getImprint();

    if (imprint === '') {
      return this.get('publisher_name') ? this.get('publisher_name') : '';
    }

    return imprint;
  }

  getClaimant(): string {
    const claimants: { claimant_full_name: string }[] = this.get('claimants_list');

    if (claimants) {
      return claimants.map((c) => c.claimant_full_name).join(', ');
    }

    if (this.get('system_of_origin') === 'card_catalog') {
      const cc_claimants: { claimant_full_name: string }[] = [this.get('claimant')];

      if (cc_claimants) {
        return cc_claimants.map((c) => {
          if (c?.claimant_full_name) {
            return c.claimant_full_name
          } else {
            return c;
          }
        }).join(', ');
      }
    }

    return '';
  }

  getPartyOne(): string {
    const party_1: { names: string; owner_statement_gatt_document: string }[] = this.get('party_1');

    if (party_1) {
      return party_1.map((p) => p.names || p.owner_statement_gatt_document).join('');
    }

    return '';
  }

  getPartyTwo(): string {
    const party_2: { names: string; author_statement_gatt_document: string }[] = this.get('party_2');

    if (party_2) {
      return party_2.map((p) => p.names || p.author_statement_gatt_document).join('');
    }

    return '';
  }

  getLabel(key: string) {
    if (key === 'full_title') {
      return {
        label: 'Full Title',
        value: this.getTitle(),
      };
    }

    if (key === 'copyright_number') {
      if (this.isRegistration()) {
        return {
          label: 'Registration Number',
          value: this.getCopyrightNumber(),
        };
      } else if (this.isRecordation()) {
        return {
          label: 'Document Number',
          value: this.getCopyrightNumber(),
        };
      } else if (this.isAcquisition()) {
        return {
          label: 'SR Number',
          value: this.get('service_request_number'),
        };
      }
    }

    if (key === 'representative_date') {
      if (this.isRegistration()) {
        return {
          label: 'Date',
          value: this.get('representative_date'),
        };
      } else if (this.isRecordation()) {
        return {
          label: 'Date of Recordation',
          value: this.get('representative_date'),
        };
      } else if (this.isAcquisition()) {
        return {
          label: 'Type',
          value: this.get('type_of_acquisition'),
        };
      }
    }

    if (key === 'additional1') {
      if (this.isRegistration()) {
        return {
          label: 'Type of Work',
          value: this.findFirst(['type_of_work_to_english', 'cc_type_of_work']),
        };
      } else if (this.isRecordation()) {
        return {
          label: this.isGATT() ? 'Owner' : 'Party 1',
          value: this.getPartyOne(),
        };
      } else if (this.isAcquisition()) {
        return {
          label: 'Publisher Name',
          value: this.getPublisherName(),
        };
      }
    }

    if (key === 'additional2') {
      if (this.isRegistration()) {
        return {
          label: 'Claimant',
          value: this.getClaimant(),
        };
      } else if (this.isRecordation()) {
        return {
          label: this.isGATT() ? 'Author' : 'Party 2',
          value: this.getPartyTwo(),
        };
      } else if (this.isAcquisition()) {
        return {
          label: 'Publication Date',
          value: this.get('representative_date'),
        };
      }
    }

    if (key === 'additional3' && this.isRegistration()) {
      return {
        label: 'Service Request Number',
        value: this.get('service_request_number'),
      };
    }
    return {
      label: '',
      value: '',
    };
  }

  getSimpleRecord(record: ElasticSearchResult) {
    const type_of_record = this.get('type_of_record');
    const system_of_origin = this.get('system_of_origin');

    const transformer = new TransformerService();
    transformer.record = record;
    let schema = transformer.createType(record, type_of_record);
    if (system_of_origin === 'card_catalog') {
      const cardCatalog = new CardCatalog();

      const properties = cardCatalog.getSchema();
      schema = transformer.create(record, properties);
    }

    let isInternal = false;
    if (window['__env'] && window['__env']['internal']) {
      isInternal = window['__env']['internal'] ?? false;
    }

    schema.forEach((prop: { label: string, tags: any[], value: any }) => {
      if (prop.label === 'Names' && Array.isArray(prop.value)) {
        const values: any[] = [];
        prop.value.forEach(v => {
          if (Array.isArray(v.value)) {
            return v.value.map((v: any) => values.push(v));
          } else {
            return values.push(v.value);
          }
        });

        prop.value = values;
      }
    })

    const filtered = schema
      .filter(prop => !(prop && prop.tags && prop.tags.includes('no-export')) && prop && (prop.value && prop.value.length > 0))
      .filter(prop => !(prop && prop.tags && prop.tags.includes('internal') && !isInternal));

    const groupings = [{
      label: '',
      properties: filtered
    }]

    let title = this.getTitle();
    if (Array.isArray(title)) {
      title = title.join(' ');
    }

    const return_obj: { header: string; full_title: string; sections: any[]; groupings?: any[] } = {
      header: this.getCprsTitle(),
      full_title: title,
      sections: groupings,
    };

    return return_obj;
  }

  getCopyrightNumberLabel() {
    if (this.isRegistration()) {
      if (this.isCSN()) {
        return 'Copyright Serial Number';
      }
      return 'Registration Number';
    }
    if (this.isRecordation()) {
      return 'Document Number';
    }
    if (this.isAcquisition()) {
      return 'Acquisitions Number';
    }
    return 'Copyright Number';
  }

  isRegistration() {
    return this.get('type_of_record') === 'registration';
  }

  isRecordation() {
    return this.get('type_of_record') === 'recordation';
  }

  isCSN() {
    const registration_number = this.get('registration_number');
    if (!registration_number) {
      return false;
    }
    return this.get('registration_number').toString().toLowerCase().includes('csn');
  }

  isCancelledRegistration(): boolean {
    const hasCancelled = this.registration_list[RegistrationTypes.cancelled_registration]?.registration_number_cancelled;

    return !!hasCancelled;
  }

  isGATT() {
    return this.get('recordation_item_type_code') === 'gatt_notices_of_intent_to_enforce_a_copyright';
  }

  isAcquisition() {
    return this.get('type_of_record') === 'acquisition';
  }

  isCardCatalog() {
    return this.get('system_of_origin') === 'card_catalog';
  }

  getTypeOfRecord() {
    return this.get('type_of_record');
  }

  getTitle(displayUnAvailabelTitle: boolean = false): string {
    const title: any = this.findFirst(['title_concatenated', 'title_of_work']);
    const titleMissing = 'Title Missing. Click here to view detailed record view';
    const isTitleAvailabel: boolean = (title !== null && String(title).trim()?.length > 0);
    return displayUnAvailabelTitle ? (isTitleAvailabel ? title : titleMissing) : title;
  }

  getCopyrightNumber() {
    return this.findFirst(['copyright_number_for_display', 'registration_number', 'service_request_number']) == null || undefined
      ? 'Not available'
      : this.findFirst(['copyright_number_for_display', 'registration_number', 'service_request_number']);
  }

  getTypeOfRecordAsEnglish() {
    if (this.isAcquisition()) {
      return 'Acquisition record';
    } else if (this.isRecordation()) {
      return 'Recordation record';
    } else if (this.isRegistration()) {
      if (this.isCSN()) {
        return 'Copyright Serial Number';
      } else {
        return 'Registration record'
      }
    } else {
      return 'Copyright record';
    }
  }

  getCprsTitle() {
    return [this.getTypeOfRecordAsEnglish(), this.getCopyrightNumber()].join(' ');
  }

  findFirst(keys: string[]) {
    if (this.record === undefined || this.record === null) {
      throw new Error('this.record.hit must be filled in before calling findFirst');
    }
    const values = Object.keys(this.record)
      .filter((k) => keys.includes(k))
      .map((foundKey) => this.record[foundKey]);

    return values.length > 0 ? values[0] : null;
  }

  isLinkList(detail: Object) {
    // eslint-disable-next-line no-prototype-builtins
    return detail.hasOwnProperty('list') && detail.hasOwnProperty('links');
  }

  isSingular(detail: Object) {
    // eslint-disable-next-line no-prototype-builtins
    return detail.hasOwnProperty('property') && detail['property'] && !detail['list'];
  }

  isMultiple(detail: Object) {
    // eslint-disable-next-line no-prototype-builtins
    return detail.hasOwnProperty('properties') && detail['properties'].length > 0;
  }

  isDisplayList(detail: Object) {
    // eslint-disable-next-line no-prototype-builtins
    return detail.hasOwnProperty('list') && detail['list'] === true;
  }

  isAvailable(detail: Object) {
    return (
      (detail['property'] && detail['property'].length > 0) ||
      (detail['properties'] && detail['properties'].length > 0 && detail['properties'].join('').length > 0)
    );
  }

  isGroupAvailable(group: SchemaProperty) {
    let display = false;
    if (group && group.properties && group.properties.length > 0) {
      group.properties.forEach((p) => {
        if (this.isAvailable(p)) {
          display = true;
        }
      });
    }
    return display;
  }

  getHref() {
    const isCardCatalog = this.get('system_of_origin') === 'card_catalog';
    return isCardCatalog
      ? `/application-card/${this.get('control_number')}`
      : `/detailed-record/${this.get('control_number')}`;
  }
}
