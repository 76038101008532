import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import * as OpenSeadragon from 'openseadragon';
import { Subscription } from 'rxjs/internal/Subscription';
import { Viewer } from 'openseadragon';
import { Option } from '@cop/design/forms/lib/select/option';
import { FeatureFlagService } from '@src/app/cprs/modules/shared/services/feature-flag/feature-flag.service';

export class OpenSeadragonTileSource {
  text?: string;
  value?: any;
  tileSource: {
    type: string;
    url: string;
    filename: string;
  } | null = null;
}

@Component({
  selector: 'cd-openseadragon',
  templateUrl: './cd-openseadragon.component.html',
  styleUrls: ['./cd-openseadragon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CdOpenseadragonComponent implements OnDestroy, OnChanges {
  subscriptions: Subscription[] = [];
  public viewer: Viewer | null;

  public availableImages: OpenSeadragonTileSource[] = [];

  public slideshow: null;
  public interval: any;
  public slideshowMs: number = 7000;

  public viewTypes = [
    { text: 'Single Image', value: 'single_image' },
    { text: 'List', value: 'list' },
    { text: 'Gallery', value: 'gallery' },
    { text: 'Grid', value: 'grid' },
  ];

  public isSingleImageView = true;

  public cardImages = new UntypedFormGroup({
    images: new UntypedFormControl(0),
    viewTypes: new UntypedFormControl('single_image'),
  });

  @Input() tileSources: OpenSeadragonTileSource[];

  @Input() options: Option[];

  @Input() showDownload = true;

  @Input() showImagePager = true;

  @Input() showImageViewer = true;

  public rotationStep = 90; // Adjust the step size as needed
  public currentRotation = 0;

  constructor(
    public httpClient: HttpClient,
    public router: Router,
    public featureFlagService: FeatureFlagService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tileSources']) {
      const images = changes['tileSources'].currentValue;
      const config = this.getSingleImageView();
      config.tileSources = images;

      this.cardImages.get('images')?.setValue(0);
      this.cardImages.get('viewTypes')?.setValue('single_image');

      this.create(config);
    }
  }

  create(config: any) {
    this.viewer?.destroy();
    const openseadragon_container = document.querySelector('.openseadragon-container');
    if (openseadragon_container) {
      openseadragon_container.remove();
    }

    this.availableImages = this.createAvailableImages();

    this.viewer = new OpenSeadragon.Viewer(config as any);
  }

  toSingleImage() {
    const config = this.getSingleImageView();
    config.tileSources = this.tileSources;
    this.create(config);

    this.isSingleImageView = true;
  }

  toList() {
    const config = this.getListView();
    config.tileSources = this.tileSources;

    this.create(config);

    const item = this.availableImages.find(Boolean);
    if (item) {
      item.text = '1 of 1';

      this.availableImages = [item];
    }

    this.isSingleImageView = false;
  }

  toGrid() {
    const config = this.getGridView();
    config.tileSources = this.tileSources;
    this.create(config);

    const item = this.availableImages.find(Boolean);
    if (item) {
      item.text = '1 of 1';

      this.availableImages = [item];
    }

    this.isSingleImageView = false;
  }

  toGallery() {
    const config = this.getGalleryView();
    config.tileSources = this.tileSources;
    this.create(config);

    const item = this.availableImages.find(Boolean);
    if (item) {
      item.text = '1 of 1';

      this.availableImages = [item];
    }

    this.isSingleImageView = false;
  }

  prev() {
    if (this.availableImages.length <= 1) {
      return;
    }

    if (this.viewer) {
      const currentImageIndex = this.viewer['_sequenceIndex'];
      const maxImages = this.tileSources.length - 1;

      if (currentImageIndex <= maxImages) {
        const prevPage = currentImageIndex - 1;
        this.setPage(prevPage);
      }

      if (currentImageIndex <= 0) {
        const prevPage = maxImages;
        this.setPage(prevPage);
      }
    }
  }

  next() {
    if (this.availableImages.length <= 1) {
      return;
    }

    if (this.viewer) {
      const currentIndex = this.viewer['_sequenceIndex'];
      const totalPages = this.tileSources.length;

      if (currentIndex + 1 < totalPages) {
        this.setPage(currentIndex + 1);
      } else {
        this.setPage(0);
      }
    }
  }

  setPage(pageIndex: number) {
    this.viewer?.goToPage(pageIndex);
    this.cardImages.get('images')?.setValue(pageIndex);
  }

  rotate() {
    this.currentRotation = this.currentRotation + this.rotationStep;
    this.viewer?.viewport.setRotation(this.currentRotation);
    if (this.currentRotation === 360) {
      this.currentRotation = 0;
    }
  }

  home() {
    this.viewer?.viewport.goHome();
  }

  zoomOut() {
    const zoom = Number(this.viewer?.viewport.getZoom()) - (Number(this.viewer?.viewport.getZoom()) * 0.2);
    this.viewer?.viewport.zoomTo(zoom);
  }

  zoomIn() {
    const zoom = Number(this.viewer?.viewport.getZoom()) + (Number(this.viewer?.viewport.getZoom()) * 0.2);
    this.viewer?.viewport.zoomTo(zoom);
  }

  fullscreen() {
    this.viewer?.setFullScreen(true);
  }

  changeView() {
    const view = this.cardImages.get('viewTypes')?.value;

    switch (view) {
    case 'grid': {
      this.toGrid();

    break;
    }
    case 'list': {
      this.toList();

    break;
    }
    case 'gallery': {
      this.toGallery();

    break;
    }
    default: {
      this.toSingleImage();
    }
    }
  }

  goto() {
    const pageIndex = this.cardImages.get('images')?.value;

    if (this.viewer && pageIndex === this.viewer['_sequenceIndex']) {
      return;
    }

    this.setPage(pageIndex);
  }

  /*
    Downloads the image using npm library 'file-saver' to download the JPEG
      - filename for download is stored in the tileSource object data
  */
  downloadImage() {
    const imageToDownload: OpenSeadragonTileSource | undefined =
      this.availableImages[(this.viewer as any)._sequenceIndex];

    if (imageToDownload && imageToDownload.tileSource && imageToDownload.tileSource.url) {
      const subscription = this.httpClient
        .get(imageToDownload.tileSource.url, { headers: undefined, responseType: 'blob' })
        .pipe(
          map((res) => {
            return new Blob([res], { type: 'image/jpeg' });
          })
        )
        .subscribe((data) => {
          saveAs(data, imageToDownload.tileSource?.filename);
        });

      this.subscriptions.push(subscription);
    }
  }

  createAvailableImages(): OpenSeadragonTileSource[] {
    if (!this.tileSources) {
      // Returning null creates null handling problems everywhere.
      throw new Error('No tileSources, cannot createAvailableImages');
    }

    return this.tileSources.map((tile, i) => {
      return {
        text: `${i + 1} of ${this.tileSources.length}`,
        value: i,
        tileSource: {
          type: tile.tileSource?.type ?? '',
          filename: tile.tileSource?.filename ?? '',
          url: tile.tileSource?.url ?? '',
        },
      };
    });
  }

  /*
    OpenSeadragon Viewer Configurations
      - Single Image
      - List, Gallery, Grid View
      - Slideshow
  */
  getSingleImageView(): OpenSeadragonViewConfiguration {
    const config = new OpenSeadragonViewConfiguration();
    config.tileSources = this.tileSources;
    this.isSingleImageView = true;
    return JSON.parse(JSON.stringify(config));
  }

  getListView(): OpenSeadragonViewConfiguration {
    const config = new OpenSeadragonViewConfiguration();
    config.tileSources = this.tileSources;
    config.collectionLayout = 'vertical';
    config.collectionRows = 1;
    config.collectionMode = true;
    config.sequenceMode = false;
    return JSON.parse(JSON.stringify(config));
  }

  getGalleryView(): OpenSeadragonViewConfiguration {
    const config = new OpenSeadragonViewConfiguration();
    config.tileSources = this.tileSources;
    config.collectionRows = 4;
    config.collectionMode = true;
    config.sequenceMode = false;
    return JSON.parse(JSON.stringify(config));
  }

  getGridView(): OpenSeadragonViewConfiguration {
    const config = new OpenSeadragonViewConfiguration();
    config.tileSources = this.tileSources;
    config.collectionLayout = 'vertical';
    config.collectionRows = 6;
    config.collectionMode = true;
    config.sequenceMode = false;
    return JSON.parse(JSON.stringify(config));
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }
}

export class OpenSeadragonViewConfiguration {
  id = 'openseadragon-holder';
  showSequenceControl = true;
  zoomPerClick = 2;
  zoomInButton = 'zoomIn';
  zoomOutButton = 'zoomOut';
  homeButton = 'homePage';
  rotateRightButton = 'rotate';
  fullPageButton = 'fullPage';
  previousButton = 'previous';
  nextButton = 'next';
  navPrevNextWrap = true;
  tileSources: OpenSeadragonTileSource[] = [];
  showRotationControl = true;
  gestureSettingsTouch = {
    pinchRotate: true,
  };

  showReferenceStrip = false;
  collectionMode = true;
  sequenceMode = true;
  debugMode = false;
  collectionRows: number = 0;
  collectionLayout: string = 'vertical';
}
