import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { EnvService } from '../../shared/services/env/env.service';
import { CentralErrorService } from '@app/cprs/services/central.error.service';
import { FeatureFlagService } from '../../shared/services/feature-flag/feature-flag.service';

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {
  constructor(
    private envService: EnvService,
    private featureFlagService: FeatureFlagService,
    public centralErrorService: CentralErrorService,
    public ngZone: NgZone,
  ) {
    this.featureFlagService.isSentryLoggingEnabled().subscribe(isEnabled => {
      if (isEnabled) {
        this.init();
      }
    });
  }

  init() {
    Sentry.init({
      dsn: this.envService.sentryDsn,
      environment: this.envService.envName,
    });
    console.log('Centralized error logger initializing...');
  }

  handleError(error: any) {
    const message = CentralErrorService.identifyMessage(error);

    // if (window.location.hostname === 'localhost') {
    //   console.log(error);
    //   // Can't throw here or the error handler stops handling errors!
    //   // throw error;
    // }
    // else

    if (
      this.envService.envName === 'PROD' ||
      this.envService.envName === 'PROD-INTERNAL' ||
      this.envService.envName === 'STAGING' ||
      this.envService.envName === 'STAGING-INTERNAL'
    ) {
      // PROD-* AND STAGING-*
      // Whitelist errors related to things the user can do something about, e.g. empty search/defective search
      // Or server timeout, resource doesnt' exist.
      const whitelist = ['Server error: Punctuation queries must have 3 or more characters'];
      if (whitelist.includes(message)) {
        // This will trigger a popup
        this.centralErrorService.addError(message, error);
      } else {
        console.log("Error not on whitelist, won't notify user");
        console.log(error);
      }
    } else {
      // black list certain errors we can't fix. Write to console anyhow.
      if (message === 'n.find is not a function') {
        console.log("Blacklisted error that we can't fix");
        console.log(error);
      } else {
        this.centralErrorService.addError(message, error);
      }
    }
    const currentError = error.originalError || error;
    if (!(error instanceof HttpErrorResponse)) {
      console.log('SENTRY ERROR:', currentError);
    }
    try {
      let value = sessionStorage.getItem('sentryError');
      if (value === null) {
        value = '';
      }
      const sentryErr = sessionStorage.getItem('sentryError') ? JSON.parse(value) : [];
      if (sentryErr.includes(currentError)) {
        sentryErr.push(currentError);
        const latestSentryErr = JSON.stringify(sentryErr);
        sessionStorage.setItem('sentryError', latestSentryErr);
      } else {
        Sentry.captureException(currentError);
      }
    } catch {
      console.warn('SENTRY_LOG_EXCEPTION');
    }
  }

  clear() {
    sessionStorage.clear();
  }
}
