<cd-table *ngIf="this.dataSource" [caption]="'List of Users'" [id]="'selectTable'" [classes]="'mb-3'">
  <thead>
    <tr>
      <ng-container *ngFor="let column of displayedColumns">
        <th>{{ this.getLabelByKey(column) }}</th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of dataSource">
      <ng-container *ngFor="let column of displayedColumns">
        <td>{{ data[column] }}</td>
      </ng-container>
    </tr>
  </tbody>
</cd-table>
