import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivate } from '@angular/router';

import { FeatureFlagService } from '@src/app/cprs/modules/shared/services/feature-flag/feature-flag.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MaintenanceGuard implements CanActivate {
  constructor(protected router: Router, protected featureFlagService: FeatureFlagService) {}

  canActivate(_route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.featureFlagService.isRedirectAllToSystemDownNoticeEnabled().pipe(
      map((enabled) => {
        if (enabled) {
          this.router.navigate(['/maintenance']).then();
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
