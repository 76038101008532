import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CprsDialogComponent } from './components/cprs-dialog/cprs-dialog.component';
import { DesignComponentsModule } from '@cop/design/components';
import { DesignFormsModule } from '@cop/design/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CprsErrorComponent } from './components/cprs-error/cprs-error.component';
import { DisclaimerDialogComponent } from './components/disclaimer-dialog/disclaimer-dialog.component';
import { DownloadDialogComponent } from './components/download-dialog/download-dialog.component';
import { PopupSurveyComponent } from './components/popup-survey/popup-survey.component';
import { DesignShareModule } from '@cop/design/_share';

@NgModule({
  declarations: [
    CprsDialogComponent,
    CprsErrorComponent,
    DisclaimerDialogComponent,
    DownloadDialogComponent,
    PopupSurveyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DesignComponentsModule,
    DesignFormsModule,
    DesignShareModule
  ],
  exports: [
    CprsDialogComponent,
    CprsErrorComponent,
    DisclaimerDialogComponent,
    DownloadDialogComponent,
    PopupSurveyComponent
  ]
})
export class DialogsModule { }
