import { KeycloakService } from 'keycloak-angular';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';
import { FeatureFlagService } from '@src/app/cprs/modules/shared/services/feature-flag/feature-flag.service';

export function keycloakInitializer(
  keycloak: KeycloakService,
  envService: EnvService,
  featureFlatService: FeatureFlagService
): () => Promise<boolean> {
  return () => {
    // Probably didn't need toPromise here.
    return featureFlatService
      .isLoginEnabled()
      .toPromise()
      .then((enabled) => {
        if (enabled) {
          const keycloakConfig: any = envService.keycloakConfig;
          return keycloak.init({
            config: keycloakConfig,
            initOptions: {
              onLoad: 'check-sso',
              silentCheckSsoFallback: false,
              silentCheckSsoRedirectUri: document.baseURI + 'assets/silent-check-sso.html',
              checkLoginIframe: false,
              enableLogging: true,
              checkLoginIframeInterval: 10_000,
            },
            bearerExcludedUrls: [
              'https://api-dev.publicrecords.usco.lctl.gov/search_service',
              'https://api-test.publicrecords.usco.lctl.gov/search_service',
              'https://api.publicrecords.copyright.gov/feature_flag_service/*',
              'https://api-staging.publicrecords.copyright.gov/feature_flag_service/*',
              'https://api-dev.publicrecords.usco.lctl.gov/feature_flag_service/*',
              'https://api-test.publicrecords.usco.lctl.gov/feature_flag_service/*',
              'https://tile.loc.gov/*',
            ],
            enableBearerInterceptor: true,
          });
        } else {
          // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
          return Promise.resolve(false);
        }
      });

    //   .pipe(
    //   map((enabled) => {
    //     if (enabled) {
    //       const keycloakConfig: any = envService.keycloakConfig;
    //       return keycloak.init({
    //         config: keycloakConfig,
    //         initOptions: {
    //           onLoad: 'check-sso',
    //           checkLoginIframe: false
    //         },
    //         enableBearerInterceptor: true
    //       });
    //     } else {
    //       return Promise.resolve(false);
    //     }
    //   })
    // ).toPromise();
  };
}
