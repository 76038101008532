import { CprsService } from '@src/app/cprs/services/cprs.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CprsRecentRecord, RecentFeatureService } from '../../services/recent.service';
import { ButtonMenuComponent } from '@cop/design/components';
import { DownloadDialogComponent } from '@src/app/cprs/modules/dialogs/components/download-dialog/download-dialog.component';
import { DialogService } from '@src/app/cprs/modules/dialogs/services/dialog.service';
import { CprsDialogComponent } from '@src/app/cprs/modules/dialogs/components/cprs-dialog/cprs-dialog.component';
import { CdSelectionService } from '../../../selection/services/selection.service';
import { CprsTableGrid } from '../../../table/models/cprs-table-grid.interface';
import { SortDirection, SortEvent } from '@cop/design/table';
import { CprsTableGridComponent } from '../../../table/components/cprs-table-grid/cprs-table-grid.component';
import { DatePipe } from '@angular/common';
import { SavedFeatureService } from '../../../saved/services/saved.feature.service';
import { FeatureFlagService } from '../../../shared/services/feature-flag/feature-flag.service';
import { ParametersService } from '@src/app/cprs/services/parameters.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cprs-module-recent-records',
  templateUrl: './recent-records.component.html',
  styleUrls: ['./recent-records.component.scss'],
})
export class RecentRecordsComponent implements OnInit {
  @ViewChild('actionsMenuButton')
  actionsMenuButton!: ButtonMenuComponent;

  @ViewChild('sortByMenuButton')
  sortByMenuButton!: ButtonMenuComponent;

  @ViewChild('tableGrid')
  tableGrid!: CprsTableGridComponent;

  public sort_field_options: { text: string; value: { sort_field: string; sort_order: string } }[] = [
    { text: 'Title A-Z', value: { sort_field: 'full_title', sort_order: 'asc' } },
    { text: 'Title Z-A', value: { sort_field: 'full_title', sort_order: 'desc' } },
    { text: 'Record type', value: { sort_field: 'full_title', sort_order: 'desc' } },

    { text: 'Date (oldest)', value: { sort_field: 'representative_date', sort_order: 'asc' } },
    { text: 'Date (newest)', value: { sort_field: 'representative_date', sort_order: 'desc' } },
    { text: 'Date (viewed)', value: { sort_field: 'timestamp', sort_order: 'desc' } },
  ];

  public newTable = new CprsTableGrid('recentRecordsTable', 'List of Recent Records', [
    {
      label: 'Full Title',
      columnKey: 'full_title',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-6',
    },
    {
      label: 'Copyright Number',
      columnKey: 'copyright_number',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },

    {
      label: 'Date',
      columnKey: 'representative_date',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Type',
      columnKey: 'acquisition_type',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },

    {
      label: 'Type of Work',
      columnKey: 'type_of_work',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Owner',
      columnKey: 'owner',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Party 1',
      columnKey: 'party_1',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Publisher Name',
      columnKey: 'publisher_name',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },

    {
      label: 'Claimant',
      columnKey: 'claimant',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Party 2',
      columnKey: 'party_2',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
    {
      label: 'Publication Date',
      columnKey: 'publication_date',
      visibility: { grid: true, table: false, visible: true },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },

    {
      label: 'Record Type',
      columnKey: 'type_of_record',
      visibility: { grid: false, table: false, visible: false },
      visible: true,
      sortable: true,
      classes: 'col-2',
    },
  ]);

  public mode: 'grid' | 'table' = 'grid';

  public totalElements: number;

  public selection: any[] = [];

  public data: any[] = [];

  public sortEvent: SortEvent;
  public sortLabel: string = 'Sort by';

  constructor(
    public cdSelectionService: CdSelectionService,
    public dialogService: DialogService,
    public recentService: RecentFeatureService,
    public cprsService: CprsService,
    public savedService: SavedFeatureService,
    public featureFlagService: FeatureFlagService,
    public datePipe: DatePipe,
    public parameterService: ParametersService,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.cdSelectionService.deselectAllByKey('recent_records');

    this.route.queryParamMap.subscribe(paramMap => {
      if (!paramMap.has('name') || !paramMap.has('direction')) {
        return;
      }

      const sortEvent = {
        name: JSON.parse(paramMap.get('name') ?? ''),
        direction: JSON.parse(paramMap.get('direction') ?? '') as SortDirection
      } as SortEvent;

      if (sortEvent.name && sortEvent.direction) {
        this.sortEvent = sortEvent;
        const option = this.sort_field_options.find(
          (o) => o.value.sort_field === sortEvent?.name && o.value.sort_order === sortEvent?.direction
        );

        if (option) {
          this.sortLabel = `Sort by ${option.text}`;
        }
      }
    })

    this.updateTable();
    // this.recentService.getRecentRecords().subscribe((recent: CprsRecentRecordData[]) => {
    //   this.data = recent.reverse();
    //   this.totalElements = this.data.length;
    //   this.data.forEach((types: any) => {
    //     if (types.type_of_work && types.type_of_work?.value) {
    //       types.type_of_work.value = this.cprsService.toTitleCase(types.type_of_work.value);
    //     }
    //   })
    // });
  }

  updateTable() {
    this.cprsService.setLoadingSubject('recent_records', true);
    this.recentService.getRecentRecordsFromService().subscribe(resp => {
      this.data = resp.map(r => this.recentService.getData(r));
      this.data.sort((a, b) => {
        const timestampA = a.unix_timestamp.value;
        const timestampB = b.unix_timestamp.value;

        if (timestampB < timestampA) {
          return -1;
        }
        if (timestampB > timestampA) {
          return 1;
        }
        return 0;
      });
      this.totalElements = this.data.length;

      this.data.forEach((types: any) => {
        if (types.type_of_work && types.type_of_work?.value) {
          types.type_of_work.value = this.cprsService.toTitleCase(types.type_of_work.value);
        }
      })
      this.data.sort((a, b) => {
        return new Date(b['timestamp'].value).getTime() - new Date(a['timestamp'].value).getTime();
      });

      console.log('do we have a sort event?', this.sortEvent, this.tableGrid, this.data);
      if (this.sortEvent && this.data.length > 0) {
        this.tableGrid.content = this.data;
        this.tableGrid.genericSort(this.sortEvent);
      }

      this.cprsService.setLoadingSubject('recent_records', false);
      this.updateRecentRecordOrder();
      this.cprsService.setCurrentSearchResults('recent_records');
    });
  }

  sortBy(option: { text: string; value: { sort_field: string; sort_order: string } }) {
    const sortEvent: SortEvent = {
      name: option.value.sort_field,
      direction: option.value.sort_order as SortDirection,
    };

    this.sortLabel = `Sort by ${option.text}`;

    this.tableGrid.genericSort(sortEvent);
    this.parameterService.serializeQueryToURL(sortEvent);

    this.sortByMenuButton.closeDropdown();
    this.updateRecentRecordOrder();
  }

  getTableData(recent_record: CprsRecentRecord) {
    return {
      full_title: {
        value: recent_record.full_title,
      },
      copyright_number: {
        value: recent_record.copyright_number,
      },
      representative_date: {
        value: recent_record.representative_date,
      },
      date_viewed: {
        value: recent_record.date_viewed,
      },
      type_of_record: {
        value: recent_record.describer.get('type_of_record')
      }
    };
  }

  email(): void {
    const records = this.cdSelectionService.getSelectedByKey('recent_records');

    const simple_records = records.map((r) => r.control_number.value);

    this.dialogService.open(CprsDialogComponent, { data: { title: 'Title', records: simple_records } });

    this.actionsMenuButton.closeDropdown();
  }

  download(): void {
    const records = this.cdSelectionService.getSelectedByKey('recent_records');

    const simple_records: any[] = records.map((r) => r.simple_record.value);
    const control_numbers: any[] = records.map((r) => r.control_number.value);

    this.dialogService.open(DownloadDialogComponent, {
      data: { title: 'Title', records: simple_records, control_numbers },
    });

    this.actionsMenuButton.closeDropdown();
  }

  save(): void {
    const records = this.cdSelectionService.getSelectedByKey('recent_records');

    const control_numbers: any[] = records.map((r) => r.control_number.value);

    this.savedService.saveRecords(control_numbers).subscribe();
  }

  isSaved(recent_record: CprsRecentRecord) {
    return this.savedService.isSaved(recent_record.control_number['value']);
  }

  saveRecord(recent_record: CprsRecentRecord) {
    this.savedService.saveRecords(recent_record.control_number['value']).subscribe();
  }

  removeRecord(recent_record: CprsRecentRecord) {
    this.savedService.removeRecords(recent_record.control_number['value']).subscribe()
  }

  clear(): void {
    const selected = this.cdSelectionService.getSelectedByKey('recent_records');
    this.recentService.removeRecentRecords(selected);
    this.cdSelectionService.deselectAllByKey('recent_records');
    this.totalElements = this.data.length;
    this.actionsMenuButton.closeDropdown();

    this.updateTable();
  }

  selectionChange($event: any[]) {
    this.selection = $event;
  }

  updateRecentRecordOrder() {
    this.recentService.getRecentRecordsList().subscribe((search: any) => {
      const response = search;
      const timeStampList = this.data?.map((e) => e.unix_timestamp.value);
      response.data?.sort((a: any, b: any) => {
        return timeStampList.indexOf(a.get('unix_timestamp')) - timeStampList.indexOf(b.get('unix_timestamp'))
      })
      if (response?.data) {
        this.cprsService.setRecentRecordSearch(response);
      }
    })
  }
}
