import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss'],
})
export class SessionExpiredComponent {
  constructor(private router: Router) {}

  search() {
    this.router.navigateByUrl('/search').then();
  }

  advancedSearch() {
    this.router.navigate(['/advanced-search']).then();
  }
}
