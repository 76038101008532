<!--Scroll to top-->
<div id="scroll-to-top">
  <cd-button
    [btnClass]="'cd-bg-white'"
    [btnId]="'scroll-to-top'"
    [mode]="'secondary'"
    [size]="'large'"
    [type]="'button'"
    (btnClick)="scrollToTop()"
  >
    Back to Top
    <cd-icon [cdsIcon]="'caret-up'"></cd-icon>
  </cd-button>
</div>
