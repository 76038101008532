import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Deserializable, deserializableProperty } from '@src/app/cprs/shared/interfaces/deserializable';
// import { AuthGuardService } from '../guards/auth.guard.service';
import { EnvService } from '../env/env.service';
import { SimplerRestService } from '../rest/rest.service';
import { Cacheable, GlobalCacheConfig, LocalStorageStrategy } from 'ts-cacheable';

GlobalCacheConfig.storageStrategy = LocalStorageStrategy;

const MAX_CACHE_AGE = 15 * 60 * 1000;

function fixup_env(environment: string): string {
  environment = environment.replace('STAGING', 'STAGE');
  if (environment.includes('-')) {
    return environment.split('-')[0]?.toLowerCase() ?? '';
  }
  return environment.toLowerCase();
}

@Injectable({ providedIn: 'root' })
export class FeatureFlagService extends SimplerRestService {
  subscriptions: Subscription[] = [];
  private readonly baseUrl: string;

  // Fix after merge.
  // eslint-disable-next-line no-use-before-define
  private featureFlags: FeatureFlagResponse | undefined;

  // Fix after merge.
  // eslint-disable-next-line no-use-before-define
  // private authenticatedFeatureFlags: AuthenticatedFeatureFlagResponse | undefined;

  public shortLowerCaseEnvName: string;

  private features: { [id: string]: boolean } | undefined;

  constructor(
    protected override http: HttpClient,
    protected override envService: EnvService // Temp disable for cyclic imports // public authGuardService: AuthGuardService
  ) {
    super(http, 'SearchService', envService);

    this.baseUrl = envService.featureFlagServiceUrl;
    this.shortLowerCaseEnvName = fixup_env(envService.envName);
    // this.checkForAuthentication();
  }

  // checkForAuthentication() {
  //   const auth_subscription = this.authGuardService.isAuthenticated.subscribe((authed) => {
  //     if (authed) {
  //       const flag_subscription = this.getAuthenticatedFeatureFlags().subscribe(
  //         // eslint-disable-next-line no-empty-function
  //         (_resp) => {},
  //         (_failed) => {
  //           const subscription = this.getFeatureFlags().subscribe();
  //           this.subscriptions.push(subscription);
  //         }
  //       );
  //       this.subscriptions.push(flag_subscription);
  //     } else {
  //       const subscription = this.getFeatureFlags().subscribe();
  //       this.subscriptions.push(subscription);
  //     }
  //   });
  //   this.subscriptions.push(auth_subscription);
  // }
  //
  // getAuthenticatedFeatureFlags(): Observable<AuthenticatedFeatureFlagResponse> {
  //   const url = `${this.baseUrl}/AuthenticatedFeatureFlags/`;
  //
  //   const params = {
  //     application: 'public_records_search_ui',
  //     environment: this.shortLowerCaseEnvName,
  //   };
  //
  //   return this.http.get<any>(url, { params }).pipe(
  //     map((response) => {
  //       this.authenticatedFeatureFlags = new AuthenticatedFeatureFlagResponse(response);
  //       if (this.featureFlags === undefined || this.featureFlags.quick_flags === undefined) {
  //         throw new Error('No quick flags, cannot continue');
  //       }
  //       this.features = this.featureFlags.quick_flags;
  //       return this.authenticatedFeatureFlags;
  //     })
  //   );
  // }

  // 1000 ms, 60 seconds, 60 minutes, 4 hours
  @Cacheable({ maxAge: MAX_CACHE_AGE })
  public feature_flags_request(
    url: string,
    params:
      | HttpParams
      | {
          [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        }
  ): Observable<any> {
    return this.http.get<any>(url, { params });
  }

  // 1000 ms, 60 seconds, 60 minutes, 4 hours
  @Cacheable({ maxAge: MAX_CACHE_AGE })
  public getFeatureFlagsAsync(feature: string): Observable<boolean> {
    const url = `${this.baseUrl}/FeatureFlags/`;

    const params = {
      application: 'public_records_search_ui',
      environment: this.shortLowerCaseEnvName,
    };

    return this.feature_flags_request(url, params).pipe(
      map((response) => {
        this.processFeatureFlags(response);
        return this.lookupValue(feature);
      }),
      catchError(() => {
        return of(false);
      })
      // does shareReplay do anything?
    );
  }

  // 1000 ms, 60 seconds, 60 minutes, 4 hours
  // @Cacheable({ maxAge: 1000 * 60 * 60 * 4 })
  // private getFeatureFlags(): Observable<FeatureFlagResponse> {
  //   const url = `${this.baseUrl}/FeatureFlags/`;
  //
  //   const params = {
  //     application: 'public_records_search_ui',
  //     environment: this.shortLowerCaseEnvName,
  //   };
  //
  //   return this.feature_flags_request(url, params).pipe(
  //     map((response) => {
  //       return this.processFeatureFlags(response);
  //     }),
  //     // Have no idea if this will cache correctly
  //     shareReplay(1) // Cache the last emitted value
  //   );
  // }

  private processFeatureFlags(flagResp: any) {
    this.featureFlags = new FeatureFlagResponse(flagResp);
    if (this.featureFlags === undefined || this.featureFlags.quick_flags === undefined) {
      throw new Error('No quick flags, cannot continue');
    }
    const keys: string[] = Object.keys(this.featureFlags.quick_flags);
    if (keys) {
      this.features = {};
      keys.forEach((key) => {
        if (
          this.features === undefined ||
          this.featureFlags === undefined ||
          this.featureFlags.quick_flags === undefined
        ) {
          throw new Error('No quick flags, cannot continue');
        }
        const value = this.featureFlags.quick_flags[key];
        if (value === undefined) {
          throw new Error("Undefined feature flags, can't continue");
        }
        this.features[key] = value;
      });
    }
    return this.featureFlags;
  }

  @Cacheable({ maxAge: 1000 * 60 * 60 * 4 })
  public isFeatureEnabled(feature: string): Observable<boolean> {
    if (!feature) {
      throw new Error('No feature name provided cannot continue');
    }
    return this.getFeatureFlagsAsync(feature);
  }

  public isSentryLoggingEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('enable_sentry_logging')
  }

  public isRMMVoyagerEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('rmm_voyager')
  }

  public isSiebelEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('siebel')
  }

  public isColumnFilteringEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('column_filtering');
  }

  public isApplicationCardEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('application_card');
  }

  public isAsAPhraseEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('as_a_phrase');
  }

  public isContainsSearchEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('contains_search');
  }

  public isDisplayCancelledRegistrationNumberEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('display_cancelled_registration_number');
  }

  public isDisplayNameDirectoryHyperlinksEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('display_name_directory_hyperlinks');
  }

  public isDisplayPdfCertificateEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('display_pdf_certificate');
  }

  public isDisplayPreRegisteredAsNumberEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('display_pre_registered_as_number');
  }

  public isDisplayPreviousRegistrationnumberEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('display_previous_registration_number');
  }

  public isDisplayReRegistrationnumberEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('display_re_registration_number');
  }

  public isDisplayRenewalRegistrationNumberEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('display_renewal_registration_number');
  }

  public isDisplaySupplemtedByRegNumbersEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('display_supplemented_by_reg_numbers');
  }

  public isDisplaySupplementedToRegNumbersEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('display_supplemented_to_reg_numbers');
  }

  public isLoginEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('login');
  }

  public isNameDirectoryEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('name_directory');
  }

  public isRedirectAllToSystemDownNoticeEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('redirect_all_to_system_down_notice');
  }

  public isSavedRecordSearchEnabled(): Observable<boolean> {
    if (this.envService.production && this.envService.internal) {
      return of(true);
    } else {
      return this.getFeatureFlagsAsync('save_record_search');
    }
  }

  public isSearchAlgorithmEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('search_algorithm');
  }

  public isSearchFieldTypeEnabled(): Observable<boolean> {
    return this.getFeatureFlagsAsync('search_field_type');
  }

  private lookupValue(feature: string): boolean {
    if (!this.features) {
      throw new Error('Feature flags failed to initialize');
    }
    const value = this.features[feature];
    if (value === undefined) {
      throw new Error('Feature flag does not exist cannot continue' + feature);
    }
    if (!(value === true || value === false)) {
      throw new Error('Feature flag must be boolean cannot continue');
    }
    return value;
  }
}

export class FeatureFlagResponse extends Deserializable {
  // Fix after merge.
  // eslint-disable-next-line no-use-before-define
  @deserializableProperty feature_flags: FeatureFlag[] | undefined;
  @deserializableProperty metadata: {} | undefined;
  @deserializableProperty quick_flags: { [id: string]: boolean } | undefined;
}

export class AuthenticatedFeatureFlagResponse extends Deserializable {
  @deserializableProperty flags: FeatureFlagResponse | undefined;
  @deserializableProperty metadata: {} | undefined;
}

export class FeatureFlag extends Deserializable {
  @deserializableProperty enabled: boolean | undefined;
  @deserializableProperty name: string | undefined;
}
