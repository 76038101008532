/**
 * Deserializable allows you to instantiate your class using any object.
 * It will copy over any properties with matching names.
 * Be sure to mark any properties you want to be deserialized with the @deserializableProperty decorator.
 */
export abstract class Deserializable {
  constructor(json: any) {
    // All property initializations will overwrite these values
    // https://stackoverflow.com/questions/67271632/typescript-field-default-overwrites-value-set-while-executing-super-constructor
    if (json && this['deserializablePropertyArray']) {
      this['deserializablePropertyArray'].forEach((p: any) => {
        if (json[p] !== undefined) {
          this[p] = json[p];
        }
      });
    }
  }
}

/**
 * In a class that extends Deserializable,
 * properties marked as @deserializableProperty
 * will be deserialized when a new object is instantiated.
 */
export function deserializableProperty(target: Object, propertyKey: string | symbol) {
  if (
    target['deserializablePropertyArray'] === undefined ||
    target['deserializablePropertyArray'] === null ||
    target['deserializablePropertyArray'] === 0 ||
    target['deserializablePropertyArray'] === ''
  ) {
    target['deserializablePropertyArray'] = [];
  }
  target['deserializablePropertyArray'].push(propertyKey.toString());
}
