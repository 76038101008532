<cprs-table-grid
  *appLoading="'saved_records'"
  #tableGrid
  [interface]="table"
  [mode]="mode"
  [selectionKey]="'saved_records'"
  [content]="data"
  (pageChange)="pageChange($event)"
  [totalElements]="totalElements"
>
  <div header-left>
    <cd-button-menu [btnClass]="'me-2'" title="Actions" [placement]="'bottom-start'" #actionsMenuButton>
      <li (cdMenuTextButton)="email()">Email</li>
      <li (cdMenuTextButton)="download()">Download</li>
      <li (cdMenuTextButton)="clear()">Clear record(s)</li>
    </cd-button-menu>

    <cd-button-menu [btnClass]="'me-2'" [title]="sortByLabel" [placement]="'bottom-start'" #sortByMenuButton>
      <li *ngFor="let option of sort_field_options" (cdMenuTextButton)="sortBy(option)">
        {{ option.text }}
      </li>
    </cd-button-menu>
  </div>

  <div sideColumnHeader>Saved on</div>
  <ng-template let-data #sideColumn>
    {{ data.timestamp.value | date : 'YYYY-MM-dd hh:mm a' }}
  </ng-template>

  <div no-content-grid>Once you have saved a record, your saved record(s) will be available here.</div>
</cprs-table-grid>

<div class="my-4">* This page is limited to 100 saved records.</div>
