import { DisclaimerConfig } from '@cop/disclaimer';

function checkInternalEnv(): boolean {
    if (window && window['__env']) {
        return window['__env']['internal'];
    }
    return true; // for  Unit test checking
}

export const appDisclaimerConfig: DisclaimerConfig = {

    content: {
        toUsersText: 'Unauthorized access, use, or modification of this U.S. Government computer system or of the data maintained in, or in transit to or from this system constitutes a violation of 18 U.S.C. 1030. This system is monitored to ensure proper performance of applicable security features and procedures. Such monitoring may result in the acquisition, recording, and analysis of data being communicated, transmitted, processed, or stored in this system by a user. In particular, we monitor the identity of all who access this system, as well as the date and time of their access. Unauthorized or improper use of this system may result in administrative action, as well as civil and criminal penalties.',
        privacyActText: 'The Copyright Office collects personally identifiable information (PII) if you voluntarily create a Copyright Public Records System (CPRS) user account. PII is any personal information that can be used to identify, contact, or trace an individual, such as names, addresses, telephone numbers, and email addresses. By providing this information you are agreeing to the routine use of it to maintain your user account and provide updates. The PII that you submit when creating a user account is not required to use the website. If you do not provide the information requested, you will not be able to utilize certain advanced website features.',
        showLoginImage: checkInternalEnv(), // must be true for external
        loginText: '',
    }
};
