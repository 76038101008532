// import { Deserializable, deserializableProperty } from '@cop/copyright-design-system';
import { Describer } from './describer.class';
import { Deserializable, deserializableProperty } from '@src/app/cprs/shared/interfaces/deserializable';
import { GetUserSavedRecords } from '../../modules/saved/services/user.service';

export interface ElasticSearchHighlight {
  [key: string]: string[] | string;
  label: string;
  value: any;
  value_to_find: string;
}

export interface ElasticSearchRecord {
  [key: string]: any;
}

export class ElasticSearchResult extends Describer {
  @deserializableProperty score!: number;
  @deserializableProperty highlights!: ElasticSearchHighlight;
  @deserializableProperty hit!: ElasticSearchRecord;
  @deserializableProperty saved?: GetUserSavedRecords;

  constructor(json: any) {
    super(json);

    if (json.highlights) {
      this.highlights = json.highlights as ElasticSearchHighlight;
      const simple = this.getSimpleRecord(this);

      if (simple) {
        this.highlights = this.highlightService.getFirstHighlight(
          this.getSimpleRecord(this),
          this.highlights
        );
      }
    }

    if (json.hit) {
      this.hit = json.hit as ElasticSearchRecord;
    }
  }
}

export class ElasticSearchQuery extends Deserializable {
  @deserializableProperty column_name!: string;
  @deserializableProperty query!: string;
  @deserializableProperty type_of_query!: string;
}

export interface ElasticSearchHistogramType {
  system_of_origin: {
    voyager: number;
    rmm_voyager: number;
    card_catalog: number;
  };
  type_of_record: {
    registration: number;
    recordation: number;
  };
  type_of_work: {
    text: string;
    music: string;
    visual_material: string;
    serial: string;
    motion_picture: string;
    sound_recording_music: string;
    dramatic_work_choreography: string;
    sound_recording: string;
    computer_file: string;
    map: string;
    cancelled_registration: string;
    sound_recording_text: string;
    kit: string;
    mask_work: string;
    preregistration: string;
    unspecified: string;
  };
  registration_status: {
    published: number;
    unpublished: number;
  };
  registration_class: {
    TX: number;
    PA: number;
    VA: number;
    SR: number;
    RE: number;
    book: number;
    newspaper_or_periodical: number;
    contribution_to_newspaper_or_periodical: number;
    MW: number;
    print_or_pictorial_illustration: number;
    dramatic_composition: number;
    PRE: number;
    photograph: number;
    map: number;
    work_of_art: number;
    unspecified: number;
    PR: number;
    drawing_or_plastic_work_of_scientific_or_technical_character: number;
    lecture_sermon_or_address: number;
    motion_picture: number;
    musical_composition: number;
    photoplay: number;
    print_or_label_used_for_an_article_of_merchandise: number;
    reproduction_of_a_work_of_art: number;
    chromo_or_lithograph: number;
    engraving_cut_or_print: number;
    drama: number;
    other_item_type: number;
    map_or_chart: number;
    periodical: number;
    book_or_drama: number;
    music: number;
  };
  type_of_acquisition: {};
  recordation_item_type: {
    unspecified: number;
    security_interests: number;
    mortgage_security_agreement: number;
    assignment: number;
    other: number;
    notice_of_termination: number;
    notice_of_termination_203: number;
    gatt_notices_of_intent_to_enforce_a_copyright: number;
    notice_of_termination_304_c: number;
    court_action: number;
    change_of_name: number;
    affidavit_declaration_certification: number;
    change_of_address: number;
    dmca_filing: number;
    exclusive_license: number;
    court_actions_other_than_clerk: number;
    s_508: number;
    nafta_statements_of_interest: number;
    declarations_about_the_author: number;
    notice_of_termination_304_d: number;
    counternotice: number;
    non_exclusive_license: number;
    court_order: number;
    mask_works_documents: number;
    will: number;
    s_302: number;
  };
}

export class ElasticSearchHistogram extends Deserializable {
  @deserializableProperty filtered!: ElasticSearchHistogramType;
  @deserializableProperty unfiltered!: ElasticSearchHistogramType;
}

export class ElasticSearchMetadata extends Deserializable {
  @deserializableProperty took_ms!: number;
  @deserializableProperty hit_count!: number;
  @deserializableProperty hit_count_relation!: string;
  @deserializableProperty max_score!: number;
  @deserializableProperty histogram!: ElasticSearchHistogram;
  @deserializableProperty user_class!: string;
  @deserializableProperty executed!: string;
  @deserializableProperty query!: string;
  @deserializableProperty normalized_query!: string;
  @deserializableProperty cache!: boolean;

  constructor(json: any) {
    super(json);

    if (json.histogram) {
      this.histogram = new ElasticSearchHistogram(json.histogram);
    }
  }
}

export class ElasticSearchResponse extends Deserializable {
  metadata!: ElasticSearchMetadata;
  data!: ElasticSearchResult[];

  constructor(json: any) {
    super(json);

    if (json.metadata) {
      this.metadata = new ElasticSearchMetadata(json.metadata);
    }

    if (json.data && json.data instanceof Array) {
      this.data = json.data.map((record: any) => new ElasticSearchResult(record));
    }
  }
}
