import { Component, EventEmitter, Output } from '@angular/core';
import { CprsRecordTypes } from '../../services/detailed-record.service';
import { VoyagerRecordTypes } from '../../schemas/voyager/voyager.class';

@Component({
  selector: 'cprs-maximal-record',
  templateUrl: './cprs-maximal-record.component.html',
  styleUrls: ['./cprs-maximal-record.component.scss']
})
export class CprsMaximalRecordComponent {
  public selectedSystemOfOrigin: CprsRecordTypes = CprsRecordTypes.voyager;

  public selectedVoyagerType: VoyagerRecordTypes = VoyagerRecordTypes.registration;

  public selectedInternalExternal: 'internal' | 'external' = 'internal';

  @Output() public loadSchema = new EventEmitter<{key: string, value: string}>();

  loadSystemOfOrigin(system_of_origin: string = '') {
    this.selectedSystemOfOrigin = CprsRecordTypes[system_of_origin];
    this.loadSchema.emit({ key: 'system_of_origin', value: system_of_origin });
  }

  loadTypeOfRecord(type_of_record: string = '') {
    this.selectedVoyagerType = VoyagerRecordTypes[type_of_record];
    this.loadSchema.emit({ key: 'type_of_record', value: type_of_record });
  }

  loadInternalExternal(internal_external: string = '') {
    this.selectedInternalExternal = internal_external as 'internal' | 'external';
    this.loadSchema.emit({ key: 'internal_external', value: internal_external });
  }
}
