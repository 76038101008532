<ul>
  <ng-container *ngFor="let property of this.schema">
    <li *ngIf="this.isVisible(property)" class="cd-record d-flex p-2">
      <ng-container *ngIf="!this.isTable(property)">
        <ng-container *ngIf="this.hasTag(property, 'html')">
          <ng-container *ngIf="this.isArray(property) && this.isNotEmpty(property)">
            <div class="text-break">
              <strong> {{ property.label }} <span *ngIf="isInternal(property)">*</span> </strong>
            </div>
            <div>
              <ng-container *ngFor="let value of property.value">
                <div [innerHtml]="value" style="white-space: pre-line"></div>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="!this.isArray(property)">
            <div class="text-break">
              <strong>{{ property.label }} <span *ngIf="isInternal(property)">*</span> </strong>
            </div>
            <div [innerHtml]="property.value" style="white-space: pre-line"></div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!this.hasTag(property, 'html')">
          <ng-container *ngIf="this.isArray(property) && this.isNotEmpty(property)">
            <div class="text-break">
              <strong> {{ property.label }} <span *ngIf="isInternal(property)">*</span> </strong>
            </div>
            <ul class="cd-property-list">
              <li *ngFor="let value of property.value">
                <ng-container *ngIf="this.isLinkValue(value)">
                  <div>
                    <a [href]="value.url">{{ value.value }}</a>
                  </div>
                </ng-container>

                <ng-container *ngIf="!this.isLinkValue(value)">
                  <div>{{ value }}</div>
                </ng-container>
              </li>
            </ul>
          </ng-container>

          <ng-container *ngIf="!this.isArray(property)">
            <ng-container *ngIf="this.isLink(property)">
              <div class="text-break">
                <strong> {{ property.label }} <span *ngIf="isInternal(property)">*</span> </strong>
              </div>
              <div>
                <a [href]="property.value.url">{{ property.value.value }}</a>
              </div>
            </ng-container>

            <ng-container *ngIf="!this.isObject(property)">
              <div class="text-break">
                <strong>{{ property.label }} <span *ngIf="isInternal(property)">*</span> </strong>
              </div>
              <div>{{ property.value }}</div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <div *ngIf="this.isTable(property) && property.table && property.value.length > 0">
        <div class="text-break">
          <strong>{{ property.label }} <span *ngIf="isInternal(property)">*</span> </strong>
        </div>
        <div>
          <app-data-table [columns]="property.table.getDataColumns()" [data]="property.value"></app-data-table>
        </div>
      </div>
    </li>
  </ng-container>
</ul>
