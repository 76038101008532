<div id="cd-openseadragon-container" >

    <div *ngIf="this.cardImages" class="open-seadragon-toolbar">
      <div class="toolbar-group" *ngIf="showImagePager">
        <div class="toolbar-select">
          <label>Image</label>
  
          <form [formGroup]="cardImages">
            <cd-select class="d-flex pe-2" formControlName="images" [options]="$any(availableImages)"></cd-select>
          </form>
          <cd-button [mode]="'primary'" [size]="'large'" (btnClick)="goto()">
            Go
          </cd-button>
        </div>
      </div>
  
      <div class="toolbar-group" *ngIf="showImageViewer">
        <div class="toolbar-select">
          <label>View</label>
          <form [formGroup]="cardImages">
            <cd-select class="d-flex pe-2" formControlName="viewTypes" [options]="viewTypes"></cd-select>
          </form>
          <cd-button [mode]="'primary'" [size]="'large'" (btnClick)="changeView()"> Go </cd-button>
        </div>
      </div>
    </div>
  
    <div class="openseadragon-image-controls">
      <cd-button class="me-2" (click)="home()" [mode]="'secondary'" [size]="'small'" [ariaLabel]="'Center'">
        <cd-icon [cdsIcon]="'crosshairs'"></cd-icon>
      </cd-button>
      <cd-button class="me-2" (click)="zoomOut()" [mode]="'secondary'" [size]="'small'" [ariaLabel]="'Zoom out'">
        <cd-icon [cdsIcon]="'minus'"></cd-icon>
      </cd-button>
      <cd-button class="me-2"  (click)="zoomIn()" [mode]="'secondary'" [size]="'small'" [ariaLabel]="'Zoom in'">
        <cd-icon [cdsIcon]="'plus'"></cd-icon>
      </cd-button>
      <cd-button class="me-2" (click)="rotate()" [mode]="'secondary'" [size]="'small'" [ariaLabel]="'Rotate'">
        <cd-icon [cdsIcon]="'redo-alt'"></cd-icon>
      </cd-button>
      <cd-button class="me-2" (click)="fullscreen()" [mode]="'secondary'" [size]="'small'" [ariaLabel]="'Expand'">
        <cd-icon [cdsIcon]="'expand-alt'"></cd-icon>
      </cd-button>
    </div>
  
    <cd-button *ngIf="isSingleImageView" [btnId]="'previousBtn'" [mode]="'secondary'" [size]="'large'" (btnClick)="prev()" [ariaLabel]="'Previous'">
      <cd-icon [cdsIcon]="'angle-left'"></cd-icon>
    </cd-button>
  
    
    <div id="openseadragon-holder" style="height: 600px"></div>
  
    <cd-button *ngIf="isSingleImageView" [btnId]="'nextBtn'" [mode]="'secondary'" [size]="'large'" (btnClick)="next()" [ariaLabel]="'Next'">
      <cd-icon [cdsIcon]="'angle-right'"></cd-icon>
    </cd-button>
  
    <div class="open-seadragon-toolbar">
      <cd-button
        [disabled]="!isSingleImageView"
        [mode]="'secondary'"
        [size]="'large'"
        *ngIf="showDownload"
        (btnClick)="this.downloadImage()"
      >
        Download <cd-icon [cdsIcon]="'cloud-download-alt'"></cd-icon>
      </cd-button>
    </div>
  </div>
  
  