import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdRecordComponent } from './component/cd-record/cd-record.component';
import { TableModule } from '../table/table.module';
import { SharedModule } from '@src/app/shared/shared.module';
import { DesignComponentsModule } from '@cop/design/components';
import { CdOpenseadragonComponent } from './component/cd-openseadragon/cd-openseadragon.component';
import { DesignFormsModule } from '@cop/design/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CprsMaximalRecordComponent } from './component/cprs-maximal-record/cprs-maximal-record.component';
import { CprsRecordNavigationTextComponent } from './component/cprs-record-navigation-text/cprs-record-navigation-text.component';
import { CprsRecordNavigationComponent } from './component/cprs-record-navigation/cprs-record-navigation.component';

@NgModule({
  declarations: [
    CdRecordComponent,
    CdOpenseadragonComponent,
    CprsMaximalRecordComponent,
    CprsRecordNavigationTextComponent,
    CprsRecordNavigationComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    DesignComponentsModule,
    DesignFormsModule,
  ],
  exports: [
    CdRecordComponent,
    CdOpenseadragonComponent,
    CprsMaximalRecordComponent,
    CprsRecordNavigationTextComponent,
    CprsRecordNavigationComponent
  ]
})
export class DetailedRecordModule { }
