import { Component, OnInit } from '@angular/core';
import { CdSearchService } from '../../services/search.service';
import { ActivatedRoute } from '@angular/router';
import { TransformerService } from '../../modules/detailed-record/services/transformer.service';
import { RecordProperty } from '../../modules/detailed-record/component/cd-record/cd-record.component';
import { TabComponent } from '@cop/design/components';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { Title } from '@angular/platform-browser';
import { CdSelectionService } from '../../modules/selection/services/selection.service';
// import { FormControl, FormGroup } from '@angular/forms';
// import { map, switchMap } from 'rxjs/operators';
import { CprsRecordTypes, DetailedRecord, DetailedRecordService } from '../../modules/detailed-record/services/detailed-record.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { StateService } from '../../state/state.service';
import { CprsSearch, CprsService } from '../../services/cprs.service';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';
import { RecentFeatureService } from '../../modules/recent/services/recent.service';
import { concat } from 'rxjs';

/*
  okay we need to only have 1 page per schema
  but maybe we should keep this page simple
  and have a service for all this
*/
@Component({
  selector: 'app-detailed-record-page',
  templateUrl: './detailed-record-page.component.html',
  styleUrls: ['./detailed-record-page.component.scss'],
})
export class DetailedRecordPageComponent implements OnInit {
  public schema: RecordProperty[];

  public control_number: any;

  public current_search: CprsSearch;

  public detailed_record: DetailedRecord;

  public tab: TabComponent;

  public paginationDetails: any = {
    currentPage: 0,
    totalPages: 0
  };

  public selectedInternalExternal: 'internal' | 'external' = 'internal';

  constructor(
    public route: ActivatedRoute,
    public breadcrumbService: BreadcrumbService,
    public titleService: Title,
    public envService: EnvService,
    public cdSearchService: CdSearchService,
    public transformerService: TransformerService,
    public selectionService: CdSelectionService,
    public detailedRecordService: DetailedRecordService,
    public cprsService: CprsService,
    public stateService: StateService,
    public recentService: RecentFeatureService,
  ) {}

  ngOnInit(): void {
    // set title
    this.titleService.setTitle(
      'Detailed Record View | U.S. Copyright Public Records System'
    );

    // set breadcrumbs
    this.breadcrumbService.breadcrumbs = [{ name: 'Home', link: '/', linkType: 'routerLink' }];
    this.breadcrumbService.currentBreadcrumbTextOnly = 'Detailed Record View';

    concat(this.cprsService.getCurrentSearch(), this.cprsService.onFacet).subscribe(search => {
      if (search) {
        const viewMode = this.stateService._state.get('mode')?.value;
        const search_breadcrumb = search.type === 'simple' ? { name: 'Search', link: search.url, linkType: 'href' } : (search.type === 'advanced' ? { name: 'Advanced Search', link: search.url, linkType: 'href' } : null);

        if (viewMode && search_breadcrumb && search_breadcrumb.link) {
          search_breadcrumb.link = `${search_breadcrumb.link}&mode="${viewMode}"`;
        }

        this.breadcrumbService.breadcrumbs = [
          { name: 'Home', link: '/', linkType: 'routerLink' },
          search_breadcrumb,
        ];
        this.breadcrumbService.currentBreadcrumbTextOnly = 'Detailed Record View';
      }
    });

    this.route.queryParamMap.subscribe(queryParams => {
      const associatedRecordId = queryParams.get('associatedRecords');

      if (associatedRecordId) {
        this.cprsService.associatedRecords(associatedRecordId).subscribe();

        this.breadcrumbService.breadcrumbs = [
          { name: 'Home', link: '/', linkType: 'routerLink' },
          { name: 'Name Directory', link: '/name-directory', linkType: 'routerLink' },
          { name: associatedRecordId, link: `/name-directory/${associatedRecordId}/associated-records`, linkType: 'routerLink' },
        ];
        this.breadcrumbService.currentBreadcrumbTextOnly = 'Detailed Record View';
      }
    })

    // watch for tab change
    this.stateService.internalExternalTabs.subscribe(internal => {
      if (internal) {
        this.selectedInternalExternal = 'internal';
      } else {
        this.selectedInternalExternal = 'external';
      }
    })

    // load record on query param change
    this.route.paramMap.subscribe(params => {
      this.control_number = params.get('id');

      if (this.control_number) {
        this.cprsService.getLoadingSubject('detailed_record').next(true);
        this.detailedRecordService.createDetailedRecord(this.control_number).then((drv) => {
          if (!drv) {
            return;
          }

          if (drv.record.isAcquisition()) {
            this.stateService.internalExternalTabs.next(true);
            this.selectedInternalExternal = 'internal';
          }

          this.detailed_record = drv;
          if (this.selectedInternalExternal === 'internal') {
            this.schema = drv.schema;
          } else {
            if (!drv.schema) {
              drv.schema = [];
            }

            this.schema = drv.schema.filter((prop: RecordProperty) => !prop.tags || (prop.tags && !prop.tags.includes('internal')));
          }

          this.cprsService.getLoadingSubject('detailed_record').next(false);
          this.recentService.createRecentRecord(drv.record);
          // reset the title
          this.titleService.setTitle(
            `${drv.title} Detailed Record View | U.S. Copyright Public Records System`
          );

          const group: UntypedFormGroup = this.selectionService.selection_groups.detailed_record;
          group.get('items')?.setValue([
            new UntypedFormGroup({
              index: new UntypedFormControl(0),
              selected: new UntypedFormControl(true),
              data: new UntypedFormControl(drv.record),
            }),
          ]);
        })
      }
    });
  }

  /*
    Loads a maximal record schema by record type / tag filtering
  */
  maximal($event: { key: string, value: string}) {
    if ($event.key === 'system_of_origin') {
      this.cdSearchService.getFullRecord($event.value as CprsRecordTypes).subscribe(record => {
        const properties = this.detailedRecordService.getSchema(record, $event.value as CprsRecordTypes);
        this.schema = this.transformerService.create(record, properties);
      });
    }

    if ($event.key === 'type_of_record') {
      this.cdSearchService.getFullRecord($event.value as CprsRecordTypes).subscribe(record => {
        this.schema = this.transformerService.createType(record, $event.value ?? 'registration');
      });
    }

    if ($event.key === 'internal_external' && this.detailed_record && this.detailed_record.record) {
      if ($event.value === 'internal') {
        const properties = this.detailedRecordService.getSchema(this.detailed_record.record, this.detailed_record.record.get('system_of_origin'));
        this.schema = this.transformerService.create(this.detailed_record.record, properties);
        this.cprsService.getLoadingSubject('detailed_record').next(false);
      } else {
        const properties = this.detailedRecordService.getSchema(this.detailed_record.record, this.detailed_record.record.get('system_of_origin'));
        this.schema = this.transformerService.create(this.detailed_record.record, properties).filter((prop: RecordProperty) => !prop.tags || (prop.tags && !prop.tags.includes('internal')));
        this.cprsService.getLoadingSubject('detailed_record').next(false);
      }
    }
  }

  loadInternalExternal(internal_external: string = '') {
    if (!this.detailed_record) {
      return;
    }
    const internalOrExternal: boolean = internal_external === 'internal';
    this.stateService.internalExternalTabs.next(internalOrExternal);
    if (!this.detailed_record) {
      return;
    }

    if (this.selectedInternalExternal === 'internal') {
      const properties = this.detailedRecordService.getSchema(this.detailed_record.record, this.detailed_record.record.get('system_of_origin'));
      this.schema = this.transformerService.create(this.detailed_record.record, properties);
      this.cprsService.getLoadingSubject('detailed_record').next(false);
      this.selectedInternalExternal = 'internal';
    } else {
      const properties = this.detailedRecordService.getSchema(this.detailed_record.record, this.detailed_record.record.get('system_of_origin'));
      this.schema = this.transformerService.create(this.detailed_record.record, properties).filter((prop: RecordProperty) => !prop.tags || (prop.tags && !prop.tags.includes('internal')));
      this.cprsService.getLoadingSubject('detailed_record').next(false);
      this.selectedInternalExternal = 'external';
    }
  }

  get isAcquisition() {
    return this.detailed_record && this.detailed_record.record.isAcquisition();
  }

  get staff_view() {
    return this.envService.internal;
  }
}
