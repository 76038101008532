<cprs-base-page>
  <div id="unsubscribe-page" class="row d-flex align-items-center justify-content-center">
    <div class="unsubscribe-confirmation d-flex" style="justify-content: center">
      <ng-container *ngIf="this.state === 'unsubscribe' || (this.state === 'resubscribe' && this.email)">
        <!-- <cd-icon class="fa-xl" [icon]="faCheckCircle"></cd-icon> -->
        <cd-icon [classes]="'p-1 fs-3 text-success'" [cdsIcon]="'check-circle'"></cd-icon>
      </ng-container>
      <ng-container *ngIf="this.state === 'resubscribe' && !this.email">
        <!-- <cd-icon class="fa-xl red" [icon]="faExclamationTriangle"></cd-icon> -->
        <cd-icon [classes]="'p-1 fs-3 text-danger'" [cdsIcon]="'exclamation-triangle'"></cd-icon>
      </ng-container>
      <div class="display-3">
        <span *ngIf="this.state === 'unsubscribe'"> Unsubscribe confirmation </span>
        <span *ngIf="this.state === 'resubscribe'"> Resubscribe confirmation </span>
      </div>
    </div>

    <div class="unsubscribe-confirmation-message d-flex mt-4 mb-8" style="justify-content: center">
      <ng-container *ngIf="this.state === 'unsubscribe'">
        <span *ngIf="this.email">{{ this.email }} has successfully unsubscribed from receiving shared results.</span>
        <span *ngIf="!this.email">You have successfully unsubscribed from receiving shared results.</span>
      </ng-container>
      <ng-container *ngIf="this.state === 'resubscribe'">
        <span *ngIf="this.email">{{ this.email }} has successfully resubscribed to receiving shared results.</span>
        <span *ngIf="!this.email">There was an issue resubscribing, please try again later.</span>
      </ng-container>
    </div>
  </div>
</cprs-base-page>
