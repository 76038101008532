export type RegistrationNumber = {
    registration_number: string;
    registration_date: string;
    copyright_number_display_text: string;
    copyright_number_source: string;
    registration_number_cancelled: string;
    preregistration_number: string;
    preregistration_date: string
}

export type HyperlinkSchema = {
    ids: string[];
    tokens: string[];
    source?: string;
}

export type PreviousRegistrationNumber = {
    previous_registration_statement: string;
    previous_registration_number_hyperlinks: HyperlinkSchema;
    previous_registration_statement_registration_hyperlinks: HyperlinkSchema;
}

export enum RegistrationTypes {
    registration_number = 'registration_number',
    supplemented_by = 'supplemented_by',
    supplement_to = 'supplement_to',
    renewal_registration = 'renewal_registration',
    reregistration = 'reregistration',
    cancelled_registration = 'cancelled_registration',
    preregistration = 'preregistration',
}

export type RegistrationList = {
    [RegistrationTypes.registration_number]?: RegistrationNumber;
    [RegistrationTypes.supplemented_by]?: RegistrationNumber;
    [RegistrationTypes.supplement_to]?: RegistrationNumber;
    [RegistrationTypes.renewal_registration]?: RegistrationNumber;
    [RegistrationTypes.reregistration]?: RegistrationNumber;
    [RegistrationTypes.cancelled_registration]?: RegistrationNumber;
    [RegistrationTypes.preregistration]?: RegistrationNumber;
}

export type VoyagerEntity = {
    name: string;
    names: string[];
    address: string[];
    roles: string[];
    undifferentiated_roles: string[];
    dates: string;
    alternate_names: string[];
    owner_statement_gatt_document: string;
    author_statement_gatt_document: string[];
}

export type DisplayNames = {
    persons: VoyagerEntity[];
    organizations: VoyagerEntity[];
}
