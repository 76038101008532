import { VoyagerProperty } from '../../../services/transformer.service';
import { VoyagerHelper } from '../voyager.helper';

export class VoyagerDescription extends VoyagerHelper {
    get description(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Description', [
            'physical_description_accompanying_material',
            'physical_description_extent',
            'physical_description_details',
            'physical_description_dimensions'
        ])
    }

    // DOES NOT EXIST
    get description_based_on(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Description based on', 'description_based_on');
    }

    get description_based_on_list(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Description based on', 'description_based_on_list');
    }

    get imprints(): VoyagerProperty {
        return this.createVoyagerProperty('Imprint', 'imprint_list', [
            'imprint_place_of_publication',
            'imprint_publisher_name',
            'imprint_date_of_publication'
        ]);
    }

    get publisher_name(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Publisher Name', 'publisher_name');
    }

    get publisher_number(): VoyagerProperty {
        return this.createVoyagerProperty('Publisher Label Number', 'publisher_number_list', [
            'label_name',
            'label_number'
        ]);
    }

    get frequency_of_publication(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Frequency', 'frequency_of_publication');
    }

    get edition(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Edition', ['edition_statement', 'edition_statement_remainder']);
    }

    get scale_for_graphic_materials(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Scale', 'scale_for_graphic_materials');
    }

    get description_of_preregistered_work(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Description of Work', 'description_of_preregistered_work');
    }

    get language_note(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Language', 'language_note');
    }

    get copyright_count_category(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Copyright Count Category', 'copyright_count_category');
    }

    // DOES NOT EXIST
    get deposit_format(): VoyagerProperty {
        return this.createVoyagerProperty('Deposit Format', 'deposits_list', ['deposit_format'], ['internal']);
    }

    get retrieval_code(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Retrieval Code', 'retrieval_code', ['internal']);
    }

    get copies_retained(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Copies Retained', 'copies_retained');
    }

    get copies_deposited(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Copies Deposited', 'copies_deposited');
    }

    get copies_date_of_receipt(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Copies Date of Receipt', 'copies_date_of_receipt');
    }
}
