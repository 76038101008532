import { VoyagerProperty } from '../../../services/transformer.service';
import { Voyager } from '../voyager.class';

export class VoyagerRegistration extends Voyager {
    public schema: VoyagerProperty[] = [
        // copyright data
        this.copyright_data.registration_number,
        this.copyright_data.cancelled_registration_number,
        this.copyright_data.reregistered_as,
        this.copyright_data.renewal_registration,
        this.copyright_data.supplemented_by,
        this.copyright_data.supplement_to,
        this.copyright_data.supplement_to_registration,
        this.copyright_data.previous_registration,
        this.copyright_data.type_of_work_preregistered,
        this.copyright_data.preregistration_number_date,
        this.copyright_data.preregistered_as,
        this.copyright_data.service_request_number,
        this.copyright_data.preregistration_date_of_commencement_of_creation,
        this.copyright_data.preregistration_date_of_anticipated_completion,
        this.copyright_data.registration_class,
        this.copyright_data.type_of_work,
        this.copyright_data.certificate,
        this.copyright_data.publisher_label_number,

        // titles
        this.titles.title,
        this.titles.title_application_title,
        this.titles.title_alternate_title_from_application,
        this.titles.series,
        this.titles.contents,
        this.titles.photographs,
        this.titles.title_qualifier,
        this.titles.running_title,
        this.titles.alternative_titles,
        this.titles.other_titles,
        this.titles.other_titles_two,
        this.titles.variant_title,
        this.titles.appears_in,
        this.titles.title_of_works,
        this.titles.contributions,
        this.titles.former_title,
        this.titles.title_cover_title,
        this.titles.title_distinctive_title,
        this.titles.title_spine_title,
        this.titles.portion_of_title,
        this.titles.title_translated,
        this.titles.list_of_works,

        // dates
        this.dates.creation_date_as_year,
        this.dates.date_creation_date,
        this.dates.date_of_first_publication,
        this.dates.preregistration_date_of_anticipated_completion,
        this.dates.date_first_exploitation_date,
        this.dates.in_notice_date,
        this.dates.representative_date_for_deposit,
        this.dates.deposit_date_offered_to_lc,
        this.dates.date_record_entered_on_file_as_date,
        this.dates.deposit_receipt_date,
        this.dates.first_exploitation_date_coded,
        this.dates.latest_transaction_date_and_time,

        // parties
        this.parties.claimants_list,
        this.parties.authorship_on_application,
        this.parties.owner,
        this.parties.performer,
        this.parties.cast,
        this.parties.credits_note,
        this.parties.in_notice_name,

        // claim description
        this.claim_description.basis_of_claim,
        this.claim_description.rights_and_permissions,
        this.claim_description.preexisting_material,
        this.claim_description.amplifications_explained,
        this.claim_description.corrections_explained,
        this.claim_description.reproduction_rights_code,
        this.claim_description.publication_status,
        this.claim_description.place_of_publication_code,

        // standard identifiers
        this.standard_identifiers.isbn,
        this.standard_identifiers.issn_list,
        this.standard_identifiers.isrc,
        this.standard_identifiers.ismn,
        this.standard_identifiers.upc,
        this.standard_identifiers.ean,
        this.standard_identifiers.ipn_number,
        this.standard_identifiers.record_id,
        this.standard_identifiers.system_control_number,
        this.standard_identifiers.control_number_identifier,
        this.standard_identifiers.originating_control_number,

        // description
        this.description.description,
        this.description.description_based_on,

        this.description.imprints,
        this.description.publisher_number,
        this.description.edition,
        this.description.scale_for_graphic_materials,
        this.description.description_of_preregistered_work,
        this.description.language_note,
        this.description.copyright_count_category,
        // this.description.deposit_format,
        this.description.retrieval_code,
        this.description.frequency_of_publication,

        // notes
        this.notes.copyright_note,
        this.notes.copyright_note_to_staff,
        this.notes.cancellation_note,
        this.notes.notes,
        this.notes.area_covered,
        this.notes.date_of_first_publication_album,
        this.notes.publication_nation,
        this.notes.catalog_of_copyright_entries_part_codes,
        this.notes.monograph_type,
        this.notes.selection_notes,
        this.notes.record_created_by,

        //
        this.description.description_based_on_list,

        // serial information
        this.serial_information.serial_key_title,
        this.serial_information.serial_publication_year,
        this.serial_information.publication_date_range,
        this.serial_information.publication_history,
        this.serial_information.former_frequency,
        this.serial_information.multiple_claimant_note,
        this.serial_information.issues_registered,
        // this.serial_information.issues_registered_staff,
        this.serial_information.continues,
        this.serial_information.continued_by,
        this.serial_information.continues_in_part,
        this.serial_information.merged_with,
        this.serial_information.absorbed,
        this.serial_information.absorbed_by,
        this.serial_information.absorbed_in_part,
        this.serial_information.supersedes,
        this.serial_information.superseded_by,
        this.serial_information.split_into,
        this.serial_information.formed_by_the_union_of,
        this.serial_information.serial_preceding_issn,
        this.serial_information.serial_succeeding_issn,
        this.serial_information.serial_description_based_on_note,

        // administrative
        this.administrative.service_request_row_identifier,
        this.administrative.record_cataloging_agency,
        this.administrative.record_transcribing_agency,
        this.administrative.record_origin_code,

        // deposits
        this.deposit_information.deposit_information,

        // names
        this.names.names,
        this.names.name_undifferentiated_role
    ]
}
