import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CprsSearch, CprsService } from '../../services/cprs.service';
import { ParametersService } from '../../services/parameters.service';
import { StateService } from '../../state/state.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { Subscription, concat } from 'rxjs';

@Component({
  selector: 'cprs-simple-search',
  templateUrl: './simple-search.component.html',
  //   styleUrls: ['./simple-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SimpleSearchComponent implements OnInit, OnDestroy {
  public search: CprsSearch | null;

  public searchLoading: boolean;

  public breadcrumbSubscription: Subscription;
  public loadingSubscription: Subscription;

  constructor(
    public cprsService: CprsService,
    public breadcrumbService: BreadcrumbService,
    public stateService: StateService,
    public parameterService: ParametersService
  ) {}

  ngOnInit() {
    this.parameterService.deserializeParamsToState();

    this.loadingSubscription = this.cprsService.loading.subscribe((loading) => {
      this.searchLoading = loading;

      if (loading) {
        this.breadcrumbService.breadcrumbs = [{ name: 'Home', link: '/', linkType: 'routerLink' }];
        this.breadcrumbService.currentBreadcrumbTextOnly = 'Search';
      }
    });

    if (!this.search && !!this.stateService.search_form.get('query')?.value) {
      this.cprsService.search();
    }

    this.breadcrumbService.breadcrumbs = [{ name: 'Home', link: '/', linkType: 'routerLink' }];
    this.breadcrumbService.currentBreadcrumbTextOnly = 'Search';

    concat(this.cprsService.getCurrentSearch(), this.cprsService.onFacet).subscribe(search => {
      if (search) {
        this.search = search;

        this.breadcrumbService.breadcrumbs = [
          { name: 'Home', link: '/', linkType: 'routerLink' },
          { name: 'Search', link: this.search.url, linkType: 'href' },
        ];
        if (this.search.parameters.query) {
          this.breadcrumbService.currentBreadcrumbTextOnly = `Search results for ${this.search.parameters.query}`;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}
