<ng-container>
    <cd-tabs (tabClick)="loadSystemOfOrigin($event.id)">
      <cd-tab [id]="'voyager'" [tabTitle]="'Voyager'">
      </cd-tab>
      <cd-tab [id]="'card_catalog'" [tabTitle]="'Card Catalog'">
      </cd-tab>
    </cd-tabs>  
</ng-container>

<ng-container *ngIf="selectedSystemOfOrigin === 'voyager'">
    <cd-tabs (tabClick)="loadTypeOfRecord($event.id)">
        <cd-tab [id]="'registration'" [tabTitle]="'Registration'">
        </cd-tab>
        <cd-tab [id]="'recordation'" [tabTitle]="'Recordation'">
        </cd-tab>
        <cd-tab [id]="'acquisition'" [tabTitle]="'Acquisition'">
        </cd-tab>
    </cd-tabs>  
</ng-container>

<div>
    <cd-tabs (tabClick)="loadInternalExternal($event.id)">
        <cd-tab [id]="'internal'" [tabTitle]="'Internal View'">
        </cd-tab>
        <cd-tab [id]="'external'" [tabTitle]="'External View'">
        </cd-tab>
    </cd-tabs>
</div>


