import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { VoyagerProperty } from '../../../services/transformer.service';
import { VoyagerHelper } from '../voyager.helper';

export class VoyagerTitles extends VoyagerHelper {
    get title(): VoyagerProperty {
        return {
            label: 'Title',
            value: (record: ElasticSearchResult) => {
                return record.getTitle();
            }
        }
    }

    get title_of_works(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Title of Works', 'title_of_works');
    }

    get title_application_title(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Application Title', 'title_application_title');
    }

    get title_alternate_title_from_application(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Alternative Title on Application', 'title_alternate_title_from_application');
    }

    get series(): VoyagerProperty {
        return {
            label: 'Series',
            value: (record: ElasticSearchResult) => {
                const propertyKeys = [
                    'series_statement_title',
                    'series_title',
                    'series_name_of_part',
                    'series_volume_number',
                    'series_number_of_part',
                    'series_statement_part_number',
                    'series_statement_part_name',
                    'series_statement_volume',
                    'series_statement_issn'
                ];
                return propertyKeys.map(key => record.get(key)).join('');
            }
        }
    }

    get series_list(): VoyagerProperty {
        return {
            label: 'Series',
            value: (record: ElasticSearchResult) => {
                const seriesList: any[] = record.get('series');

                const seriesProperties = [
                    'series_statement_title',
                    'series_title',
                    'series_name_of_part',
                    'series_volume_number',
                    'series_number_of_part',
                    'series_statement_part_number',
                    'series_statement_part_name',
                    'series_statement_volume',
                    'series_statement_issn'
                ]

                return seriesList.map(series => {
                    return seriesProperties.map(key => series[key]).join('');
                })
            }
        }
    }

    get contents(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Contents', 'title_contents_title');
    }

    // TODO bad data in these fields
    get photographs(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Photographs', 'title_photograph_title', ['html']);
    }

    get title_qualifier(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Title Qualifier', [
            'local_title_qualifying_information',
            'title_serial_title_qualifying_information_remainder'
        ]);
    }

    get running_title(): VoyagerProperty {
        return this.createVoyagerProperty('Running Title', 'title_running_title', [
            'title_running_title',
            'title_running_title_name_of_part',
        ]);
    }

    get other_titles(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Other Title', 'title_other_title');
    }

    get other_titles_two(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Other Title', 'other_titles');
    }

    get alternative_titles(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Alternative Title', 'alternative_titles');
    }

    get variant_title(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Variant Title', 'title_variant_title');
    }

    get appears_in(): VoyagerProperty {
        return {
            label: 'Appears In',
            value: (record: ElasticSearchResult) => {
                const appears_in: any[] = record.get('larger_work_list') ?? [];

                return appears_in.map((appeared_in: { title_of_larger_work_title: string, title_of_larger_work_enumeration_and_date: string }) => {
                    return {
                        value: [appeared_in.title_of_larger_work_title, appeared_in.title_of_larger_work_enumeration_and_date].join(''),
                        url: record.createAdvancedSearchURL(appeared_in.title_of_larger_work_title, 'AND', 'titles', 'starts_with')
                    }
                })
            }
        }
    }

    get contributions(): VoyagerProperty {
        return this.createVoyagerProperty('Contributions', 'contributions', [
            'contribution_title',
            'contribution_relationship_information',
            'contribution_date_of_completion',
            'contribution_description_photograph', // does not exist
            'contribution_series',
            'contribution_publication_date_and_place',
            'contribution_registration_number',
            'contribution_parent_title',
            'contribution_parent_title_issn',
            'contribution_url' // does not exist
        ]);
    }

    get former_title(): VoyagerProperty {
        return this.createVoyagerProperty('Former Title', 'former_titles_list', [
            'title_former_title',
            'title_former_title_remainder_of_title',
            'title_former_title_statement_of_responsibility'
        ]);
    }

    get title_cover_title(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Cover Title', 'title_cover_title');
    }

    get title_distinctive_title(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Distinctive Title', 'title_distinctive_title');
    }

    get title_spine_title(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Spine Title', 'title_spine_title');
    }

    // does not exist!?
    get portion_of_title(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Portion of Title', 'portion_of_title');
    }

    get list_of_works(): VoyagerProperty {
        return {
            label: 'List of Works',
            value: (record: ElasticSearchResult) => {
                const titles = record.get('list_of_titles') as any[];
                if (titles) {
                    const records = titles.map(json => {
                        return new ElasticSearchResult({
                            hit: json
                        });
                    });

                    return records.map(record => {
                        return {
                            value: [record.get('titles_child_id'), record.getTitle()].join(' '),
                            url: '/detailed-record/' + record.get('control_number')
                        }
                    })
                }

                return [];
            }
        }
    }

    get title_translated(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Title Translated', 'title_parallel_title');
    }

    get title_appears_in_document(): VoyagerProperty {
        return {
            label: 'Title Appears in Document',
            value: (record: ElasticSearchResult) => {
                if (record.get('parent_document_title')) {
                    return [{
                        value: record.get('parent_document_title'),
                        url: '/detailed-record/' + record.get('parent_document_control_number')
                    }]
                }

                return null;
            }
        }
    }

    get selection_number_of_copies(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Selection Number of Copies', 'selection_number_of_copies');
    }

    get number_of_pages(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('To Page Value (Number of Pages)', 'number_of_pages', ['internal']);
    }
}
