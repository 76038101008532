<cd-button-menu
  class="actions-menu"
  title="Actions"
  [btnClass]="'me-2'"
  [placement]="'bottom-start'"
  #actionsMenuButton
>
  <li *ngIf="showEmail" (cdMenuTextButton)="email()">Email</li>
  <li (cdMenuTextButton)="download()">Download</li>
  <ng-container *ngIf="loggedIn">
    <ng-container *ngIf="this.featureFlagService.isSavedRecordSearchEnabled() | async">
      <li *ngIf="this.savedFeatureService.isLoggedIn" (cdMenuTextButton)="saveRecords()">Save record(s)</li>
    </ng-container>
  </ng-container>
</cd-button-menu>
