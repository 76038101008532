import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CprsSearch, CprsService } from '@src/app/cprs/services/cprs.service';
import { StateService } from '@src/app/cprs/state/state.service';

@Component({
  selector: 'cprs-highlight',
  templateUrl: './highlight.component.html',
  styleUrls: ['./highlight.component.scss'],
})
export class HighlightComponent implements OnInit {
  public form: UntypedFormGroup = new UntypedFormGroup({
    highlight: new UntypedFormControl(true),
  });

  public current_search: CprsSearch;

  constructor(public stateService: StateService, public cprsService: CprsService) {}

  ngOnInit(): void {
    // not implemented
    this.cprsService.getCurrentSearch().subscribe((search) => {
      this.current_search = search;
    });
    this.form = this.stateService.getFormGroup('highlight');
  }

  search() {
    this.cprsService.search(this.current_search.type);
  }

  public getStatusText() {
    return this.form.get('highlight')?.value === true ? 'on' : 'off';
  }
}
