import { Component } from '@angular/core';
import { StateService } from '../../state/state.service';
import { DialogService } from '../../modules/dialogs/services/dialog.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { Deserializable, deserializableProperty } from '@src/app/cprs/shared/interfaces/deserializable';

export class ILandingCard extends Deserializable {
  @deserializableProperty image!: { resource: string; altText: string };
  @deserializableProperty title!: string;
  @deserializableProperty bodyText!: string;
  @deserializableProperty action!: { label: string; link: any };
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  public text = {
    header1: 'Welcome to the U.S. Copyright Office Public Records System - Pilot',
    header2: 'What would you like to search?',
    search_example_text: 'Example: Copyright numbers, authors, publishers, claimants, etc.',
  };

  public cards: ILandingCard[] = [
    new ILandingCard({
      image: { resource: '../../../assets/images/Help-resources.jpg', altText: '' },
      title: 'Records Research and Certifications',
      bodyText: 'Request official copies of records, certificates, search reports, and deposits.',
      action: { label: 'Learn more', link: 'https://www.copyright.gov/rrc/' },
    }),
    new ILandingCard({
      image: { resource: '../../../assets/images/historical-records.jpg', altText: '' },
      title: 'Historical Public Records',
      bodyText:
        'Learn more about our efforts to preserve historical records through digitization and to make these records available online in an easily searchable format.',
      action: { label: 'Learn more', link: 'https://www.copyright.gov/historic-records/' },
    }),
    new ILandingCard({
      image: { resource: '../../../assets/images/registration-portal.jpg', altText: '' },
      title: 'Virtual Card Catalog',
      bodyText:
        'The Virtual Card Catalog Proof of Concept contains over 41.5 million scanned card images from the U.S. Copyright Card Catalog, which provides an index to copyright registrations, assignments, renewals, and other U.S. Copyright Office records from 1870 through 1977.',
      action: { label: 'Learn more', link: 'https://www.copyright.gov/vcc/' },
    }),
    new ILandingCard({
      image: { resource: '../../../assets/images/Search Our Records.jpg', altText: '' },
      title: 'Copyright Historical Record Books',
      bodyText:
        'A preview of a digitized version of the U.S. Copyright Office’s historical record books. The collection contains images of copyright applications and other records bound in books dating from 1870 through 1977.',
      action: { label: 'Learn more', link: 'https://www.loc.gov/collections/copyright-historical-record-books-1870-to-1977/' },
    }),
  ];

  constructor(
    public stateService: StateService,
    public dialogService: DialogService,
    public localStorageService: LocalStorageService
  ) {
    this.stateService.search_form.reset();
    this.stateService.pagination.get('page_number')?.setValue(1);
    this.stateService.pagination.get('rows_per_page')?.setValue(10);
    this.stateService.clearSelectionLists();

    const stored = this.localStorageService.getItem('cprs-disclaimer');

    if (!stored) {
      this.localStorageService.setItem('cprs-disclaimer', { shown: true }, 30);
      this.dialogService.disclaimer();
    }
  }
}
