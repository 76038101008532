<!-- Datepicker -->
<h5 class="display-5 mb-3">Filter by date</h5>
<cprs-module-date-picker
  class="d-block mb-4"
  (apply)="applyDatepicker()"
  (clear)="clearDatepicker()"
  [showControls]="true"
></cprs-module-date-picker>

<!-- Filter Results -->
<h5 class="display-5 mb-3">Filter results</h5>
<cd-button (click)="toggleAccordions()" class="me-3" mode="secondary" size="small">
  {{ this.searchResultService.expandedState }}
  <cd-icon [cdsIcon]="expandIcon()"></cd-icon>
</cd-button>
<cd-button (click)="clearSelectionLists()" class="me-3" mode="secondary" size="small">
  Clear <cd-icon [cdsIcon]="'repeat'"> </cd-icon>
</cd-button>

<!-- Selection Lists -->
<div class="facet-selection-lists my-3">
  <ng-container *ngFor="let facet of available_facets">
    <!-- Extra container to allow visibility toggles -->
    <ng-container *ngIf="facet.visible">
      <cprs-module-selection-list
        [title]="facet.label"
        class="d-block mb-3"
        mode="accordion"
        [expanded]="isExpanded(facet.value)"
        [formGroup]="this.stateService.selectionLists"
        [formArray]="this.getFormArray(facet.value)"
        (selectionChange)="selectionChange($event, facet)"
      >
      </cprs-module-selection-list>
    </ng-container>
  </ng-container>
</div>
