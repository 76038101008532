export interface CdTableHeader {
  label: string;
  columnKey: string;
  visibility: {
    grid: boolean;
    table: boolean;
    visible: boolean;
  };
  visible: boolean;
  resizable?: boolean;
  sortable?: boolean;
  sortKey?: string;
  sortDefault?: 'asc' | 'desc';
  classes?: any;
}

export interface CdTableData {
  label?: string;
  value: any;
  url?: string;
  visible?: boolean;
}

export class CprsTableGrid {
  constructor(
    public id: string,
    public caption: string,
    public columns: CdTableHeader[],
    public override_label = false
  ) {}

  getDataColumns() {
    return this.columns.map(col => {
      return { label: col.label, key: col.columnKey };
    })
  }
}
