import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export class SimpleError {
  constructor(public message: string, public error: any) {}
}

@Injectable({
  providedIn: 'root',
})
export class CentralErrorService {
  public getCentralErrors = new BehaviorSubject<SimpleError | null>(null);

  addError(message: string, error: any) {
    this.getCentralErrors.next(new SimpleError(message, error));
  }

  static identifyMessage(error: any): string {
    let message = 'An unspecified error occurred';
    console.log('Evaulating message');
    let defaultMessage = '';
    const baseError = error;
    if (error instanceof SimpleError) {
      defaultMessage = error.message;
      // baseError = error.error;
    }
    if (baseError.error && baseError.error.detail) {
      message = `Server error: ${baseError.error.detail}`;
    } else if (baseError instanceof HttpErrorResponse) {
      message = baseError.error;
      if (baseError.error?.message) {
        message = baseError.error?.message;
      }
    } else if (baseError.error && baseError.error.statusText) {
      if (baseError.error.message) {
        message = `Server error: ${baseError.error.message}`;
      } else {
        message = `Server error: ${baseError.error.statusText}`;
      }
    } else if (baseError.error instanceof Error) {
      message = baseError.error.message;
    } else if (baseError.message) {
      message = baseError.message;
    } else {
      message = defaultMessage;
    }
    return message;
  }
}
