import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Authentication, LoginOptions, } from '@cop/design/services';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakLoginOptions } from 'keycloak-js';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { EnvService } from '../../shared/services/env/env.service';
import { Remitter } from '@cop/design/auth';
import { UrlType, lookupURL } from '@src/app/cprs/consts/url.constants';
import { take, shareReplay } from 'rxjs/operators';
/*
    DS3.0 custom implementation of Authguard service,
        - doesnt look like the External/Internal authguards are implemented yet
*/
@Injectable({
  providedIn: 'root',
})
export class CustomAuthGuardService implements CanActivate, Authentication {
  public _loggedIn = new BehaviorSubject(false);
  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly router: Router,
    protected readonly keycloakService: KeycloakService,
    protected readonly envService: EnvService
  ) {
    this.isLoggedIn().then((logged) => {
      this.loggedIn().next(logged);
    })
  }

  loggedIn(): BehaviorSubject<boolean> {
    return this._loggedIn;
  }

  isLoggedIn(): Promise<boolean> {
    return this.keycloakService.isLoggedIn();
  }

  get isAuthenticated(): Observable<boolean> {
    return from(
      new Promise<boolean>((resolve) => {
        if (this.keycloakService && typeof this.keycloakService.isLoggedIn === 'function') {
          this.keycloakService.isLoggedIn().then((authenticated: boolean) => {
            resolve(authenticated);
          });
        } else {
          resolve(false);
        }
      })
    );
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // Force the user to log in if currently unauthenticated.
    // if (!this.authenticated) {
    //   await this.keycloak.login({
    //     redirectUri: window.location.origin + state.url
    //   });
    // }
    if (route || state) {
      return Promise.resolve(true);
    }
    return Promise.resolve(true);
  }

  remitter(): Observable<Remitter> {
      const url = `${lookupURL(UrlType.remitterOrgServiceUrl)}/Remitters/Current`;
      return this.httpClient.get<Remitter>(url).pipe(
        take(1),
        shareReplay(1)
      );
  }

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    this.keycloakService.isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        if (this.envService.internal) {
          if (route.url.toString().includes('disclaimer')) {
            this.router.navigate(['/']);
          }
        } else {
          if (!this.envService.internal && this.keycloakService.isUserInRole('rd_internal')) {
            this.logout('/');
            return;
          }

          this.remitter().subscribe(() => {
            if (this.router.url.includes('/disclaimer')) {
              window.location.href = '/';
            }
          }, () => {
            this.router.navigate(['/disclaimer/register']);
          })
        }
      }
    });

    return of(true);
  }

  login(options?: LoginOptions): Observable<void> {
    if (options) {
      options.idpHint = this.envService.internal ? 'centrify' : 'login-gov-saml';
    }
    return from(this.keycloakService.login(options as KeycloakLoginOptions));
  }

  logout(redirectPath: string = ''): Observable<void> {
    return from(this.keycloakService.logout(redirectPath));
  }
}
