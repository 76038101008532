import { Deserializable, deserializableProperty } from '../../../shared/interfaces/deserializable';

export enum NameDirectoryTypes {
  individual = 'Individual',
  organization = 'Organization',
}

export class NameDirectoryResult extends Deserializable {
  @deserializableProperty date: any;
  @deserializableProperty number_of_records!: number;
  @deserializableProperty name!: string;
  @deserializableProperty name_type!: string;
  @deserializableProperty starting_position!: number;

  constructor(json: any) {
    super(json);
    if (json && this['deserializablePropertyArray']) {
      this['deserializablePropertyArray'].forEach((p: any) => {
        if (json[p] !== undefined) {
          this[p] = json[p];
        }
      });
    }
    if (json.name_type) {
      this.name_type = NameDirectoryTypes[json.name_type];
    }

    if (json.starting_position) {
      this.starting_position = Number(json.starting_position);
    }
  }
}

export class NameDirectoryResponse extends Deserializable {
  @deserializableProperty metaData: any;
  @deserializableProperty results: NameDirectoryResult[] = [];
  @deserializableProperty current_page: number = 0;

  constructor(json: any) {
    super(json);
    if (json && this['deserializablePropertyArray']) {
      this['deserializablePropertyArray'].forEach((p: any) => {
        if (json[p] !== undefined) {
          this[p] = json[p];
        }
      });
    }
    if (json['metadata']) {
      this.metaData = json['metadata'];

      if (this.metaData['current_page_number']) {
        this.current_page = this.metaData['current_page_number'];
      }
    }

    if (json['data'] && json['data'] instanceof Array) {
      this.results = json['data'].map((res) => new NameDirectoryResult(res['hit']));
    }
  }
}
