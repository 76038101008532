<div class="loading-overlay-container">
  <div class="shimmer">
    <div
      class="loading-overlay"
      [ngClass]="{ 'visible-overlay': loading, animate: loading }"
      [style.width]="this.width"
    ></div>
  </div>
  <ng-template [ngIf]="template !== null">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </ng-template>
</div>
