<div *ngIf="this.formGroup && this.formArray" class="cprs-selection-list">
  <ng-container *ngIf="this.histogram && hasAnyValue()">
    <cd-accordion *ngIf="this.mode === 'accordion'" [size]="'small'" [expanded]="expanded">
      <h3 cdAccordionHeader>{{ this.title }}</h3>
      <div cdAccordionBody>
        <form [formGroup]="formGroup">
          <ng-container *ngFor="let group of formArray.controls">
            <div *ngIf="hasValue(group)" class="row g-0">
              <div class="col-9">
                <cd-input-crs
                  [inputId]="group.get('id')?.value"
                  [label]="getLabelVal(group.get('label')?.value) ?? ''"
                  [errorMessage]="'Error state'"
                  [formControl]="$any(group.get('selected'))"
                  (change)="checkboxChange($any($event.target).checked, $any(group))"
                ></cd-input-crs>
              </div>
              <div class="col-3 d-flex justify-content-end">
                {{ group.get('histogram')?.value | number }}
              </div>
            </div>
          </ng-container>
        </form>
      </div>
    </cd-accordion>
  </ng-container>

  <div *ngIf="this.mode === 'default'">
    <h6 class="display-6">{{ this.title }}</h6>
    <div class="cprs-selection">
      <form [formGroup]="formGroup">
        <ng-container *ngFor="let group of formArray.controls">
          <cd-input-crs
            [inputId]="group.get('id')?.value"
            [label]="group.get('label')?.value ?? ''"
            [errorMessage]="'Error state'"
            [formControl]="$any(group.get('selected'))"
            (change)="checkboxChange($any($event.target).checked, $any(group))"
          ></cd-input-crs>
        </ng-container>
      </form>
    </div>
  </div>
</div>
