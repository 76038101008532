import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '@src/app/cprs/services/breadcrumb.service';
import { CprsService } from '@src/app/cprs/services/cprs.service';

@Component({
  selector: 'app-recent-records-page',
  templateUrl: './recent-records-page.component.html',
  styleUrls: ['./recent-records-page.component.scss'],
})
export class RecentRecordsPageComponent implements OnInit {
  constructor(public breadcrumbService: BreadcrumbService, public cprsService: CprsService) {}

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbs = [{ name: 'Home', link: '/', linkType: 'routerLink' }];
    this.breadcrumbService.currentBreadcrumbTextOnly = 'Recent Records';

    this.cprsService.clearState();
  }
}
