<div id="cprs-base-page" class="container g-0 p-3 p-md-4">
  <!-- Breadcrumbs -->
  <div class="row g-0">
    <cd-breadcrumb
      [trail]="this.breadcrumbService.breadcrumbs"
      [current]="this.breadcrumbService.currentBreadcrumbTextOnly"
    ></cd-breadcrumb>
  </div>

  <!-- Search Row w/ Actions -->
  <div class="row g-0 mb-4 border-bottom" [class.pb-0]="!this.hideSearch">
    <ng-container *ngIf="!this.hideSearch">
      <div class="left col-md-6 col-sm-12 pb-3">
        <cprs-search></cprs-search>
      </div>

      <div class="right col-md-6 col-sm-12 d-flex align-items-end justify-content-end">
        <ng-container *ngIf="current_search && showSaveSearch() && isLoggedIn">
          <div *ngIf="this.featureFlagService.isSavedRecordSearchEnabled() | async">
            <ng-container *ngIf="isSaved() && !isSaving">
              <cd-button mode="text" size="small" (btnClick)="removeSearch()">
                <cd-icon [cdsIcon]="'save'"></cd-icon> Remove Search
              </cd-button>
            </ng-container>
            <ng-container *ngIf="!isSaved() && !isSaving">
              <cd-button mode="text" size="small" (btnClick)="saveSearch()">
                <cd-icon [cdsIcon]="'save'"></cd-icon> Save Search
              </cd-button>
            </ng-container>
            <ng-container *ngIf="isSaving">
              <cd-button mode="text" size="small">
                <cd-icon [cdsIcon]="'spinner'" [classes]="'fa-spin'"></cd-icon> {{ savingLabel }}
              </cd-button>
            </ng-container>
          </div>
        </ng-container>

        <div *ngIf="this.featureFlagService.isSavedRecordSearchEnabled() | async" class="h-25">
          <ng-container *ngIf="isDetailedRecord() && isLoggedIn">
            <ng-container *ngIf="isDRVSaved() && !isSaving">
              <cd-button mode="text" size="small" (btnClick)="removeRecord()">
                <cd-icon [cdsIcon]="'save'"></cd-icon> Remove Record
              </cd-button>
            </ng-container>
            <ng-container *ngIf="!isDRVSaved() && !isSaving">
              <cd-button mode="text" size="small" (btnClick)="saveRecord()">
                <cd-icon [cdsIcon]="'save'"></cd-icon> Save Record
              </cd-button>
            </ng-container>
            <ng-container *ngIf="isSaving">
              <cd-button mode="text" size="small">
                <cd-icon [cdsIcon]="'spinner'" [classes]="'fa-spin'"></cd-icon> {{ savingLabel }}
              </cd-button>
            </ng-container>
          </ng-container>
        </div>

        <app-share class="d-block" *ngIf="isDisplayShareBtn"></app-share>
      </div>
    </ng-container>
  </div>

  <!-- border -->

  <!-- Generic Page sections? -->
  <div class="display-4">
    <ng-content select=".title"></ng-content>
  </div>

  <div class="display-5 mt-1">
    <ng-content select=".subtitle"></ng-content>
  </div>

  <div class="mt-2 mb-1">
    <ng-content select=".caption"></ng-content>
  </div>

  <div class="row g-0">
    <ng-content></ng-content>
  </div>
</div>

<app-back-to-top></app-back-to-top>
