import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecentRecordsComponent } from './components/recent-records/recent-records.component';
import { RecentSearchesComponent } from './components/recent-searches/recent-searches.component';
import { DesignTableModule } from '@cop/design/table';
import { DesignFormsModule } from '@cop/design/forms';
import { DesignComponentsModule } from '@cop/design/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { TableModule as CprsTableModule } from '../table/table.module';
import { SharedModule } from '@src/app/shared/shared.module';
@NgModule({
  declarations: [RecentRecordsComponent, RecentSearchesComponent],
  imports: [
    CommonModule,
    DesignTableModule,
    CprsTableModule,
    ReactiveFormsModule,
    FormsModule,
    DesignComponentsModule,
    DesignFormsModule,
    NgxPaginationModule,
    SharedModule,
  ],
  exports: [RecentRecordsComponent, RecentSearchesComponent],
})
export class RecentModule {}
