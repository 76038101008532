import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TemplateService } from '@src/app/cprs/services/template.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ElasticSearchResult } from '../../../../shared/interfaces/elastic-search.interface';
import { Router } from '@angular/router';
import { CentralErrorService } from '@app/cprs/services/central.error.service';
import { CdSelectionService } from '@src/app/cprs/modules/selection/services/selection.service';

@Component({
  selector: 'cprs-dialog',
  templateUrl: './cprs-dialog.component.html',
  styleUrls: ['./cprs-dialog.component.scss'],
})
export class CprsDialogComponent implements OnDestroy {
  subscriptions: Subscription[] = [];
  public opened = true;
  public successModal = false;
  public alertType = 'default';

  public formGroup = new UntypedFormGroup({
    records: new UntypedFormControl([]),
    email_address: new UntypedFormControl('', {
      validators: [Validators.required, Validators.email],
      updateOn: 'blur'
    }),
    template_type: new UntypedFormControl('detail_record'),
    skip_email: new UntypedFormControl(false),
  });

  public data: { title: string; responseType: 'detail_record' | 'search_results'; records: ElasticSearchResult[] };

  public error_message = 'Invalid email address';

  public hasAcquisitionsOnDrv = false;
  public hasAcquisitionsSearchResults = false;

  constructor(
    public templateService: TemplateService,
    public selectionService: CdSelectionService,
    public router: Router,
    public centralErrorService: CentralErrorService,
  ) { }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  send() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    }

    const records = this.data.records;
    const response_type = this.data.responseType ?? 'detail_record';
    this.formGroup.get('template_type')?.setValue(response_type);
    if (records.length === 0) {
      this.error_message = 'Records required';
      this.formGroup.get('email_address')?.setErrors({ email: this.error_message });
      return;
    }

    const acquisitionsOnSearch = this.router.url.includes('search') ||
    this.router.url.includes('recent') || this.router.url.includes('associated') || this.router.url.includes('saved');
    this.hasAcquisitionsSearchResults = !!([
      this.selectionService.hasAcquisitions('search_results'),
      this.selectionService.hasAcquisitions('recent_records'),
      this.selectionService.hasAcquisitions('associated_records'),
      this.selectionService.hasAcquisitions('saved_records'),
    ].some(Boolean) && acquisitionsOnSearch);

    this.hasAcquisitionsOnDrv = [
      this.selectionService.hasAcquisitions('detailed_record'),
    ].some(Boolean);

    if (this.hasAcquisitionsSearchResults || this.hasAcquisitionsOnDrv) {
      this.error_message = 'Note: you have selected acquisitions record(s), they will be filtered.'
    }

    if (this.formGroup.valid) {
      this.formGroup.get('records')?.setValue(records);
      const subscription = this.templateService.render_detail_for_email(this.formGroup.value).subscribe(
        () => {
          this.opened = false;
          this.successModal = true;
          if (!this.router.url.includes('detailed-record') && !this.router.url.includes('application-card')) {
            this.selectionService.deselectAllByKey('detailed_record');
          }
        },
        (error: HttpErrorResponse) => {
          this.centralErrorService.addError('Email', error);
          this.error_message = error.error.detail ? error.error.detail : error.statusText;
          this.formGroup.get('email_address')?.setErrors({ email: error.error.detail });
        }
      );
      this.subscriptions.push(subscription);
    } else {
      this.error_message = 'Records required';
      this.formGroup.get('email_address')?.setErrors({ email: this.error_message });
    }
  }

  cancel() {
    this.formGroup.reset();
    this.opened = false;
  }

  close() {
    this.opened = false;
    this.successModal = false;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }
}
