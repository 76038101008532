import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultsComponent } from './search-results/search-results.component';
import { DesignTableModule } from '@cop/design/table';
import { DesignFormsModule } from '@cop/design/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectionModule } from '../selection/selection.module';
import { DesignComponentsModule } from '@cop/design/components';
import { SharedModule } from '@src/app/shared/shared.module';
import { SharedModule as CprsSharedModule } from '@src/app/cprs/modules/shared/shared.module';
import { TableModule as CprsTableModule } from '../table/table.module';
import { SystemModule } from '../system/system.module';

@NgModule({
  declarations: [SearchResultsComponent],
  imports: [
    CommonModule,
    DesignComponentsModule,
    SharedModule,
    DesignFormsModule,
    ReactiveFormsModule,
    SelectionModule,
    DesignTableModule,
    CprsSharedModule,
    CprsTableModule,
    SystemModule
  ],
  exports: [SearchResultsComponent],
})
export class SearchResultsModule {}
