<div
  *appLoading="'detailed_record'"
  class="record-navigation pt-2 pb-4 d-flex justify-content-center align-items-center"
>
  <ng-container *ngIf="current_search">
    <cd-button
      [disabled]="this.isPrevDisabled()"
      [mode]="'secondary'"
      [size]="'large'"
      [type]="'button'"
      [btnClass]="'me-2'"
      (btnClick)="this.getPreviousRecord()"
    >
      <cd-icon [cdsIcon]="'caret-left'"></cd-icon>
      Previous
    </cd-button>

    <cd-button
      [disabled]="this.isNextDisabled()"
      [mode]="'secondary'"
      [size]="'large'"
      [type]="'button'"
      [btnClass]="'me-2'"
      (btnClick)="this.getNextRecord()"
    >
      Next
      <cd-icon [cdsIcon]="'caret-right'"></cd-icon>
    </cd-button>
  </ng-container>
</div>
