<cprs-base-page>
  <div class="title">{{ recordsText }}</div>
  <ng-container *ngIf="current_tab === 'saved-records'">
    <div style="max-width: 200px">
      <div *appLoading="'saved_records'" class="caption">
        <div>Total saved records: {{ total }}</div>
        <cd-paging-display
          *ngIf="total > 0"
          [pageStart]="manualPagingDisplay.pageStart"
          [pageEnd]="manualPagingDisplay.pageEnd"
          [total]="manualPagingDisplay.total"
        ></cd-paging-display>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="current_tab === 'saved-searches'">
    <div style="max-width: 200px">
      <div *appLoading="'saved_searches'" class="caption">
        <div>Total saved searches: {{ totalSearches }}</div>
        <cd-paging-display
          *ngIf="totalSearches > 0"
          [pageStart]="searchesPaginDisplay.pageStart"
          [pageEnd]="searchesPaginDisplay.pageEnd"
          [total]="searchesPaginDisplay.total"
        ></cd-paging-display>
      </div>
    </div>
  </ng-container>
  <cd-tabs (tabClick)="savedRecords($event)">
    <cd-tab [id]="'saved-records'" [tabTitle]="'Saved Records'">
      <cprs-module-saved-records></cprs-module-saved-records>
    </cd-tab>
    <cd-tab [id]="'saved-searches'" [tabTitle]="'Saved Searches'">
      <cprs-module-saved-searches></cprs-module-saved-searches>
    </cd-tab>
  </cd-tabs>
</cprs-base-page>
