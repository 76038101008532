import { VoyagerCopyrightData } from './groups/copyright-data.voyager';
import { VoyagerTitles } from './groups/titles.voyager';
import { VoyagerDates } from './groups/dates.voyager';
import { VoyagerParties } from './groups/parties.voyager';
import { VoyagerClaimDescription } from './groups/claim-description.voyager';
import { VoyagerStandardIdentifiers } from './groups/standard-identifiers.voyager';
import { VoyagerDescription } from './groups/description.voyager';
import { VoyagerNotes } from './groups/notes.voyager';
import { VoyagerSerialInformation } from './groups/serial-information.voyager';
import { VoyagerAdministrative } from './groups/administrative.voyager';
import { VoyagerNames } from './groups/names.voyager';
import { VoyagerDepositInformation } from './groups/deposit-information.voyager';

export enum VoyagerRecordTypes {
    registration = 'registration',
    recordation = 'recordartion',
    acquisition = 'acquisition'
}

export class Voyager {
    public copyright_data = new VoyagerCopyrightData();
    public titles = new VoyagerTitles();
    public dates = new VoyagerDates();
    public parties = new VoyagerParties();
    public claim_description = new VoyagerClaimDescription();
    public standard_identifiers = new VoyagerStandardIdentifiers();
    public description = new VoyagerDescription();
    public notes = new VoyagerNotes();
    public serial_information = new VoyagerSerialInformation();
    public administrative = new VoyagerAdministrative();
    public deposit_information = new VoyagerDepositInformation();
    public names = new VoyagerNames();
}
