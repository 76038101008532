import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';
import { environment } from '@src/environments/environment';
import { ApiParameters } from './parameters.service';
import { ElasticSearchResponse, ElasticSearchResult } from '../shared/interfaces/elastic-search.interface';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { NameDirectoryRequest } from '@src/app/cprs/modules/shared/models/name-directory-request.model';
import { NameDirectoryResponse } from '@src/app/cprs/modules/shared/models/name-directory-response.model';
import { AssociatedRecordsRequest } from '@src/app/cprs/modules/shared/models/associated-records-request.model';
import { Params } from '@angular/router';
import { ErrorService } from './error.service';
import { FeatureFlagService } from '@src/app/cprs/modules/shared/services/feature-flag/feature-flag.service';
import { CprsRecordTypes } from '../modules/detailed-record/services/detailed-record.service';

export const SEARCH_SERVICE_ERRORS = {
  search_unique_record: {
    title: 'Error Loading Result',
    default: 'There was an error loading the results. Please try again later.',
  },
  search: {
    title: 'Error Loading Search Results',
    default: 'There was an error loading the search results. Please try again later.'
  },
  name_directory: {
    title: 'Error Loading Name Directory',
    default: 'There was an error loading the name directory. Please try again later.'
  },
  associated_record: {
    title: 'Error Loading Associated Records',
    default: 'There was an error loading the associated records. Please try again later.'
  },
  application: {
    title: 'Application Error',
    default: 'There was an error in the application.'
  }
}
@Injectable({
  providedIn: 'root',
})
export class CdSearchService {
  private readonly baseUrl = environment.searchServiceUrl;

  constructor(
    public http: HttpClient,
    private envService: EnvService,
    private errorService: ErrorService,
    private featureFlagService: FeatureFlagService
  ) {
    this.baseUrl = this.envService.searchServiceUrl;
  }

  simpleSearch(parameters: ApiParameters): Observable<ElasticSearchResponse> {
    const url = `${this.baseUrl}/simple_search_dsl`;

    return this.http.get<ElasticSearchResponse>(url, { params: parameters as any }).pipe(
      map(resp => {
        this.errorService.resetErrors('search');
        return resp;
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorService.httpError(
          error,
          'search',
          SEARCH_SERVICE_ERRORS.search.title,
          SEARCH_SERVICE_ERRORS.search.default
        );
        return throwError(error);
      }),
    );
  }

  advancedSearch(parameters: ApiParameters): Observable<ElasticSearchResponse> {
    const url = `${this.baseUrl}/advance_search`;

    return this.http.post<any>(url, parameters).pipe(
      map(resp => {
        this.errorService.resetErrors('search');
        return resp;
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorService.httpError(
          error,
          'search',
          SEARCH_SERVICE_ERRORS.search.title,
          SEARCH_SERVICE_ERRORS.search.default
        );
        return throwError(error);
      }),
    )
  }

  searchUniqueRecord(control_number: string): Observable<ElasticSearchResult> {
    const url = `${this.baseUrl}/search_unique_record`;

    return this.http.get<any>(url, { params: { query: control_number } }).pipe(
      map((resp) => {
        const json = resp['data'] && resp['data'].length > 0 ? resp['data'].pop() : {};
        this.errorService.resetErrors('search_unique_record');
        return new ElasticSearchResult(json);
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorService.httpError(
          error,
          'search_unique_record',
          SEARCH_SERVICE_ERRORS.search_unique_record.title,
          SEARCH_SERVICE_ERRORS.search_unique_record.default
        );
        return throwError(error);
      })
    );
  }

  copyrightsPdfExists(copyright_number: string): Observable<{ exists: boolean; url: string } | null> {
    const url = `${this.baseUrl}/copyrights/pdf/exists`;
    const params = { copyright_number };

    return this.featureFlagService.isDisplayPdfCertificateEnabled().pipe(
      mergeMap((value: boolean, _index: number) => {
        if (value) {
          return this.http.get<any>(url, { params }).pipe(
            map((resp: { message: any }) => {
              const exists = resp.message[copyright_number];

              return { exists, url: resp.message['url'] };
            }),
          );
        } else {
          return of(null);
        }
      })
    );
  }

  getFullRecord(type: CprsRecordTypes = CprsRecordTypes.voyager): Observable<ElasticSearchResult> {
    let url = '/assets/mock_data/complete-voyager.json';
    if (type === CprsRecordTypes.card_catalog) {
      url = '/assets/mock_data/complete-card-catalog.json';
    }

    return this.http.get<any>(url).pipe(
      map((resp) => {
        const json = resp['data'] && resp['data'].length > 0 ? resp['data'].pop() : {};
        return new ElasticSearchResult(json);
      })
    );
  }

  getUniqueRecords(control_numbers: string[]): Observable<ElasticSearchResult[]> {
    const url = `${this.baseUrl}/search_unique_record`;

    if (control_numbers.length === 0) {
      return of([]);
    }

    return this.http.get<any>(url, { params: { query: control_numbers.join(',') } }).pipe(
      map((resp) => {
        const data: any[] = resp['data'];
        this.errorService.resetErrors('search_unique_record');
        return data.map(json => new ElasticSearchResult(json));
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorService.httpError(
          error,
          'search_unique_record',
          SEARCH_SERVICE_ERRORS.search_unique_record.title,
          SEARCH_SERVICE_ERRORS.search_unique_record.default
        );
        return throwError(error);
      })
    );
  }

  displayUniqueRecords(control_numbers: string[]): Observable<{}> {
    const url = `${this.baseUrl}/search_unique_record`;

    if (control_numbers.length === 0) {
      return of([]);
    }

    return this.http.get<any>(url, { params: { query: control_numbers.join(',') } }).pipe(
      map((resp) => {
        const data: any[] = resp['data'];
        this.errorService.resetErrors('search_unique_record');
        return { metadata: resp.metadata, data: data.map(json => new ElasticSearchResult(json)) };
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorService.httpError(
          error,
          'search_unique_record',
          SEARCH_SERVICE_ERRORS.search_unique_record.title,
          SEARCH_SERVICE_ERRORS.search_unique_record.default
        );
        return throwError(error);
      })
    );
  }

  searchUniqueRecordCSV(control_numbers: string[]): Observable<Blob> {
    const url = `${this.baseUrl}/search_unique_record`;

    return this.http.get<Blob>(url, {
      params: { query: control_numbers.join(', '), mime_type: 'text/csv' },
      responseType: 'blob' as 'json',
    });
  }

  nameDirectory(params: NameDirectoryRequest): Observable<NameDirectoryResponse> {
    const url = `${this.baseUrl}/names_directory_list`;
    params.page_number = params.page_number + 1;
    return this.http.post<NameDirectoryResponse>(url, params).pipe(
      map((resp) => {
        this.errorService.resetErrors('name_directory');
        return new NameDirectoryResponse(resp)
      }),

      catchError((error: HttpErrorResponse) => {
        this.errorService.httpError(
          error,
          'name_directory',
          SEARCH_SERVICE_ERRORS.name_directory.title,
          SEARCH_SERVICE_ERRORS.name_directory.default
        );
        return throwError(error);
      })
    );
  }

  associatedRecords(params: AssociatedRecordsRequest): Observable<ElasticSearchResponse> {
    const url = `${this.baseUrl}/search_names_directory_associated_records`;
    params.page_number = params.page_number + 1;
    return this.http
      .get<any>(url, { params: params as Params })
      .pipe(
        map((resp) => {
          this.errorService.resetErrors('associated_record');
          return new ElasticSearchResponse(resp)
        }),

        catchError((error: HttpErrorResponse) => {
          this.errorService.httpError(
            error,
            'associated_record',
            SEARCH_SERVICE_ERRORS.associated_record.title,
            SEARCH_SERVICE_ERRORS.associated_record.default
          );
          return throwError(error);
        })
      );
  }
}
