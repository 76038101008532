import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CprsSearch, CprsService } from '@src/app/cprs/services/cprs.service';
import { ParametersService } from '@src/app/cprs/services/parameters.service';
import { CdSearchService } from '@src/app/cprs/services/search.service';
import { ElasticSearchResponse, ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { StateService } from '@src/app/cprs/state/state.service';
import { concat } from 'rxjs';
import { RecentFeatureService } from '../../../recent/services/recent.service';
import { SavedFeatureService } from '../../../saved/services/saved.feature.service';

@Component({
  selector: 'cprs-record-navigation',
  templateUrl: './cprs-record-navigation.component.html',
  styleUrls: ['./cprs-record-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CprsRecordNavigationComponent implements OnInit {
  public current_control_number: string;

  public current_index: number;
  public abs_index: number;

  public current_search: CprsSearch;

  public previousDisabled = false;
  public currentScreen: string;

  constructor(
    public parameterService: ParametersService,
    public searchService: CdSearchService,
    public stateService: StateService,
    public cprsService: CprsService,
    public route: ActivatedRoute,
    public router: Router,
    public recentService: RecentFeatureService,
    public savedFeatureService: SavedFeatureService
  ) { }

  // assumes we have a control_number in the URL
  ngOnInit(): void {
    this.currentScreen = this.cprsService.getCurrentSearchResults();
    this.route.params.subscribe(() => {
      this.current_control_number = this.route.snapshot.paramMap.get('id') ?? '';

      if (this.current_search) {
        this.current_index = this.current_search.response.data.findIndex(
          (d) => d.get('control_number') === this.current_control_number
        );
        const page_number = this.stateService.pagination.get('page_number')?.value;
        const rows_per_page = this.stateService.pagination.get('rows_per_page')?.value;
        if (this.currentScreen === 'recent_records' || this.currentScreen === 'saved_records') {
          this.abs_index = this.current_index + 1;
        } else {
          this.abs_index = this.current_index + 1 + rows_per_page * (page_number - 1);
        }
      }
    });

    concat(this.cprsService.getCurrentSearch(), this.cprsService.onFacet).subscribe(search => {
      if (search && this.currentScreen === 'search_results') {
        this.current_search = search;

        this.current_control_number = this.route.snapshot.paramMap.get('id') ?? '';

        this.current_index = this.current_search.response.data.findIndex(
          (d) => d.get('control_number') === this.current_control_number
        );
        const page_number = this.stateService.pagination.get('page_number')?.value;
        const rows_per_page = this.stateService.pagination.get('rows_per_page')?.value;
        this.abs_index = this.current_index + 1 + rows_per_page * (page_number - 1);
      }
    })

    this.cprsService.associated_record_search.subscribe(search => {
      if (search && this.currentScreen === 'associated_records') {
        this.current_search = search;

        this.current_control_number = this.route.snapshot.paramMap.get('id') ?? '';

        this.current_index = this.current_search.response.data.findIndex(
          (d) => d.get('control_number') === this.current_control_number
        );
        const page_number = this.stateService.pagination.get('page_number')?.value;
        const rows_per_page = this.stateService.pagination.get('rows_per_page')?.value;
        this.abs_index = this.current_index + 1 + rows_per_page * (page_number - 1);
      }
    });
    if (this.currentScreen === 'recent_records') {
      if (this.cprsService.getRecentRecordSearch()?.data) {
        this.navigateSaveRecent(this.cprsService.getRecentRecordSearch());
      }
    } else if (this.currentScreen === 'saved_records') {
      this.savedFeatureService.getElasticSavedRecords().subscribe((search: any) => {
        if (search) {
          search.data = search.data?.sort((a: any, b: any) => {
            const timeA = a.saved?.data.timestamp ?? '';
            const timeB = b.saved?.data.timestamp ?? '';
            return timeB.localeCompare(timeA);
          });
          this.navigateSaveRecent(search);
        }
      });
    }
  }

  navigateSaveRecent(search: any) {
    const updatedParams = this.parameterService.simpleSearchParameters();
    const response = new ElasticSearchResponse({});
    response.data = search.data;
    response.metadata = search.metadata;
    this.current_search = new CprsSearch('simple', updatedParams, response, '');
    this.current_index = this.current_search.response.data.findIndex(
      (d: any) => d.get('control_number') === this.current_control_number
    );
    this.abs_index = this.current_index + 1;
  }

  getPreviousRecord() {
    // if (this.recent_record) {
    //   this.displayPreviousRecord();
    //   return;
    // }
    if (!this.current_search) {
      return;
    }

    this.current_index = this.current_search.response.data.findIndex(
      (d) => d.get('control_number') === this.current_control_number
    );
    const rows_per_page = this.stateService.pagination.get('rows_per_page')?.value;
    const prev_index = this.current_index - 1 < -1 ? -1 : this.current_index - 1;
    const prevRecord = this.current_search.response.data[prev_index];
    const page_number = this.stateService.pagination.get('page_number')?.value;
    this.abs_index = this.current_index + 1 + rows_per_page * (page_number - 1);

    this.previousDisabled = !!prevRecord;
    if (prev_index <= -1 && page_number >= 0) {
      this.stateService.pagination.get('page_number')?.setValue(Number(this.current_search.parameters.page_number) - 1);
      if (this.current_search.type === 'simple' && this.currentScreen !== 'recent_records' &&
        this.currentScreen !== 'saved_records') {
        const updatedParams = this.parameterService.simpleSearchParameters();
        this.searchService.simpleSearch(updatedParams).subscribe((resp) => {
          this.current_search = this.cprsService.createCprsSearch('simple', updatedParams, new ElasticSearchResponse(resp));
          this.cprsService.cprsSearch = this.current_search;
          this.cprsService.$page.totalElements = this.current_search.response.metadata.hit_count ?? 0;
          this.cprsService.$page.totalPages = Math.ceil(this.cprsService.$page.totalElements / this.cprsService.$page.size);
          if (this.cprsService.$page.totalElements <= this.cprsService.$page.numberOfElements) {
            this.cprsService.$page.numberOfElements = this.cprsService.$page.totalElements;
          }
          this.cprsService.searchPage.next(this.cprsService.$page);
          this.cprsService.current_search.next(this.current_search);
          const prevRecord = this.current_search.response.data[this.current_search.response.data.length - 1];
          if (prevRecord) {
            this.current_index = 9;
            this.router.navigate([this.getHref(prevRecord)]);
          }
        });
      } else if (this.current_search.type === 'advanced' && this.currentScreen !== 'recent_records' &&
        this.currentScreen !== 'saved_records') {
        const updatedParams = this.parameterService.advancedSearchParameters();
        this.searchService.advancedSearch(updatedParams).subscribe((resp) => {
          this.current_search = this.cprsService.createCprsSearch('advanced', updatedParams, new ElasticSearchResponse(resp));
          this.cprsService.cprsSearch = this.current_search;
          this.cprsService.$page.totalElements = this.current_search.response.metadata.hit_count ?? 0;
          this.cprsService.$page.totalPages = Math.ceil(this.cprsService.$page.totalElements / this.cprsService.$page.size);
          if (this.cprsService.$page.totalElements <= this.cprsService.$page.numberOfElements) {
            this.cprsService.$page.numberOfElements = this.cprsService.$page.totalElements;
          }
          this.cprsService.searchPage.next(this.cprsService.$page);
          this.cprsService.current_search.next(this.current_search);
          const prevRecord = this.current_search.response.data[this.current_search.response.data.length - 1];
          if (prevRecord) {
            this.current_index = 9;
            this.router.navigate([this.getHref(prevRecord)]);
          }
        });
      }
      return;
    }
    if (prev_index >= 0 && prevRecord) {
      this.router.navigate([this.getHref(prevRecord)]);
    }
  }

  getNextRecord() {
    if (!this.current_search) {
      return;
    }

    this.current_index = this.current_search.response.data.findIndex(
      (d) => d.get('control_number') === this.current_control_number
    );
    const rows_per_page = this.stateService.pagination.get('rows_per_page')?.value;
    const page_number = this.stateService.pagination.get('page_number')?.value;
    const next_index = this.current_index + 1;
    const nextRecord = this.current_search.response.data[next_index];
    if (this.currentScreen === 'recent_records' || this.currentScreen === 'saved_records') {
      this.abs_index = this.current_index + 1;
    } else {
      this.abs_index = this.current_index + 1 + rows_per_page * (page_number - 1);
    }
    if (next_index < this.current_search.parameters.records_per_page && nextRecord) {
      this.router.navigate([this.getHref(nextRecord)]);
    } else if (next_index >= this.current_search.parameters.records_per_page) {
      this.stateService.pagination.get('page_number')?.setValue(Number(this.current_search.parameters.page_number) + 1);
      if (this.current_search.type === 'simple' &&
        this.currentScreen !== 'recent_records' &&
        this.currentScreen !== 'saved_records') {
        const updatedParams = this.parameterService.simpleSearchParameters();
        this.searchService.simpleSearch(updatedParams).subscribe((resp) => {
          this.current_search = this.cprsService.createCprsSearch('simple', updatedParams, new ElasticSearchResponse(resp));
          this.current_index = -1;
          this.cprsService.cprsSearch = this.current_search;
          this.cprsService.$page.totalElements = this.current_search.response.metadata.hit_count ?? 0;
          this.cprsService.$page.totalPages = Math.ceil(this.cprsService.$page.totalElements / this.cprsService.$page.size);
          if (this.cprsService.$page.totalElements <= this.cprsService.$page.numberOfElements) {
            this.cprsService.$page.numberOfElements = this.cprsService.$page.totalElements;
          }
          this.cprsService.searchPage.next(this.cprsService.$page);
          this.cprsService.current_search.next(this.current_search);
          this.getNextRecord();
        });
      } else if (this.current_search.type === 'advanced' &&
        this.currentScreen !== 'recent_records' &&
        this.currentScreen !== 'saved_records') {
        const updatedParams = this.parameterService.advancedSearchParameters();
        this.searchService.advancedSearch(updatedParams).subscribe((resp) => {
          this.current_search = this.cprsService.createCprsSearch('advanced', updatedParams, new ElasticSearchResponse(resp));
          this.current_index = -1;
          this.cprsService.cprsSearch = this.current_search;
          this.cprsService.$page.totalElements = this.current_search.response.metadata.hit_count ?? 0;
          this.cprsService.$page.totalPages = Math.ceil(this.cprsService.$page.totalElements / this.cprsService.$page.size);
          if (this.cprsService.$page.totalElements <= this.cprsService.$page.numberOfElements) {
            this.cprsService.$page.numberOfElements = this.cprsService.$page.totalElements;
          }
          this.cprsService.searchPage.next(this.cprsService.$page);
          this.cprsService.current_search.next(this.current_search);
          this.getNextRecord();
        });
      } else if ((this.currentScreen === 'recent_records' || this.currentScreen === 'saved_records') && nextRecord) {
        this.router.navigate([this.getHref(nextRecord)]);
      }
    }
  }

  getHref(data: ElasticSearchResult) {
    const isCardCatalog = data.get('system_of_origin') === 'card_catalog';
    return isCardCatalog
      ? `/application-card/${data.get('control_number')}`
      : `/detailed-record/${data.get('control_number')}`;
  }

  isPrevDisabled() {
    return this.abs_index <= 1;
  }

  isNextDisabled() {
    return this.abs_index >= this.current_search.response.metadata.hit_count;
  }
}
