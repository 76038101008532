<cprs-table-grid
  #tableGrid
  *appLoading="'recent_records'"
  [interface]="newTable"
  [mode]="mode"
  [selectionKey]="'recent_records'"
  [content]="data"
  [totalElements]="totalElements"
>
  <div header-left>
    <cd-button-menu [btnClass]="'me-2'" title="Actions" [placement]="'bottom-start'" #actionsMenuButton>
      <li (cdMenuTextButton)="email()">Email</li>
      <li (cdMenuTextButton)="download()">Download</li>
      <ng-container *ngIf="this.featureFlagService.isSavedRecordSearchEnabled() | async">
        <li *ngIf="this.savedService.isLoggedIn" (cdMenuTextButton)="save()">Save record(s)</li>
      </ng-container>
      <li (cdMenuTextButton)="clear()">Clear record(s)</li>
    </cd-button-menu>

    <cd-button-menu [btnClass]="'me-2'" [title]="sortLabel" [placement]="'bottom-start'" #sortByMenuButton>
      <li *ngFor="let option of sort_field_options" (cdMenuTextButton)="sortBy(option)">
        {{ option.text }}
      </li>
    </cd-button-menu>
  </div>

  <div sideColumnHeader>Date viewed</div>
  <ng-template let-data #sideColumn>
    {{ data?.timestamp?.value }}
  </ng-template>

  <ng-container *ngIf="this.savedService.isLoggedIn">
    <ng-template let-data #header>
      <div class="d-flex justify-content-end">
        <ng-container *ngIf="this.isSaved(data)">
          <cd-button mode="text" size="small" (btnClick)="removeRecord(data)">
            <cd-icon [cdsIcon]="'save'"></cd-icon> Remove
          </cd-button>
        </ng-container>
        <ng-container *ngIf="!this.isSaved(data)">
          <cd-button mode="text" size="small" (btnClick)="saveRecord(data)">
            <cd-icon [cdsIcon]="'save'"></cd-icon> Save
          </cd-button>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>

  <div no-content-grid>Once you view a record, your record(s) from this session will be available here.</div>
</cprs-table-grid>

<div class="my-4">
  * This page is limited to your 100 most recent records viewed and will expire after 4 hours of inactivity.
</div>
