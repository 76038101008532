import { VoyagerProperty } from '../../../services/transformer.service';
import { VoyagerHelper } from '../voyager.helper';

export class VoyagerAdministrative extends VoyagerHelper {
    get service_request_row_identifier(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('eCO SR Row Identifier', 'service_request_row_identifier', ['internal']);
    }

    get record_cataloging_agency(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Record Cataloging Agency', 'cataloging_source_original_cataloging_agency', ['internal']);
    }

    get record_transcribing_agency(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Record Transcribing Agency', 'cataloging_source_transcribing_agency', ['internal']);
    }

    get record_origin_code(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Record Origin Code', 'record_origin_code', ['internal']);
    }

    get copyright_count_category(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Copyright Count Category', 'copyright_count_category', ['internal']);
    }

    // there are a few other unmapped field in legacy model
}
