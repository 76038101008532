import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  toggleShare = false;

  // eslint-disable-next-line no-empty-function
  ngOnInit(): void { }

  onMouseEnter() {
    this.toggleShare = true;
  }

  onMouseLeave() {
    this.toggleShare = false;
  }

  toggleShow() {
    this.toggleShare = !this.toggleShare;
  }

  get copyShare() {
    navigator.clipboard.writeText(location.href).then();
    return false;
  }

  get shareFacebook() {
    return encodeURIComponent(location.href);
  }

  get shareTwitter() {
    return encodeURIComponent(document.title + ' ' + location.href);
  }
}
