<form class="cd dropdown col-12" [formGroup]="formGroup">
  <cd-label [label]="'Field Heading'" [errorMessage]="'Error State'">
    <select cdSelect #cdSelectID formControlName="column_name" (change)="select(formGroup.get('column_name')?.value)">
      <optgroup label="General" class="col-sm-4 mx-1 mega-col">
        <option *ngFor="let option of options.general" id="{{ option.value }}" [ngValue]="option.value">
          {{ option.text }}
        </option>
      </optgroup>
      <optgroup label="Copyright Numbers" class="col-sm-4 mx-1 mega-col">
        <option *ngFor="let option of options.copyright_numbers" id="{{ option.value }}" [ngValue]="option.value">
          {{ option.text }}
        </option>
      </optgroup>
      <optgroup label="Names">
        <option *ngFor="let option of options.names" id="{{ option.value }}" [ngValue]="option.value">
          {{ option.text }}
        </option>
      </optgroup>
      <optgroup label="Standardized Numbers">
        <option *ngFor="let option of options.standardized_numbers" id="{{ option.value }}" [ngValue]="option.value">
          {{ option.text }}
        </option>
      </optgroup>
      <optgroup label="Other">
        <option *ngFor="let option of options.other" id="{{ option.value }}" [ngValue]="option.value">
          {{ option.text }}
        </option>
      </optgroup>
    </select>
  </cd-label>
</form>
