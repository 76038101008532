import { Component, ViewChild } from '@angular/core';
import { ButtonMenuComponent } from '@cop/design/components';
import { CprsSearch, CprsService } from '@src/app/cprs/services/cprs.service';
import { StateService } from '@src/app/cprs/state/state.service';

@Component({
  selector: 'cprs-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss'],
})
export class SortByComponent {
  @ViewChild('dropdownMenuTextButton')
  menuTextButton!: ButtonMenuComponent;

  public sort_by_label = 'Sort by';
  public sort_field_options: { text: string; value: { sort_field: string; sort_order: string } }[] = [];

  public current_search: CprsSearch;

  constructor(public stateService: StateService, public cprsService: CprsService) {
    this.sort_field_options = this.stateService.sort_field_options;

    this.cprsService.getCurrentSearch().subscribe((search) => {
      this.current_search = search;

      const sort_order = this.stateService._state.get('sort_order');
      const sort_field = this.stateService._state.get('sort_field');

      const option = this.sort_field_options.find(
        (o) => o.value.sort_field === sort_field?.value && o.value.sort_order === sort_order?.value
      );
      if (option) {
        this.sort_by_label = `Sort by ${option.text}`;
      } else {
        this.sort_by_label = 'Sort by';
      }
    });
  }

  sortBy(option: { text: string; value: { sort_field: string; sort_order: string } }) {
    this.stateService._state.get('sort_field')?.setValue(option.value.sort_field);
    this.stateService._state.get('sort_order')?.setValue(option.value.sort_order);

    this.sort_by_label = `Sort by ${option.text}`;
    this.stateService.pagination.get('page_number')?.setValue(Number(1));

    this.cprsService.search(this.current_search.type);

    this.menuTextButton.closeDropdown();
  }
}
