import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { OpenSeadragonTileSource } from '../../component/cd-openseadragon/cd-openseadragon.component';
import { VoyagerProperty } from '../../services/transformer.service';

export class CardCatalog {
    public properties: VoyagerProperty[] = [
        { label: 'Title of Work', jsonKey: 'title_of_work' },
        { label: 'Record type', jsonKey: 'type_of_record' },
        { label: 'Registration Number', jsonKey: 'registration_number' },
        { label: 'Claimant', jsonKey: 'claimant' },
        { label: 'Author', jsonKey: 'author' },
        {
          label: 'Work Type',
          value: (record: ElasticSearchResult) => {
            const types_of_work: string[] = record.get('all_type_of_work');

            return types_of_work.map(type => this.toTitleCase(type));
          }
        },
        { label: 'Application Received Date', jsonKey: 'application_date' },
        { label: 'Deposit Received Date', jsonKey: 'deposit_received_date' },
        { label: 'Fee Received Date', jsonKey: 'fee_date' },
        { label: 'Affidavit Received Date', jsonKey: 'affidavit_received_date' },
        { label: 'First Published Date', jsonKey: 'first_published_date' },
        { label: 'Publication Issue Date', jsonKey: 'publication_issue_date' },
        { label: 'Publisher Name', jsonKey: 'publisher_name' },
        { label: 'Publication Number', jsonKey: 'publication_number' },
        { label: 'Publication Volume', jsonKey: 'publication_volume' },
        {
          label: 'Drawer Name',
          value: (record: ElasticSearchResult) => {
            const drawerLabels: string[] = record.get('card_identifiers');
            return drawerLabels.map(label => this.getDrawerLabel(label, record.get('time_period'))).slice(0, 1)
          }
        },
        { label: 'Card Name', jsonKey: 'card_identifiers' },
    ];

    getDrawerLabel(identifier: string, time_period: string): string {
      const card_identifier = identifier.replace('CC', '').replace(time_period, '').split('.');
      if (card_identifier.length > 0 && card_identifier[0]) {
        return card_identifier[0];
      }
      return '';
    }

    toTitleCase(str: any) {
      if (str && typeof (str) === 'string' && str?.split('_')?.length) {
        const prepostions = new Set(['and', 'at', 'the', 'for', 'to', 'but', 'by', 'of', 'a', 'an', 'or']);
        const listStr: any = str.split('_').join(' ').replace(/\b\w/g, (first: any) => first.toLocaleUpperCase()).split(' ');
        for (let i = 0; i < listStr.length; i++) {
          if (prepostions.has(listStr[i].toLowerCase())) {
            listStr[i] = listStr[i].toLowerCase();
          }
        }
        return listStr.join(' ');
      } else if (str && Array.isArray(str) && str.length) {
        const prepostions = new Set(['and', 'at', 'the', 'for', 'to', 'but', 'by', 'of', 'a', 'an', 'or']);

        for (let i = 0; i < str.length; i++) {
          const listStr: any = str[i].split('_').join(' ').replace(/\b\w/g, (first: any) => first.toLocaleUpperCase()).split(' ');
          if (listStr && listStr.length) {
            for (let j = 0; j < listStr.length; j++) {
              if (prepostions.has(listStr[j].toLowerCase()) && listStr[j]) {
                listStr[j] = listStr[j].toLowerCase();
              }
            }
            if ((/\d/.test(listStr))) {
              const numberList = listStr.filter((item: any) => /\d/.test(item)).join('');
              const stringList = listStr.filter((item: any) => !(/\d/.test(item)));
              str[i] = numberList + ' ' + stringList.join(' ');
            } else {
              str[i] = listStr.join(' ');
            }
          }
        }
        return str;
      } else {
        return str;
      }
    }

    getTileSources(card_identifiers: string[], link_to_image_url: string[]): OpenSeadragonTileSource[] {
        // ({ tileSource } | null | OpenSeadragonTileSource)[]
        if (!card_identifiers || !link_to_image_url || card_identifiers.length !== link_to_image_url.length) {
          return [];
        }

        return card_identifiers
          .map((identifier, index) => {
            if (link_to_image_url[index]) {
              return {
                tileSource: {
                  type: 'image',
                  filename: identifier,
                  url: link_to_image_url[index],
                },
              } as OpenSeadragonTileSource;
            }
            return null;
          })
          .filter((item): item is Exclude<typeof item, null> => item !== null);
    }

    getSchema(): VoyagerProperty[] {
        return this.properties;
    }
}
