import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { DesignComponentsModule } from '@cop/design/components';
import { SessionExpiredComponent } from './pages/session-expired/session-expired.component';
import { SearchRelevancySurveyComponent } from './components/surveys/search-relevancy-survey/search-relevancy-survey.component';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    SessionExpiredComponent,
    SearchRelevancySurveyComponent
  ],
  imports: [
    CommonModule,
    DesignComponentsModule
  ],
  exports: [
    PageNotFoundComponent,
    SessionExpiredComponent,
    SearchRelevancySurveyComponent
  ]
})
export class SystemModule { }
