<div class="cprs-errors">
  <ng-container *ngFor="let error of errors | slice : 0 : 1; let index = index">
    <cd-alert-snackbar class="d-block mb-3" [mode]="'error'" *ngIf="true" (alertClick)="remove()">
      <div title>{{ this.getTitle(error) }}</div>
      <div text>
        {{ this.getMessage(error) }}

        <div *ngIf="this.isInternal">
          <div class="view-more" [class.show-more]="debug">
            <div>{{ error.error | json }}</div>
          </div>

          <cd-button mode="text" (click)="debug = !debug">{{ debug ? 'Show less' : 'Show More' }}</cd-button>
        </div>
      </div>
    </cd-alert-snackbar>
  </ng-container>
</div>
