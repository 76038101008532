import { CprsTableGrid } from '@src/app/cprs/modules/table/models/cprs-table-grid.interface';
import { VoyagerProperty } from '../../../services/transformer.service';
import { VoyagerHelper } from '../voyager.helper';

export class VoyagerDepositInformation extends VoyagerHelper {
    get deposit_information(): VoyagerProperty {
        return {
            label: 'Deposit Information',
            parentKey: 'deposits_list',
            table: new CprsTableGrid(
                'depoits_information_table',
                'Deposits Information',
                [
                    { label: 'Deposit Tracking Number', columnKey: 'deposit_tracking_number', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Dispatch/Custody', columnKey: 'deposit_dispatch_custody', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Box Number', columnKey: 'deposit_box_number', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Oversize Indicator', columnKey: 'deposit_oversize_indicator', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Copy Agreement MPA', columnKey: 'deposit_copy_agreement_mpa', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Format', columnKey: 'deposit_format', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Receipt Date', columnKey: 'deposit_receipt_date', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Physical Location', columnKey: 'deposit_physical_location', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Date Offered to LC', columnKey: 'deposit_date_offered_to_lc', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Employee Barcode Location', columnKey: 'deposit_employee_barcode_location', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Room Code', columnKey: 'deposit_room_code', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Sublocation', columnKey: 'deposit_sub_location', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Retention', columnKey: 'deposit_retention', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit URL', columnKey: 'deposit_url', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Copy Number', columnKey: 'deposit_copy_number', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Copy ISBN', columnKey: 'isbn_deposit_copy', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Enumeration', columnKey: 'deposit_enumeration', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                    { label: 'Deposit Chronology', columnKey: 'deposit_chronology', visibility: { grid: true, table: true, visible: true }, visible: true, sortable: true },
                ]
            ),
            tags: ['internal']
        }
    }

    get deposit_employee_barcode_location(): VoyagerProperty {
        return this.createVoyagerProperty('Deposit Employee Barcode Location', 'deposits_list', ['deposit_employee_barcode_location']);
    }
}
