import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CprsTableGrid } from '../../modules/table/models/cprs-table-grid.interface';
import { NameDirectoryRequest } from '@src/app/cprs/modules/shared/models/name-directory-request.model';
import { CdSearchService } from '../../services/search.service';
import { NameDirectoryResult } from '@src/app/cprs/modules/shared/models/name-directory-response.model';
import { Page, PageImpl } from '@cop/design/services';
import { BehaviorSubject } from 'rxjs';
import { CprsService } from '../../services/cprs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ParametersService } from '../../services/parameters.service';

// https://github.com/angular/angular/issues/10280
const encode = encodeURIComponent;
window['encodeURIComponent'] = (component: string) => {
  return encode(component).replace(/[!'()*]/g, (c) => {
    // Also encode !, ', (, ), and *
    return '%' + (c.codePointAt(0) || 0).toString(16);
  });
}

@Component({
  selector: 'app-name-directory-page',
  templateUrl: './name-directory-page.component.html',
  styleUrls: ['./name-directory-page.component.scss'],
})
export class NameDirectoryPageComponent implements OnInit {
  public search2Form = new UntypedFormGroup({
    keywords: new UntypedFormControl([]),
    query: new UntypedFormControl(''),
  });

  public nameDirectoryTable = new CprsTableGrid('nameDirectoryTable', 'List of Names', [
    {
      label: 'Records',
      columnKey: 'records',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
    {
      label: 'Name',
      columnKey: 'name',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
    {
      label: 'Type',
      columnKey: 'type',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
  ]);

  public data: any[] = [];
  public mode: 'grid' | 'table' = 'table';

  public searchPage = new BehaviorSubject(PageImpl.empty());

  public $page: Page<any> = PageImpl.empty();

  public named_page_number = 0;

  public named_starting_position = 1;

  public trackNames: boolean;

  constructor(
    public cprsService: CprsService,
    public breadcrumbService: BreadcrumbService,
    public searchService: CdSearchService,
    public router: Router,
    public route: ActivatedRoute,
    public parameterService: ParametersService
  ) {
    this.$page.size = 25;
    this.$page.number = 0;
    this.$page.numberOfElements = 25;
    this.$page.first = true;
    this.$page.last = false;

    this.route.queryParamMap.subscribe(queryParamMap => {
      const size = Number(queryParamMap.get('records_per_page') ?? 25);
      const number = Number(queryParamMap.get('page_number') ?? 0);

      this.$page.number = number;
      this.$page.size = size;
      this.searchPage.next(this.$page);
    })

    this.searchPage.next(this.$page);
  }

  ngOnInit(): void {
    const urlQuery = this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : null;

    if (urlQuery) {
      this.search2Form.get('query')?.setValue(urlQuery);
      this.jumpToName(urlQuery);
    } else {
      this.initialSearch();
    }

    this.breadcrumbService.breadcrumbs = [{ name: 'Home', link: '/', linkType: 'routerLink' }];
    this.breadcrumbService.currentBreadcrumbTextOnly = 'Name Directory';
  }

  initialSearch() {
    this.cprsService.loading.next(true);
    const request = new NameDirectoryRequest({
      query: '',
      sort_field: 'name',
      sort_order: 'asc',
      page_number: this.$page.number,
      starting_position: 1,
      records_per_page: this.$page.size,
    });

    this.applySearch(request);
  }

  applySearch(request: NameDirectoryRequest, save_position = false) {
    this.searchService.nameDirectory(request).subscribe((resp) => {
      if (save_position) {
        this.named_page_number = resp.metaData.current_page_number;
        this.named_starting_position = resp.metaData.starting_position;
      }
      this.$page.number = resp.metaData.current_page_number - 1;
      this.$page.totalElements = resp.metaData.hit_count;
      this.$page.totalPages = Math.ceil(this.$page.totalElements / this.$page.size);
      this.$page.first = this.$page.number === 0;
      this.$page.last = this.$page.number + 1 === this.$page.totalPages;
      this.$page.isLoaded = true;
      this.searchPage.next(this.$page);
      this.data = resp.results.map((r) => this.toTableData(r));
      this.cprsService.loading.next(false);

      this.parameterService.addQueryParameters({
        page_number: this.$page.number,
        records_per_page: this.$page.size
      })
    });
  }

  /*

  okay, when we search by a name we are given
    - starting_position
    - current_page_number

  when we press the next button
    increase page_number by 1
    starting position needs to increment by size relative of page distance

    now how do we adjust for page size?
  */

  pageChange($page: Page<any>) {
    this.cprsService.loading.next(true);

    const prevSize = this.$page.size;
    this.$page = $page;

    const request = new NameDirectoryRequest({
      query: '',
      sort_field: 'name',
      sort_order: 'asc',
      page_number: this.$page.number,
      records_per_page: this.$page.size,
    });
    request.starting_position = this.$page.number * this.$page.size + 1;

    if (this.trackNames && $page.size === this.$page.size) {
      const pageDiff = this.$page.number + 1 - this.named_page_number;
      const startingDiff = this.named_starting_position + pageDiff * this.$page.size;
      request.starting_position = startingDiff;
    }

    if (this.$page.number === 0 && request.starting_position > 1) {
      request.starting_position = 1;
    }

    if (request.starting_position > this.$page.totalElements) {
      request.starting_position = 1;
    }

    const hasQuery = this.search2Form.get('query')?.value;
    if (hasQuery && $page.size !== prevSize) {
      request.starting_position = 1;
      request.query = hasQuery;
      this.applySearch(request, true);
      return;
    }

    this.parameterService.addQueryParameters({
      page_number: this.$page.number,
      records_per_page: this.$page.size
    })

    this.parameterService.addQueryParameters({
      page_number: this.$page.number,
      records_per_page: this.$page.size
    })

    this.applySearch(request);
    // this.jumpToPage();
  }

  search() {
    const name = this.search2Form.get('query')?.value;
    this.jumpToName(name);
  }

  jumpToName(query: string) {
    this.cprsService.loading.next(true);
    const request = new NameDirectoryRequest({
      query,
      sort_field: 'name',
      sort_order: 'asc',
      page_number: 0,
      records_per_page: this.$page.size,
    });

    this.parameterService.addQueryParameters({
      page_number: this.$page.number,
      records_per_page: this.$page.size
    })

    this.trackNames = true;
    this.router.navigate([`/name-directory/${request.query}`], { relativeTo: this.route }).then();

    this.parameterService.addQueryParameters({
      page_number: this.$page.number,
      records_per_page: this.$page.size
    });

    this.applySearch(request, true);
  }

  jumpToPage(_query?: string) {
    this.cprsService.loading.next(true);

    const request = new NameDirectoryRequest({
      query: '',
      sort_field: 'name',
      sort_order: 'asc',
      page_number: this.$page.number,
      records_per_page: this.$page.size,
    });
    this.applySearch(request);
  }

  toTableData(ndr: NameDirectoryResult) {
    return {
      records: {
        value: ndr.number_of_records,
        url: `/name-directory/${encodeURIComponent(ndr.name)}/associated-records`,
      },
      name: {
        value: `${ndr.name} ${ndr.date ? ndr.date : ''}`,
        url: `/name-directory/${encodeURIComponent(ndr.name)}/associated-records`,
      },
      type: {
        value: ndr.name_type,
      },
    };
  }
}
