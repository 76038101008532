<form id="cprs-record-type-selection" class="container" [formGroup]="formGroup">
  <div class="row">
    <div class="d-flex col me-4">
      <cd-input-crs
        [label]="'All records'"
        [errorMessage]="'Error state'"
        [formControl]="all_records"
        (change)="allRecords()"
      ></cd-input-crs>
    </div>

    <ng-container *ngFor="let group of formArray.controls">
      <div class="col me-4">
        <cd-input-crs
          [label]="group.get('label')?.value ?? ''"
          [errorMessage]="'Error state'"
          [formControl]="$any(group.get('selected'))"
          (change)="change()"
        ></cd-input-crs>
      </div>
    </ng-container>
  </div>
</form>
