import { Component, OnDestroy, OnInit } from '@angular/core';
import { Content } from '@src/app/cprs/shared/interfaces/system-announcements';
import { SystemAnnouncementsService } from '@src/app/cprs/modules/system/services/system-announcements.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-system-announcements',
  templateUrl: './system-announcements.component.html',
  styleUrls: ['./system-announcements.component.scss'],
})
export class SystemAnnouncementsComponent implements OnDestroy, OnInit {
  subscriptions: Subscription[] = [];
  public announcements: Content[] = [];

  constructor(private announcementsService: SystemAnnouncementsService) {}

  ngOnInit() {
    this.announcementsService.getSystemAnnouncements().subscribe((resp) => {
      const announcementsString: any | null = localStorage.getItem('announcements');
      if (!announcementsString) {
        localStorage.setItem('announcements', JSON.stringify([]));
      }

      const announcements: Content[] | null = JSON.parse(announcementsString);
      if (resp && resp.content) {
        const mappedAnnouncements = resp?.content.map((c) => {
          const inLocalStorage = !!announcements?.find((ann) => ann.systemAnnouncementId === c.systemAnnouncementId);

          if (!inLocalStorage) {
            c.hasClosed = false;

            announcements?.push(c);
            localStorage.setItem('announcements', JSON.stringify(announcements));
          }

          return c;
        });

        this.announcements = mappedAnnouncements.filter((c) => c.hasClosed === false);
      }
    });
  }

  closeSnackbar(announcement: Content) {
    const foundAnnouncement = this.announcements.find(
      (ann) => ann.systemAnnouncementId === announcement.systemAnnouncementId
    );
    if (foundAnnouncement) {
      foundAnnouncement.hasClosed = true;
      localStorage.setItem('announcements', JSON.stringify(this.announcements));
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }
}
