import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ButtonMenuComponent } from '@cop/design/components';
import { DialogService } from '@src/app/cprs/modules/dialogs/services/dialog.service';
import { CprsDialogComponent } from '@src/app/cprs/modules/dialogs/components/cprs-dialog/cprs-dialog.component';
import { DownloadDialogComponent } from '@src/app/cprs/modules/dialogs/components/download-dialog/download-dialog.component';
import { CdSelectionService } from '../../../selection/services/selection.service';
import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { FeatureFlagService } from '@src/app/cprs/modules/shared/services/feature-flag/feature-flag.service';
import { SavedFeatureService } from '../../../saved/services/saved.feature.service';
import { CustomAuthGuardService } from '@src/app/cprs/modules/system/guards/custom-auth-guard.service';

@Component({
  selector: 'cprs-actions-button-menu',
  templateUrl: './actions-button-menu.component.html',
  styleUrls: ['./actions-button-menu.component.scss'],
})
export class ActionsButtonMenuComponent implements OnInit {
  @ViewChild('actionsMenuButton')
  actionsMenuButton!: ButtonMenuComponent;

  @Input() showEmail: boolean = true;

  @Input() key: string = 'search_results';

  public loggedIn: boolean;

  constructor(
    public dialogService: DialogService,
    public cdSelectionService: CdSelectionService,
    public featureFlagService: FeatureFlagService,
    public savedFeatureService: SavedFeatureService,
    public customAuthguard: CustomAuthGuardService,
  ) { }

  ngOnInit(): void {
    this.customAuthguard.loggedIn().subscribe(loggedIn => {
      this.loggedIn = loggedIn;
    });
  }

  email() {
    const records: any[] = this.cdSelectionService.getSelectedByKey(this.key);

    const control_numbers = records.map((r) => {
      if (r instanceof ElasticSearchResult) {
        return r.get('control_number');
      } else {
        return r?.control_number?.value;
      }
    });

    this.dialogService.open(CprsDialogComponent, { data: { title: 'Title', records: control_numbers } });

    this.actionsMenuButton.closeDropdown();
  }

  download() {
    const records: any[] = this.cdSelectionService.getSelectedByKey(this.key);

    const simple_records = records.map((r: any) => {
      if (r instanceof ElasticSearchResult) {
        return r.getSimpleRecord(r);
      } else {
        return r.simple_record.value;
      }
    });

    const control_numbers = records.map((r: any) => {
      if (r instanceof ElasticSearchResult) {
        return r.get('control_number');
      } else {
        return r.control_number.value;
      }
    });

    this.dialogService.open(DownloadDialogComponent, {
      data: { title: 'Title', records: simple_records, control_numbers },
    });

    this.actionsMenuButton.closeDropdown();
  }

  saveRecords() {
    const selected = this.cdSelectionService.getSelectedByKey(this.key);

    const control_numbers: any[] = selected.map(data => {
      if (data instanceof ElasticSearchResult) {
        return data.get('control_number');
      } else {
        return data?.control_number?.value;
      }
    });
    const non_duplicates = new Set(control_numbers.filter(ctrl => !this.savedFeatureService.saved_records.map(esr => esr.data.control_number).includes(ctrl)));

    selected.forEach(item => {
      const isSavable = non_duplicates.has(item?.control_number?.value);
      if (isSavable) {
        item.saving.label = 'Saving';
        item.saving.value = true;
      }
    });

    this.savedFeatureService.saveRecords(control_numbers).subscribe(() => {
      selected.forEach(item => {
        // const isSaveable = item.has(item?.saving?.value);
        const isSaveable = item && item?.saving && item?.saving?.value;
        if (isSaveable) {
          item.saving.value = false;
        }
      });
    });

    this.actionsMenuButton.closeDropdown();
  }
}
