import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, RoutesRecognized } from '@angular/router';
import { environment } from 'src/environments/environment';

import pkg from '../../../package.json';
import { CentralErrorService } from '@app/cprs/services/central.error.service';
import { FeatureFlagService } from '../cprs/modules/shared/services/feature-flag/feature-flag.service';
import { Subscription } from 'rxjs';
import { IdleService } from '../cprs/modules/system/services/idle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public frontend = {
    env: environment.envName,
    version: pkg ? pkg.version : 'N/A',
  };

  public backend = {
    env: '',
    version: '',
  };

  public env = environment;

  public navInfo: ({ label: string; route: string } | null)[] = [
    { label: 'Home', route: '/' },
    { label: 'Recent Records', route: '/recent-records' },
    { label: 'Recent Searches', route: '/recent-searches' },
    { label: 'Help', route: '/help' },
  ];

  public loggedInNavInfo = [
    { label: 'Home', route: '/' },
    { label: 'Saved', route: '/saved' },
    { label: 'Recent', route: '/recent-records' },
    { label: 'Directory', route: '/name-directory' },
    { label: 'Help', route: '/help' },
  ];

  isLoggedIn = false;
  isDisclaimer = false;
  name_directory: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private titleService: Title,
    private router: Router,
    private location: Location,
    public featureFlagService: FeatureFlagService,
    public centralErrorService: CentralErrorService,
    public idleService: IdleService
  ) {
  }

  ngOnInit() {
    this.loadNavigation();
    const subscription = this.featureFlagService.isSavedRecordSearchEnabled().subscribe((enabled) => {
      if (!enabled) {
        this.loggedInNavInfo.splice(1, 1);
      }
    });
    this.subscriptions.push(subscription);

    this.router.events.subscribe((_val) => {
      this.isDisclaimer = this.location.path().includes('disclaimer');
    });

    this.router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        const route = event.state.root.firstChild;
        if (!route) {
          throw new Error("Failed to get route, can't continue");
        }
        if (route.data['heading']) {
          this.titleService.setTitle(route.data['heading']);
        }
      }
    });
  }

  loadNavigation() {
    this.featureFlagService.isNameDirectoryEnabled().subscribe((enabled) => {
      this.navInfo = [
        { label: 'Home', route: '/' },
        { label: 'Recent Records', route: '/recent-records' },
        { label: 'Recent Searches', route: '/recent-searches' },
        enabled ? { label: 'Name Directory', route: '/name-directory' } : null,
        { label: 'Help', route: '/help' },
      ];
    });
  }

  public skipToMain() {
    const thing = document.querySelector('main');
    if (thing) {
      thing.focus();
    } else {
      console.warn('Cannot find main querySelector');
    }
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keypress', ['$event'])
  @HostListener('document:click', ['$event'])
  handleUserActivity() {
    this.idleService.userActivity();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
