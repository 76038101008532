import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StateService } from '@src/app/cprs/state/state.service';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'cprs-module-date-picker',
  templateUrl: './date-picker.component.html',
  //   styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnDestroy {
  subscriptions: Subscription[] = [];

  @Input() showControls = false;

  @Output() apply = new EventEmitter<{ date_type: string; start_date: string; end_date: string }>();
  @Output() clear = new EventEmitter();

  public formGroup!: UntypedFormGroup;

  public date_type_options: any[];

  public start_min_date = new Date('07/04/1776');
  public min_date = new Date('07/04/1776');
  public max_date = new Date();

  public startDate: any;
  public endDate: any;

  constructor(public stateService: StateService, public datePipe: DatePipe, public envService: EnvService) {
    this.formGroup = this.stateService._state.get('date_picker') as UntypedFormGroup;
    this.date_type_options = this.stateService.date_type_options;

    // this.max_date = this.getMinMaxDateObject(new Date());

    // const subscription = this.formGroup.get('start_date')?.valueChanges.subscribe((start_date) => {
    //   // this.min_date = this.getMinMaxDateObject(new Date(start_date));
    // });
    // if (subscription) {
    //   this.subscriptions.push(subscription);
    // }
    if (!this.envService.internal) {
      this.date_type_options = this.date_type_options.filter(
        (o: { text: string; value: string }) => o.value !== 'imprint_date_of_publication_as_year'
      );
    }
  }

  // Custom Date Serializer after (change) to allow for input assistance
  adjustStartDate() {
    this.startDate = this.formGroup.get('start_date')?.value;

    if (this.startDate) {
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.startDate)) {
        return;
      }

      const parts = this.startDate.split(/[-/]/);
      if (parts.length === 1) {
        // Only year entered
        const [firstPart] = parts;

        if (firstPart.length === 4) {
          this.startDate = `${parts[0]}-01-01`;
        }
      } else if (parts.length === 2) {
        const [firstPart, secondPart] = parts;
        if (firstPart.length === 2 && secondPart.length === 2) {
          // Month and Day entered
          const currentYear = new Date().getFullYear();
          this.startDate = `${currentYear}-${firstPart}-${secondPart}`;
        } else if (firstPart.length === 4) {
          // Year is entered first
          this.startDate = `${firstPart}-${secondPart}-01`; // Set to the first day of the month
        } else {
          // Month is entered first
          this.startDate = `${secondPart}-${firstPart}-01`; // Set to the first day of the month
        }
      }

      this.formGroup.get('start_date')?.setValue(this.startDate);
    }
  }

  adjustEndDate() {
    this.endDate = this.formGroup.get('end_date')?.value;

    if (this.endDate) {
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.endDate)) {
        return;
      }

      const parts = this.endDate.split(/[-/]/);
      if (parts.length === 1) {
        // Only year entered
        const [firstPart] = parts;

        if (firstPart.length === 4) {
          this.endDate = `${parts[0]}-12-31`;
        }
      } else if (parts.length === 2) {
        // Year and month entered
        const [firstPart, secondPart] = parts;
        if (firstPart.length === 2 && secondPart.length === 2) {
          // Month and Day entered
          const currentYear = new Date().getFullYear();
          this.endDate = `${currentYear}-${firstPart}-${secondPart}`;
        } else if (firstPart.length === 4) {
          // Year is entered first
          const lastDayOfMonth = new Date(Number.parseInt(firstPart), Number.parseInt(secondPart), 0).getDate();
          this.endDate = `${firstPart}-${secondPart}-${lastDayOfMonth}`;
        } else {
          // Month is entered first
          const lastDayOfMonth = new Date(Number.parseInt(secondPart), Number.parseInt(firstPart), 0).getDate();
          this.endDate = `${secondPart}-${firstPart}-${lastDayOfMonth}`;
        }
      }

      if (new Date(this.endDate) > new Date()) {
        this.endDate = this.datePipe.transform(this.max_date, 'yyyy-MM-dd');
      }

      this.formGroup.get('end_date')?.setValue(this.endDate);
    }
  }

  onApply() {
    const hasStartDateOrEndDate = !!(this.formGroup.get('start_date')?.value || this.formGroup.get('end_date')?.value);
    if (!hasStartDateOrEndDate) {
      this.formGroup.setErrors({ required: true });
      return;
    }
    this.apply.emit(this.formGroup.value);
  }

  onClear() {
    this.formGroup.reset();
    this.formGroup.get('date_type')?.setValue('representative_date');
    this.stateService.pagination.get('page_number')?.setValue(Number(1));
    this.clear.emit();

    // if (this.searchService.lastSearch.type === 'advanced') {
    //   this.searchService.advancedSearch().subscribe();
    // } else {
    //   this.searchService.simpleSearch().subscribe();
    // }
  }

  getMinMaxDateObject(date: Date): { year: number; month: number; day: number } {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // months are zero-indexed, so add 1 to get the correct month number
    const day = date.getDate();
    return { year, month, day };
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }
}
