<!-- <div id="ecs-public-records"> -->
<!-- System Announcements -->

<!-- Header -->
<!-- <app-cds-header
    *ngIf="!isDisclaimer"
    id="copyright-header"
    [navList]="isLoggedIn ? loggedInNavInfo : navInfo"
    [isLoggedIn]="isLoggedIn"
    (skipToMain)="this.skipToMain()"
  ></app-cds-header> -->

<!-- Main -->
<!-- <main
    #main_content
    role="main"
    [attr.ui-version]="this.frontend.version"
    [attr.backend-version]="this.backend.version"
  >
    <router-outlet class="router-outlet"></router-outlet>
  </main> -->

<!-- Footer -->
<!-- <app-footer *ngIf="!isDisclaimer"></app-footer> -->
<!-- </div> -->

<app-system-announcements></app-system-announcements>

<ng-container *ngIf="!isDisclaimer">
  <cprs-page-template></cprs-page-template>
</ng-container>

<ng-container *ngIf="isDisclaimer">
  <router-outlet></router-outlet>
</ng-container>
<!-- <cprs-error #errorDialog></cprs-error> -->
