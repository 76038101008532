import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { StateService } from '../../state/state.service';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';
import { CprsService } from '../../services/cprs.service';

@Component({
  selector: 'cprs-record-type-selection',
  templateUrl: './record-type-selection.component.html',
  styleUrls: ['./record-type-selection.component.scss'],
})
export class RecordTypeSelectionComponent {
  @Output() recordTypeSelected = new EventEmitter();

  public recordTypeSelection: UntypedFormGroup;

  public formGroup: UntypedFormGroup;
  public formArray: UntypedFormArray;

  public all_records = new UntypedFormControl(true);

  public recordTypes = [
    { text: 'Registration', name: 'registration', visible: true },
    { text: 'Recordation', name: 'recordation', visible: true },
    { text: 'Acquisitions', name: 'acquisition', visible: this.envService.internal },
  ];

  constructor(public stateService: StateService, public envService: EnvService, public cprsService: CprsService) {
    this.formGroup = this.stateService.selectionLists;
    this.formArray = this.stateService.selectionLists.get('type_of_record') as UntypedFormArray;

    this.formArray.valueChanges.subscribe((value: { label: string; value: string; selected: boolean }[]) => {
      const anySelected = value.some((v) => v.selected);

      if (anySelected && this.all_records.value) {
        this.all_records.setValue(false);
      }

      if (!anySelected && !this.all_records.value) {
        this.all_records.setValue(true);
      }

      // this.cprsService.search('advanced');
    });
  }

  allRecords() {
    this.all_records.setValue(true);
    this.stateService.pagination.get('page_number')?.setValue(1);
    this.formArray.controls.forEach((group) => group.get('selected')?.setValue(false));
    if (this.cprsService.advancedSearchPreformed) {
      this.cprsService.search('advanced');
    }
  }

  change() {
    this.stateService.pagination.get('page_number')?.setValue(1);
    if (this.cprsService.advancedSearchPreformed) {
      this.cprsService.search('advanced');
    }
  }
}
