import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { RecordLink, VoyagerProperty } from '../../../services/transformer.service';
import { VoyagerHelper } from '../voyager.helper';

export class VoyagerSerialInformation extends VoyagerHelper {
    get serial_key_title(): VoyagerProperty {
        return this.createVoyagerProperty('Serial Key Title', 'serial_key_title_list', [
            'title_key_title',
            'title_key_title_qualifier'
        ]);
    }

    get serial_publication_year(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Serial Publication Year', 'publication_year');
    }

    get publication_date_range(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Publication Date Range', ['date_begin_date', 'date_end_date']);
    }

    get publication_history(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Publication History', 'enumeration_pattern_serials');
    }

    // DOES NOT EXIST
    get former_frequency(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Former Frequency', 'former_frequency');
    }

    get multiple_claimant_note(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Multiple Claimant Note', 'split_claim_note');
    }

    // complete voyager has all these fields except one
    get issues_registered(): VoyagerProperty {
        return this.createVoyagerProperty('Issues Registered', 'issues_registered_list', [
            'serial_issue_volume_and_number',
            'serial_issue_claimant_not_displayed',
            'serial_issue_claimant_displayed',
            'serial_issue_application_author',
            'serial_claimant_displayed', // does not exist
            'serial_issue_note',
            'serial_issue_previous_registration',
            'serial_issue_new_material_included',
            'serial_issue_co_correspondence',
            'serial_issue_creation_date',
            'serial_issue_publication_date',
            'serial_issue_supplementary_registration_note',
            'serial_issue_supplementary_registration_number',
            'serial_issue_registration_date',
            'serial_issue_registration_number',
            'serial_issue_see_also_supplementary_note',
            'serial_issue_see_also_supplementary_registration_number',
            'created_by_login'
        ]);
    }

    get issues_registered_staff(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Issues Registered (Staff View)', 'issues_registered_list');
    }

    get continues(): VoyagerProperty {
        return {
            label: 'Continues',
            value: (record: ElasticSearchResult) => {
                const serial_preceding_title_continues: any[] = record.get('serial_preceding_title_continues') ?? [];

                return serial_preceding_title_continues.map(serial => {
                    return {
                        value: serial,
                        url: record.createAdvancedSearchURL(serial, 'AND', 'titles', 'starts_with')
                    } as RecordLink;
                })
            }
        }
    }

    get continued_by(): VoyagerProperty {
        return {
            label: 'Continued by',
            value: (record: ElasticSearchResult) => {
                const serial_succeeding_title_continued: any[] = record.get('serial_succeeding_title_continued') ?? [];

                return serial_succeeding_title_continued.map(serial => {
                    return {
                        value: serial,
                        url: record.createAdvancedSearchURL(serial, 'AND', 'titles', 'starts_with')
                    } as RecordLink;
                })
            }
        }
    }

    get continues_in_part(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Continues in part', 'serial_preceding_title_continues_in_part');
    }

    get merged_with(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Merged With', 'serial_succeeding_title_merged');
    }

    get absorbed(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Absorbed', 'serial_preceding_title_absorbed');
    }

    get absorbed_by(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Absorbed by', 'serial_succeeding_title_absorbed');
    }

    // DOES NOT EXIST
    get absorbed_in_part(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Absorbed in Part', 'serial_preceding_title_absorbed_in_part');
    }

    get supersedes(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Supersedes', 'serial_preceding_title_supersedes');
    }

    // DOES NOT EXIST
    get superseded_by(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Superseded by', 'serial_succeeding_title_superseded');
    }

    get split_into(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Split into', 'serial_succeeding_title_split');
    }

    get formed_by_the_union_of(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Formed by the union of', 'serial_preceding_title_formed_by_the_union_of');
    }

    get serial_preceding_issn(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Serial Preceding ISSN', 'serial_preceding_issn');
    }

    get serial_succeeding_issn(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Serial Succeeding ISSN', 'serial_succeeding_issn');
    }

    // there are a few other unmapped field in legacy model

    get serial_description_based_on_note(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Serial Description Based On Note', 'serial_description_based_on_note', ['internal']);
    }
}
