import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  getArray(key: string): any[] {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : [];
  }

  setArray(key: string, data: any[]): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  addItemToArray(key: string, item: any): void {
    const storedData = this.getArray(key);
    storedData.push(item);
    this.setArray(key, storedData);
  }

  removeItemFromArray(key: string, item: any): void {
    let storedData = this.getArray(key);
    storedData = storedData.filter((dataItem: any) => dataItem !== item);
    this.setArray(key, storedData);
  }

  removeOutdatedItems(key: string, hours: number): void {
    const storedData = this.getArray(key);
    const currentTime = Date.now();
    const timeLimit = hours * 60 * 60 * 1000; // Convert hours to milliseconds

    const updatedData = storedData.filter((item: any) => {
      const itemTime = new Date(item.timestamp).getTime();
      const elapsedTime = currentTime - itemTime;
      return item.timestamp && elapsedTime <= timeLimit;
    });
    this.setArray(key, updatedData);
  }
}
