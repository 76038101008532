import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StateService } from '../../state/state.service';
import { CprsSearch, CprsService } from '../../services/cprs.service';
import { Router } from '@angular/router';
import { CdSelectionService } from '../../modules/selection/services/selection.service';

@Component({
  selector: 'cprs-search',
  templateUrl: './cprs-search.component.html',
  styleUrls: ['./cprs-search.component.scss'],
})
export class CprsSearchComponent implements OnInit {
  @Input() linkColor: 'primary' | 'secondary' = 'primary';

  searchForm: UntypedFormGroup = this.stateService._state.get('searchForm') as UntypedFormGroup;

  public current_search: CprsSearch;

  readonly basicSearchTypeOptions: { text: string; value: string | null; selected?: boolean }[] =
    this.stateService.basic_search_options;

  constructor(
    public stateService: StateService,
    public cprsService: CprsService,
    public selectionService: CdSelectionService,
    public router: Router
  ) {}

  ngOnInit(): void {
    // cfog: not sure why this isn't serializing from the StateService default value
    if (!this.searchForm.get('keywords')?.value) {
      this.searchForm.get('keywords')?.setValue('keyword');
    }

    this.cprsService.getCurrentSearch().subscribe((current_search) => {
      this.current_search = current_search;
    });
  }

  onSubmit() {
    if (!this.searchForm.get('query')?.value) {
      return;
    }

    if (this.current_search && this.current_search.parameters.query !== this.searchForm.get('query')?.value) {
      this.clearState();
    }

    if (this.searchForm.get('query')?.value) {
      if (this.router.url.includes('/search')) {
        this.cprsService.search();
      } else {
        this.router.navigate(['/search']);
      }
    }
  }

  clearState() {
    this.stateService.pagination.get('page_number')?.setValue(1);
    this.stateService.pagination.get('rows_per_page')?.setValue(10);
    this.stateService.clearSelectionLists();
    this.stateService.date_picker.get('start_date')?.setValue(null);
    this.stateService.date_picker.get('end_date')?.setValue(null);
    this.stateService.date_picker.get('date_type')?.setValue('representative_date');
    this.stateService._state.get('sort_field')?.setValue(null);
    this.stateService._state.get('sort_order')?.setValue('asc');
    this.selectionService.deselectAllByKey('search_results');
    this.selectionService.selection_groups.search_results.get('items')?.setValue([]);

    this.cprsService.$page.number = 0;
    this.cprsService.$page.size = 10;
    this.cprsService.searchPage.next(this.cprsService.$page);
  }
}
