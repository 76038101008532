import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';
import { environment } from '@src/environments/environment';
import { map } from 'rxjs/operators';

export interface RenderDetailForEmailBody {
  records: { searchLink: string; searchTitle: string }[];
  email_address: string;
  template_type: 'detail_record' | 'search_results' | 'saved_or_recent';
  skip_email: boolean;
}

export interface RenderTemplateForDownloadBody {
  records: any[];
  template_type: 'detail_record' | 'search_results' | 'saved_or_recent';
  response_type: 'html' | 'pdf' | 'txt' | 'xml' | 'csv';
}

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private readonly baseUrl = environment.templatingServiceUrl;

  constructor(public http: HttpClient, public envService: EnvService) {
    this.baseUrl = this.envService.templatingServiceUrl;
  }

  render_detail_for_email(body: RenderDetailForEmailBody) {
    const url = `${this.baseUrl}/render_detail_for_email`;

    return this.http.post<any>(url, body).pipe(
      map((response) => {
        // TODO: implement or copy from other Service.
        console.log('RESP', response);
      })
    );
  }

  render_template_for_download(body: RenderTemplateForDownloadBody) {
    const url = `${this.baseUrl}/render_template_for_${body.response_type}`;

    return this.http.post<Blob>(url, body, { responseType: 'blob' as 'json' });
  }
}
