import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HighlightService {
  constructor() {}

  getHighlights(simpleRecord: any, highlights: Object): any[] {
    const highlightsFound: { label: string; value: string }[][] = [];
    for (const key in highlights) {
      if (highlights[key]) {
        const value = highlights[key];

        const propsFound = this.findProperty(value[0], simpleRecord);
        highlightsFound.push(propsFound);
      }
    }

    return highlightsFound;
  }

  getFirstHighlight(simpleRecord: any, highlights: Object) {
    const found = this.getHighlights(simpleRecord, highlights);
    let first: any;
    // array of arrays [{label, value, value_to_find}][]
    if (found && found.length > 0) {
      found.forEach((arr: { label: string; value: any; value_to_find: any }[]) => {
        if (arr.length > 0 && !first) {
          first = arr[0];
          first.value_to_find = first.value_to_find.trim();
        }
      });
    }

    return first;
  }

  findProperty(value: string, simpleRecord: any) {
    // const guessedTerms = value
    //   .match(/<em>(.*?)<\/em>/g)
    //   .join('')
    //   .split('<em>')
    //   .join('')
    //   .split('</em>')
    //   .join(' ');
    const filtered = value.match(/<em>(.*?)<\/em>/g)?.filter(function (va, index, array) {
      return array.indexOf(va) === index;
    });

    // Not sure this default makes sense, but also not sure this isn't dead code altogether.
    let filteredTerms = '';
    if (filtered) {
      filteredTerms = filtered.join('').split('<em>').join('').split('</em>').join(' ');
    }
    value = value.split('<em>').join('');
    value = value.split('</em>').join('');

    // const valueToFind = value;

    const propertiesFound: { label: string; value: string }[] = [];
    simpleRecord.groupings = simpleRecord.sections;
    simpleRecord.groupings.forEach((group: any) => {
      if (group && group['properties'] && group['properties'].length > 0) {
        let properties: { label: string; value: any }[] = group['properties'];
        properties = properties.filter((prop) => prop.label);
        properties.forEach((property) => {
          if (property.value instanceof Array) {
            property.value.forEach((v) => {
              if (v && typeof v === 'string' && v.trim().toLowerCase().includes(filteredTerms.toLowerCase())) {
                property['value_to_find'] = filteredTerms;
                propertiesFound.push(property);
              }
            });
          } else {
            if (
              property.value &&
              typeof property.value === 'string' &&
              property.value.trim().toLowerCase().includes(filteredTerms.toLowerCase())
            ) {
              property['value_to_find'] = filteredTerms;
              propertiesFound.push(property);
            }
          }
        });
      }
    });
    return propertiesFound;
  }
}
