import { Injectable } from '@angular/core';

interface StorageItem<T> {
  value: T;
  expiration: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storageKeyPrefix = 'app_storage_';

  constructor() {}

  setItem<T>(key: string, value: T, expirationMinutes: number | null = null): void {
    const storageKey = this.getPrefixedKey(key);
    const item: StorageItem<T> = {
      value,
      expiration: expirationMinutes === null ? null : Date.now() + expirationMinutes * 60_000,
    };
    localStorage.setItem(storageKey, JSON.stringify(item));
  }

  getItem<T>(key: string): T | null {
    const storageKey = this.getPrefixedKey(key);
    const item = localStorage.getItem(storageKey);
    if (item) {
      const parsedItem: StorageItem<T> = JSON.parse(item);
      if (!parsedItem.expiration || parsedItem.expiration > Date.now()) {
        return parsedItem.value;
      } else {
        localStorage.removeItem(storageKey);
      }
    }
    return null;
  }

  removeItem(key: string): void {
    const storageKey = this.getPrefixedKey(key);
    localStorage.removeItem(storageKey);
  }

  private getPrefixedKey(key: string): string {
    return `${this.storageKeyPrefix}${key}`;
  }
}
