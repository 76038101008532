import { Injectable } from '@angular/core';
import { StateService } from '../state/state.service';
import { CdSearchService } from './search.service';
import { ErrorService } from './error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiParameters, ParametersService } from './parameters.service';
import { ElasticSearchResponse } from '../shared/interfaces/elastic-search.interface';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { RecentFeatureService } from '../modules/recent/services/recent.service';
import { CentralErrorService } from '@app/cprs/services/central.error.service';
import { CdSelectionService } from '../modules/selection/services/selection.service';
import { PageImpl } from '@cop/design/services';
import { DatePipe } from '@angular/common';
import { finalize, tap } from 'rxjs/operators';
import { AssociatedRecordsRequest } from '../modules/shared/models/associated-records-request.model';

export type CprsSearchMetadata = {
  search_term: string;
  field_heading: { text: string; value: any; selected?: any }[];
  filters_added: { label: string; value: any }[];
  number_of_results: number;
};
export class CprsSearch {
  constructor(
    public type: 'simple' | 'advanced' | 'error' = 'simple',
    public parameters: ApiParameters,
    public response: ElasticSearchResponse,
    public url: string,
    public metadata?: CprsSearchMetadata
  ) { }
}
/*

    CPRS should handle search state
    let the search service solely focus on REST handling

*/
@Injectable({
  providedIn: 'root',
})
export class CprsService {
  public search_history: CprsSearch[] = [];

  public loading: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public drvLoading: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  public current_search = new ReplaySubject<CprsSearch>();

  public cprsSearch: CprsSearch;

  public testSubject = new BehaviorSubject<CprsSearch | null>(null);

  public $page = PageImpl.empty();

  public searchPage = new BehaviorSubject(PageImpl.empty());

  public onSearch = new BehaviorSubject<CprsSearch | null>(null);

  public onFacet = new BehaviorSubject<CprsSearch | null>(null);

  public advancedSearchPreformed = false;

  public loading_observables = [
    { key: 'search_results', subject: new BehaviorSubject(false) },
    { key: 'saved_records', subject: new BehaviorSubject(false) },
    { key: 'saved_searches', subject: new BehaviorSubject(false) },
    { key: 'recent_records', subject: new BehaviorSubject(false) },
    { key: 'detailed_record', subject: new BehaviorSubject(false) },
  ];

  public associated_record_search = new ReplaySubject<CprsSearch>();

  public current_subscription: Subscription;
  public currentScreen: string;
  // public recentRecordSearch = new BehaviorSubject<any>(null);
  public recentRecordSearches: any;

  constructor(
    public stateService: StateService,
    public cdSearchService: CdSearchService,
    public parameterService: ParametersService,
    public errorService: ErrorService,
    public recentService: RecentFeatureService,
    public router: Router,
    public centralErrorService: CentralErrorService,
    public selectionService: CdSelectionService,
    public datePipe: DatePipe
  ) {
    this.loading.next(false);

    this.$page.size = 10;
    this.$page.numberOfElements = 10;
    this.searchPage.next(this.$page);
  }

  getLoadingSubject(key: string) {
    return this.loading_observables.find(obs => obs.key === key)?.subject ?? new BehaviorSubject<boolean>(false);
  }

  getCurrentSearchResults() {
    return this.currentScreen;
  }

  setCurrentSearchResults(key: string) {
    this.currentScreen = (this.loading_observables.find(obs => obs.key === key)?.key) || key;
  }

  setLoadingSubject(key: string, value: boolean) {
    const subject = this.getLoadingSubject(key);

    return subject.next(value);
  }

  associatedRecords(id: string): Observable<ElasticSearchResponse> {
    const request = new AssociatedRecordsRequest({});
    request.display_name = id;
    request.page_number = this.$page.number;
    request.records_per_page = 100;
    this.setCurrentSearchResults('associated_records');
    return this.cdSearchService.associatedRecords(request).pipe(
      tap(resp => {
        const search = new CprsSearch('simple', request as any, resp, '');
        this.associated_record_search.next(search);
      })
    )
  }

  facet(type: 'simple' | 'advanced' | 'error' = 'simple') {
    if (type === 'simple' && this.stateService.search_form.valid) {
      this.loading.next(true);
      this.errorService.resetErrors('search');

      const parameters = this.parameterService.simpleSearchParameters();
      const searchMetadata = {
        search_term: this.getQueryString(parameters),
        field_heading: this.getFieldHeading(parameters),
        filters_added: this.getFiltersAdded(),
        number_of_results: 0,
      };
      this.current_subscription = this.cdSearchService.simpleSearch(parameters).subscribe((response) => {
        const search = new CprsSearch(
          type,
          parameters,
          new ElasticSearchResponse(response),
          this.parameterService.serializeQueryToURL(parameters)
        );
        searchMetadata.number_of_results = response.metadata.hit_count;
        search.metadata = searchMetadata;
        this.$page.size = Number(parameters.records_per_page);
        this.$page.number = Number(parameters.page_number) - 1;
        this.$page.totalElements = search.response.metadata.hit_count ?? 0;
        this.$page.totalPages = Math.ceil(this.$page.totalElements / this.$page.size);
        if (this.$page.totalElements <= this.$page.numberOfElements) {
          this.$page.numberOfElements = this.$page.totalElements;
        }
        this.onFacet.next(search);
        this.searchPage.next(this.$page);
        this.testSubject.next(search);
        this.loading.next(false);
        this.current_search.next(search);
        this.cprsSearch = search;
      }, () => {
        const search = this.searchError(parameters);
        this.onFacet.next(search);
        this.$page.totalElements = 0;
        this.$page.isLoaded = true;
        this.searchPage.next(this.$page);
        this.loading.next(false);
      });
    } else {
      this.loading.next(true);
      this.errorService.resetErrors('search');

      const parameters = this.parameterService.advancedSearchParameters();
      const searchMetadata = {
        search_term: this.getQueryString(parameters),
        field_heading: this.getFieldHeading(parameters),
        filters_added: this.getFiltersAdded(),
        number_of_results: 0,
      };
      this.current_subscription = this.cdSearchService.advancedSearch(parameters).subscribe((response) => {
        const search = new CprsSearch(
          type,
          parameters,
          new ElasticSearchResponse(response),
          this.parameterService.serializeQueryToURL(parameters)
        );
        searchMetadata.number_of_results = response.metadata.hit_count;
        search.metadata = searchMetadata;
        this.$page.size = Number(parameters.records_per_page);
        this.$page.number = Number(parameters.page_number) - 1;
        this.$page.totalElements = search.response.metadata.hit_count ?? 0;
        this.$page.totalPages = Math.ceil(this.$page.totalElements / this.$page.size);
        if (this.$page.totalElements <= this.$page.numberOfElements) {
          this.$page.numberOfElements = this.$page.totalElements;
        }
        this.searchPage.next(this.$page);
        this.onFacet.next(search);
        this.testSubject.next(search);
        this.loading.next(false);
        this.current_search.next(search);
        this.cprsSearch = search;
      }, () => {
        const search = this.searchError(parameters);
        this.onFacet.next(search);
        this.$page.totalElements = 0;
        this.$page.isLoaded = true;
        this.searchPage.next(this.$page);
        this.loading.next(false);
      });
    }
  }

  searchError(parameters: ApiParameters): CprsSearch {
    return new CprsSearch(
      'error',
      parameters,
      new ElasticSearchResponse({
        metadata: {
          hit_count: 0,
          histogram: {
            filtered: {},
            unfiltered: {},
          },
        },
        data: [],
      }),
      this.parameterService.serializeQueryToURL(parameters)
    );
  }

  updateTable(search: CprsSearch) {
    this.testSubject.next(search);
    this.$page.totalElements = search.response.metadata.hit_count ?? 0;
    const size = this.stateService.pagination.get('rows_per_page')?.value;
    this.$page.totalPages = Math.ceil(this.$page.totalElements / size);
    this.searchPage.next(this.$page);
  }

  search(type: 'simple' | 'advanced' | 'error' = 'simple'): void {
    // must have a valid search form for a simple search

    if (this.current_subscription) {
      this.current_subscription.unsubscribe();
    }
    if (type === 'simple' && this.stateService.search_form.valid) {
      const parameters = this.parameterService.simpleSearchParameters();
      const searchMetadata = {
        search_term: this.getQueryString(parameters),
        field_heading: this.getFieldHeading(parameters),
        filters_added: this.getFiltersAdded(),
        number_of_results: 0,
      };
      this.loading.next(true);
      this.errorService.resetErrors('search');
      this.current_subscription = this.cdSearchService.simpleSearch(parameters).subscribe(
        (response) => {
          const search = new CprsSearch(
            type,
            parameters,
            new ElasticSearchResponse(response),
            this.parameterService.serializeQueryToURL(parameters)
          );
          searchMetadata.number_of_results = response.metadata.hit_count;
          search.metadata = searchMetadata;
          this.updateTable(search);
          this.recentService.createRecentSearch(search);
          this.current_search.next(search);
          this.search_history.push(search);
          this.loading.next(false);
          this.$page.totalElements = response.metadata.hit_count;
          this.$page.isLoaded = true;
          this.$page.size = Number(parameters.records_per_page);
          this.$page.number = Number(parameters.page_number) - 1;
          if (this.$page.totalElements <= this.$page.numberOfElements) {
            this.$page.numberOfElements = this.$page.totalElements;
          }
          this.searchPage.next(this.$page);
          this.onSearch.next(search);
          this.cprsSearch = search;
        },
        (error: HttpErrorResponse) => {
          this.centralErrorService.addError('Search Service', error);
          const search = this.searchError(parameters);
          this.current_search.next(search);
          this.onSearch.next(search);
          this.$page.totalElements = 0;
          this.$page.isLoaded = true;
          this.searchPage.next(this.$page);
          this.loading.next(false);
        }
      );
    }

    if (type === 'advanced') {
      const parameters = this.parameterService.advancedSearchParameters();
      const searchMetadata = {
        search_term: this.getQueryString(parameters),
        field_heading: this.getFieldHeading(parameters),
        filters_added: this.getFiltersAdded(),
        number_of_results: 0,
      };
      this.loading.next(true);
      this.errorService.resetErrors('search');
      this.advancedSearchPreformed = true;
      this.current_subscription = this.cdSearchService.advancedSearch(parameters).subscribe(
        (response) => {
          const search = new CprsSearch(
            type,
            parameters,
            new ElasticSearchResponse(response),
            this.parameterService.serializeQueryToURL(parameters)
          );

          searchMetadata.number_of_results = response.metadata.hit_count;
          search.metadata = searchMetadata;
          this.updateTable(search);
          this.recentService.createRecentSearch(search);
          this.current_search.next(search);
          this.search_history.push(search);
          this.loading.next(false);
          this.$page.totalElements = response.metadata.hit_count;
          this.$page.isLoaded = true;
          this.$page.size = Number(parameters.records_per_page);
          this.$page.number = Number(parameters.page_number) - 1;
          if (this.$page.totalElements <= this.$page.numberOfElements) {
            this.$page.numberOfElements = this.$page.totalElements;
          }
          this.searchPage.next(this.$page);
          this.onSearch.next(search);
          this.cprsSearch = search;
        },
        () => {
          const search = this.searchError(parameters);
          this.current_search.next(search);
          this.onSearch.next(search);
          this.$page.totalElements = 0;
          this.$page.isLoaded = true;
          this.searchPage.next(this.$page);
          this.loading.next(false);
        }
      );
    }
    // this.setCurrentSearchResults('search_results');
  }

  createCprsSearch(type: 'simple' | 'advanced' | 'error' = 'simple', parameters: any, response: ElasticSearchResponse) {
    const urlPrefix = type === 'simple' ? '/search' : '/advanced-search';
    return new CprsSearch(
      type,
      parameters,
      response,
      this.parameterService.serializeQueryToURL(parameters, false, urlPrefix)
    )
  }

  getFiltersAdded() {
    const filters_added: any[] = [];

    const collection = {
      system_of_origin: this.stateService.getSelectionWithLabels('system_of_origin'),
      type_of_record: this.stateService.getSelectionWithLabels('type_of_record'),
      registration_status: this.stateService.getSelectionWithLabels('registration_status'),
      registration_class: this.stateService.getSelectionWithLabels('registration_class'),
      type_of_work: this.stateService.getSelectionWithLabels('type_of_work'),
      recordation_item_type: this.stateService.getSelectionWithLabels('recordation_item_type'),
      type_of_acquisition: this.stateService.getSelectionWithLabels('type_of_acquisition'),
    };

    Object.keys(collection).forEach((k) => {
      const selection: { label: string; value: string }[] = collection[k];

      selection.forEach((o) => {
        filters_added.push(o);
      });
    });

    return filters_added;
  }

  getFieldHeading(parameters: ApiParameters): { text: string; value: any; selected?: any }[] {
    if (parameters.parent_query) {
      const foundSearchTypes = new Set(parameters.parent_query.map((q) => q.column_name));
      return this.stateService.mega_mini_menu_array.filter((q) => foundSearchTypes.has(q.value));
    }

    if (parameters.query && parameters.field_type) {
      return this.stateService.basic_search_options.filter((c) => c.value === parameters.field_type);
    }

    return [];
  }

  getQueryString(parameters: ApiParameters) {
    if (parameters.query) {
      return parameters.query;
    } else if (parameters.parent_query) {
      if (Array.isArray(parameters.parent_query)) {
        return parameters.parent_query.map((q) => q['query']).join(', ');
      }
      return parameters.parent_query;
    }

    return '';
  }

  getDetailedRecord(control_number: any) {
    this.loading.next(true);
    return this.cdSearchService.searchUniqueRecord(control_number).pipe(
      finalize(() => {
        this.loading.next(false);
      })
    );
  }

  getCurrentSearch(): ReplaySubject<CprsSearch> {
    return this.current_search;
  }

  clearState() {
    this.stateService.search_form.get('keywords')?.setValue('keyword');
    this.stateService.search_form.get('query')?.setValue('');
    this.stateService.pagination.get('page_number')?.setValue(1);
    this.stateService.pagination.get('rows_per_page')?.setValue(10);
    this.stateService.clearSelectionLists();
    this.stateService.date_picker.get('start_date')?.setValue(null);
    this.stateService.date_picker.get('end_date')?.setValue(null);
    this.stateService.date_picker.get('date_type')?.setValue('representative_date');
    this.stateService._state.get('sort_field')?.setValue(null);
    this.stateService._state.get('sort_order')?.setValue('asc');
    this.selectionService.deselectAllByKey('search_results');
    this.selectionService.selection_groups.search_results.get('items')?.setValue([]);

    this.$page.number = 0;
    this.$page.size = 10;
    this.searchPage.next(this.$page);
  }

  toTitleCase(str: any) {
    if (str && typeof (str) === 'string' && str?.split('_')?.length) {
      const prepostions = new Set(['and', 'at', 'the', 'for', 'to', 'but', 'by', 'of', 'a', 'an', 'or']);
      const listStr: any = str.split('_').join(' ').replace(/\b\w/g, (first: any) => first.toLocaleUpperCase()).split(' ');
      for (let i = 0; i < listStr.length; i++) {
        if (prepostions.has(listStr[i].toLowerCase())) {
          listStr[i] = listStr[i].toLowerCase();
        }
      }
      return listStr.join(' ');
    } else if (str && Array.isArray(str) && str.length) {
      const prepostions = new Set(['and', 'at', 'the', 'for', 'to', 'but', 'by', 'of', 'a', 'an', 'or']);

      for (let i = 0; i < str.length; i++) {
        const listStr: any = str[i].split('_').join(' ').replace(/\b\w/g, (first: any) => first.toLocaleUpperCase()).split(' ');
        if (listStr && listStr.length) {
          for (let j = 0; j < listStr.length; j++) {
            if (prepostions.has(listStr[j].toLowerCase()) && listStr[j]) {
              listStr[j] = listStr[j].toLowerCase();
            }
          }
          if ((/\d/.test(listStr))) {
            const numberList = listStr.filter((item: any) => /\d/.test(item)).join('');
            const stringList = listStr.filter((item: any) => !(/\d/.test(item)));
            str[i] = numberList + ' ' + stringList.join(' ');
          } else {
            str[i] = listStr.join(' ');
          }
        }
      }
      return str;
    } else {
      return str;
    }
  }

  setRecentRecordSearch(search: any) {
    this.recentRecordSearches = search;
  }

  getRecentRecordSearch() {
    return this.recentRecordSearches;
  }
}
