<form [formGroup]="formGroup" novalidate>
  <div class="advanced-search-row row">
    <div *ngIf="this.showOperator" class="col-sm-4 col-md-2 px-3">
      <cd-select
        [label]="'Operator'"
        formControlName="operator_type"
        [options]="operator_options"
        [errorMessage]="'Error State'"
      ></cd-select>
    </div>
    <div class="col-sm-4 col-md-2 px-3">
      <!-- <cd-select
                [label]="'Field Heading'"
                formControlName="column_name"
                [options]="field_heading_options"
                [errorMessage]="'Error State'"
            ></cd-select> -->
      <cprs-mega-mini-menu
        [formGroup]="this.formGroup"
        [(selected)]="mmmSelection"
        (onSelect)="mmm($event)"
      ></cprs-mega-mini-menu>
    </div>
    <div class="col-sm-4 col-md-2 px-3">
      <cd-select
        [label]="'Search Type'"
        formControlName="type_of_query"
        [options]="search_type_options"
        [errorMessage]="'Error State'"
      ></cd-select>
    </div>
    <div class="col-sm-6 col-md-4 px-3">
      <cd-input
        label="&nbsp;"
        [errorMessage]="'Search term is required'"
        formControlName="query"
        (keyup.enter)="onSearch()"
      ></cd-input>
    </div>
    <div *ngIf="this.showButtons" class="col-sm-4 col-md-2 px-3 mt-4" style="width: fit-content">
      <cd-button-group>
        <cd-button [mode]="'primary'" class="pe-3" (btnClick)="onSearch()">Search</cd-button>
        <cd-button [mode]="'secondary'" (btnClick)="onClear()">Clear All</cd-button>
      </cd-button-group>
    </div>
  </div>
</form>
