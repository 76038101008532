import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { VoyagerProperty } from '../../../services/transformer.service';
import { VoyagerHelper } from '../voyager.helper';
import { HyperlinkSchema } from '@src/app/cprs/shared/interfaces/types/types';

export class VoyagerNotes extends VoyagerHelper {
    get copyright_note(): VoyagerProperty {
        return {
            label: 'Copyright Note',
            value: (record: ElasticSearchResult) => {
                const copyright_note: any[] = record.get('copyright_note');
                const copyright_note_reg_hyperlinks: HyperlinkSchema[] = record.get('copyright_note_registration_hyperlinks');
                const copyright_note_rec_hyperlinks: HyperlinkSchema[] = record.get('copyright_note_recordation_hyperlinks');

                const links: { id: string, token: string }[] = [];

                copyright_note_reg_hyperlinks?.forEach(hyperlinks => {
                    // Merging the arrays into an array of objects
                    const minLength = Math.min(hyperlinks.ids.length, hyperlinks.tokens.length);

                    for (let i = 0; i < minLength; i++) {
                        links.push({ id: hyperlinks.ids[i] ?? '', token: hyperlinks.tokens[i] ?? '' });
                    }
                })

                copyright_note_rec_hyperlinks?.forEach(hyperlinks => {
                    // Merging the arrays into an array of objects
                    const minLength = Math.min(hyperlinks.ids.length, hyperlinks.tokens.length);

                    for (let i = 0; i < minLength; i++) {
                        links.push({ id: hyperlinks.ids[i] ?? '', token: hyperlinks.tokens[i] ?? '' });
                    }
                })

                links.sort((a, b) => {
                    return a.token.length - b.token.length;
                })

                const new_notes: any[] = copyright_note.map(note => note);
                new_notes.forEach((note, noteIndex) => {
                    links.forEach(link => {
                        if (note.includes(link.token)) {
                            const url = record.createAdvancedSearchURL(link.id, 'AND', 'registration_numbers', 'exact')
                            const replaced = note.replace(link.token, `<a href="${url}">${link.id}</a>`);

                            new_notes[noteIndex] = replaced;
                        }
                    })
                })

                return new_notes;
            },
            tags: ['html']
        }
    }

    get copyright_note_to_staff(): VoyagerProperty {
        return this.createHTMLHyperlinks('Copyright Note to Staff', 'message_note_to_staff', ['message_note_to_staff_registration_hyperlinks', 'message_note_to_staff_recordation_hyperlinks']);
    }

    get cancellation_note(): VoyagerProperty {
        return {
            label: 'Cancellation Note',
            value: (record: ElasticSearchResult) => {
                const cancellation_note: any[] = record.get('cancellation_note');
                const cancellation_note_hyperlinks: HyperlinkSchema[] = record.get('cancellation_note_registration_hyperlinks');

                const links: { id: string, token: string }[] = [];

                cancellation_note_hyperlinks?.forEach(hyperlinks => {
                    // Merging the arrays into an array of objects
                    const minLength = Math.min(hyperlinks.ids.length, hyperlinks.tokens.length);

                    for (let i = 0; i < minLength; i++) {
                        links.push({ id: hyperlinks.ids[i] ?? '', token: hyperlinks.tokens[i] ?? '' });
                    }
                })

                links.sort((a, b) => {
                    return a.token.length - b.token.length;
                })

                cancellation_note?.forEach((note: string, index: number) => {
                    if (links) {
                        links.forEach(link => {
                            if (note.includes(link.token)) {
                                const replaceStr = '<a href="' + record.createAdvancedSearchURL(link.id, 'AND', 'registration_numbers', 'exact') + '">' + link.token + '</a>';
                                const replaced = note.replace(link.token, replaceStr);

                                cancellation_note[index] = replaced;
                            }
                        })
                    }
                })

                return cancellation_note;
            },
            tags: ['html']
        }
    }

    get terms_of_use(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Terms of Use', 'terms_of_use');
    }

    get notes(): VoyagerProperty {
        return this.createHTMLHyperlinks('Notes', 'general_note', ['general_note_registration_hyperlinks', 'general_note_recordation_hyperlinks']);
    }

    get area_covered(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Area Covered', 'name_geographic_name');
    }

    get date_of_first_publication_album(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Date of First Publication for the Album', 'date_of_first_publication_album');
    }

    get publication_nation(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Nation of First Publication', 'publication_nation');
    }

    get catalog_of_copyright_entries_part_codes(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Catalog of Copyright Entries Part Codes', 'catalog_of_copyright_entries_part_codes', ['internal']);
    }

    get monograph_type(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Monograph Type', 'monograph_type', ['internal']);
    }

    get selection_notes(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Selection Notes', [
            'selection_conditions',
            'selection_custodial_division',
            'selection_decision',
            'special_processing_instructions',
            'selection_comments',
            'selection_decision_responsibility',
            'selection_number_of_copies'
        ], ['internal']);
    }

    get selection_custodial_division(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Selection Custodial Division', [
            'selection_custodial_division',
        ]);
    }

    get selection_decision(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Selection Decision', [
            'selection_decision',
        ]);
    }

    get regularity_for_serials(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Regularity for Serials', 'enumeration_pattern_serials', ['internal']);
    }

    get record_created_by(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Record Created by', 'record_created_by', ['internal', 'no-export']);
    }
}
