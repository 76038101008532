<!-- <cprs-table
  [id]="table.id"
  [caption]="table.caption"
  [columns]="table.columns"
  [columnFiltering]="false"
  [data]="data"
  [pageType]="'client'"
  [selectionForm]="'saved_searches'"
  (selectionChange)="selectionChange($event)"
>
  <div left>
    <cd-button-menu title="Actions" [placement]="'bottom-start'" #actionsMenuButton>
      <li (cdMenuTextButton)="email()">Email</li>
      <li (cdMenuTextButton)="clearSearch()">Clear searches</li>
    </cd-button-menu>
  </div>

  <div no-content>Once you have performed a search, your searches from this session will be available here.</div>
</cprs-table>

<div class="my-4">* This list is limited to your 100 most saved searches.</div> -->
<div *appLoading="'saved_searches'">
  <cprs-table-grid
    [interface]="table"
    [mode]="mode"
    [selectionKey]="'saved_searches'"
    [content]="data"
    [totalElements]="totalElements"
    [showPagingDisplay]="true"
  >
    <div header-left>
      <cd-button-menu title="Actions" [placement]="'bottom-start'" #actionsMenuButton>
        <li (cdMenuTextButton)="email()">Email</li>
        <li (cdMenuTextButton)="download()">Download</li>
        <li (cdMenuTextButton)="clear()">Clear searches</li>
      </cd-button-menu>
    </div>

    <div no-content-table>Once you have saved a search, your saved searches will be available here.</div>
  </cprs-table-grid>
</div>

<div class="my-4">* This list is limited to 100 saved searches.</div>
