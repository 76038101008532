<cd-button-menu
    [title]="sort_by_label"
    [btnClass]="'me-2'"
    [placement]="'bottom-start'"
    #dropdownMenuTextButton
>
    <li *ngFor="let option of sort_field_options" (cdMenuTextButton)="sortBy(option)">
    {{ option.text }}
    </li>
</cd-button-menu>
