import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'cprs-module-chip-list',
  templateUrl: './chip-list.component.html',
  // styleUrls: ['./selection-list.component.scss']
})
export class ChipListComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() formArray: UntypedFormArray = new UntypedFormArray([]);

  @Output() remove: EventEmitter<any> = new EventEmitter();

  public chips: { label: string; value: string; selected?: boolean }[] = [];

  ngOnInit() {
    if (this.formArray && this.formArray.value instanceof Array) {
      this.chips = this.formArray.value.filter((g) => g.selected);

      const subscription = this.formArray.valueChanges.subscribe(
        (groups: { label: string; value: string; selected: boolean }[]) => {
          this.chips = groups.filter((g) => g.selected);
        }
      );
      this.subscriptions.push(subscription);
    }

    if (this.formGroup && !this.formArray) {
      this.chips = Object.keys(this.formGroup.controls).map((ctrl) => {
        const control = this.formGroup.get(ctrl);
        if (!control) {
          throw new Error('Cannot find ctrl in formGroup');
        }
        return { label: `${ctrl}: ${control.value}`, value: ctrl };
      });

      const subscription = this.formGroup.valueChanges.subscribe((controls) => {
        this.chips = Object.keys(controls).map((ctrl) => {
          const control = this.formGroup.get(ctrl);
          if (!control) {
            throw new Error('Cannot find ctrl in formGroup');
          }
          return { label: `${ctrl}: ${control.value}`, value: ctrl };
        });
      });
      this.subscriptions.push(subscription);
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }

  removed(chip: { label: string; value: string; selected?: boolean }) {
    this.chips = this.chips.filter((c) => c !== chip);

    if (this.formArray) {
      const control = this.formArray.controls.find((group) => group.get('value')?.value === chip.value);
      if (control) {
        control.get('selected')?.setValue(false);
      }
    }

    if (this.formGroup) {
      const control = this.formGroup.get(chip.value);
      if (control) {
        control.setValue(null);
      }
    }

    this.remove.emit(chip);
  }
}
