import { Component, Input } from '@angular/core';
import { CprsTableGrid } from '../../../table/models/cprs-table-grid.interface';

export type RecordProperty = {
  label: string;
  value: any;
  table?: CprsTableGrid;
  tags?: string[]
}

@Component({
  selector: 'cd-record',
  templateUrl: './cd-record.component.html',
  styleUrls: ['./cd-record.component.scss'],
})
export class CdRecordComponent {
  @Input() schema: RecordProperty[] = [];

  constructor(
  ) { }

  isVisible(property: RecordProperty): boolean {
    return property.value;
  }

  hasTag(property: RecordProperty, tag: string): boolean {
    return property.tags?.includes(tag) ?? false;
  }

  isArray(property: RecordProperty): boolean {
    return Array.isArray(property.value);
  }

  isNotEmpty(proprety: RecordProperty): boolean {
    return proprety.value.length > 0;
  }

  isObject(property: RecordProperty): boolean {
    return typeof property.value === 'object';
  }

  isLink(property: RecordProperty): boolean {
    return property.value && property.value?.value && property.value?.url;
  }

  isLinkValue(value: any): boolean {
    return value && Object.prototype.hasOwnProperty.call(value, 'url') && Object.prototype.hasOwnProperty.call(value, 'value');
  }

  isTable(property: RecordProperty): boolean {
    return !!property.table;
  }

  isInternal(property: RecordProperty): boolean {
    return property.tags?.includes('internal') ?? false;
  }

  getDataColumns(table: CprsTableGrid) {
    return table.columns.map(col => {
      return { label: col.label, key: col.columnKey };
    })
  }
}
