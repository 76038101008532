<!-- <ng-container *ngIf="!this.hidden">
  <div *appLoading class="survey">
    <div class="survey-header">
      <div class="display-4">How relevant are these search results?</div>

      <fa-icon (click)="dismiss()" class="fa" [icon]="faTimesCircle"></fa-icon>
    </div>

    <div class="survey-body">
      <ng-container *ngFor="let option of this.surveyOptions">
        <div class="survey-option" (click)="submit()">
          <div class="survey-circle">
            {{ option.value }}
          </div>
          <div class="survey-circle-label">
            {{ option.label }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container> -->

<!--
  SurveyMonkey won't allow us to POST to the API to log search relevancy submission unless its coming from their domain
-->
<div class="my-4 col-8">
  <iframe
    title="search-relevancy-survey"
    class="survey-monkey-form"
    scrolling="no"
    width="100%"
    src="https://www.surveymonkey.com/r/BHTBBL2"
  ></iframe>
</div>
<!-- height="550px" -->
