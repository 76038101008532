import { Injectable } from '@angular/core';
import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { VoyagerRegistration } from '../schemas/voyager/types/registration.voyager';
import { VoyagerRecordation } from '../schemas/voyager/types/recordation.voyager';
import { VoyagerAcquisition } from '../schemas/voyager/types/acquisition.voyager';
import { CprsTableGrid } from '../../table/models/cprs-table-grid.interface';

export type VoyagerProperty = {
    label: string | Function;
    value?: string | Function;
    jsonKey?: string | string[];
    parentKey?: string;
    subfields?: string[];
    order?: number;
    tags?: string[];
    table?: CprsTableGrid;
}

export type RecordLink = {
    value: string;
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class TransformerService {
    public record: ElasticSearchResult;

    createType(record: ElasticSearchResult, type: string) {
        if (type === 'recordation') {
            return this.create(record, new VoyagerRecordation().schema);
        } else if (type === 'acquisition') {
            return this.create(record, new VoyagerAcquisition().schema);
        } else {
            return this.create(record, new VoyagerRegistration().schema);
        }
    }

    // createSchema(record: ElasticSearchResult, type: string, subtype?: string) {

    // }

    create(record: ElasticSearchResult, properties: VoyagerProperty[]) {
        const schema: any[] = [];
        properties.forEach(property => {
            if (property.value) {
                const viewable = this.exec(record, property.label, property.value);
                viewable.tags = property.tags;

                if (property.table) {
                    viewable.table = property.table;
                }

                schema.push(viewable);
            }

            // strictly jsonKeys
            if (!property.value && property.jsonKey) {
                // combine properties if array
                if (Array.isArray(property.jsonKey)) {
                    // eslint-disable-next-line unicorn/prefer-array-flat
                    const combined: any[] = Array.prototype.concat.apply([], this.combine(record, property.jsonKey));

                    schema.push({
                        label: property.label,
                        value: combined,
                        tags: property.tags,
                    })
                } else {
                    schema.push({
                        label: property.label,
                        value: record.get(property.jsonKey),
                        tags: property.tags,
                    });
                }
            }

            // more complex
            if (!property.value && property.parentKey && property.subfields) {
                const parentObj = record.get(property.parentKey) as any[];
                const values = this.combineObjectArray(parentObj, property.subfields);

                schema.push({
                    label: property.label,
                    value: values,
                    tags: property.tags,
                })
            }

            // table properties
            if (!property.value && property.parentKey && property.table) {
                let parentObj: any[] = record.get(property.parentKey) ?? [];
                const tableData: any[] = [];

                if (!parentObj || !Array.isArray(parentObj)) {
                    parentObj = [];
                }

                parentObj.forEach(obj => {
                    const keys = Object.keys(obj);
                    const data = {};
                    keys.forEach(key => {
                        data[key] = obj[key];
                    })

                    tableData.push(data);
                })
                schema.push({
                    label: property.label,
                    value: tableData,
                    table: property.table,
                    tags: property.tags
                });
            }
        });

        return schema;
    }

    combine(record: ElasticSearchResult, keys: string[]): any[] {
       return keys.map(key => record.get(key)).filter(Boolean);
    }

    combineObject(object: any, keys: string[]) {
        return keys.map(key => object[key]).filter(v => v && v.length >= 1);
    }

    combineObjectArray(objectArray: any[], keys: string[]): any[] {
        if (!objectArray || !Array.isArray(objectArray)) {
            return [];
        }
        return objectArray.map(object => {
            return this.combineObject(object, keys).join(' ');
        }).filter(v => v && v.length >= 1);
    }

    exec(record: ElasticSearchResult, label: string | Function, value: string | Function) {
        const toReturn: any = {
            label: null,
            value: null
        };

        if (typeof label === 'function') {
            try {
                toReturn.label = label(record);
            } catch {
                toReturn.label = null;
            }
        } else {
            toReturn.label = label;
        }

        if (typeof value === 'function') {
            try {
                toReturn.value = value(record);
            } catch {
                toReturn.value = null;
            }
        } else {
            toReturn.value = value;
        }

        return toReturn;
    }

    return(label: string, value: string) {
        return { label, value }
    }

    get(property: string) {
        return this.record[property];
    }
}

export enum VoyagerProperties {
    copyright_number = 'copyright_number',
    service_request_number = 'service_request_number',

}
