<section class="share-container">
  <div
    aria-labelledby="button-share"
    class="dropdown-wrapper dropdown-style-small dropdown-style-inline share"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
  >
    <cd-button class="pe-2" [mode]="'text'" size="small" (click)="toggleShow()">
      Share
      <cd-icon [cdsIcon]="'share'"></cd-icon>
    </cd-button>
    <!-- <button
      aria-controls="dropdown-share"
      [attr.aria-expanded]="toggleShare"
      class="dropdown-button dropdown-button-unstyled"
      id="button-share"
      type="button"
      (click)="toggleShow()"
    >
      Share
      <cd-icon [classes]="'text-primary'" [cdsIcon]="'share'"></cd-icon>
    </button> -->
    <ul
      aria-labelledby="button-share"
      class="dropdown-list dropdown-forced-right dropdown-arrow"
      id="dropdown-share"
      *ngIf="toggleShare"
    >
      <li class="facebook-link">
        <a
          class="share-link share-link-facebook"
          href="https://www.facebook.com/sharer/sharer.php?u={{ shareFacebook }}"
          rel="noopener"
          target="_blank"
        >
          <img alt="Link to Facebook" src="assets/images/facebook.svg" />
        </a>
      </li>
      <li class="twitter-link">
        <a
          class="share-link share-link-twitter"
          href="https://twitter.com/intent/tweet?text={{ shareTwitter }}"
          rel="noopener"
          target="_blank"
        >
          <img alt="Link to Twitter" src="assets/images/twitter.svg" />
        </a>
      </li>
      <li class="copy-link">
        <a class="share-link share-link-copy-link" href="#" (click)="(copyShare)">
          <img alt="Copy link" src="assets/images/copy-link.svg" />
        </a>
      </li>
    </ul>
  </div>
</section>
