import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CprsTableGridComponent } from './components/cprs-table-grid/cprs-table-grid.component';
import { DesignTableModule as CdTableModule } from '@cop/design/table';
import { DesignFormsModule } from '@cop/design/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectionModule } from '../selection/selection.module';
import { NgxPaginationModule, PaginatePipe } from 'ngx-pagination';
import { DesignComponentsModule } from '@cop/design/components';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CprsTableGridComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CdTableModule,
    RouterModule,
    DesignFormsModule,
    NgxPaginationModule,
    SelectionModule,
    DesignComponentsModule,
  ],
  exports: [CprsTableGridComponent],
  providers: [PaginatePipe],
})
export class TableModule {}
