import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectAllComponent } from './components/select-all/select-all.component';
import { DesignFormsModule } from '@cop/design/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ChildSelectComponent } from './components/child-select/child-select.component';
import { AbsoluteIndexComponent } from './components/absolute-index/absolute-index.component';
import { SelectionListComponent } from './components/selection-list/selection-list.component';
import { DesignComponentsModule } from '@cop/design/components';
import { DesignLayoutModule } from '@cop/design/layout';

@NgModule({
  declarations: [SelectAllComponent, ChildSelectComponent, AbsoluteIndexComponent, SelectionListComponent],
  imports: [CommonModule, DesignFormsModule, DesignComponentsModule, DesignLayoutModule, ReactiveFormsModule],
  exports: [SelectAllComponent, ChildSelectComponent, AbsoluteIndexComponent, SelectionListComponent],
})
export class SelectionModule {}
