import { Deserializable, deserializableProperty } from '../../../shared/interfaces/deserializable';

export class AssociatedRecordsRequest extends Deserializable {
  @deserializableProperty page_number: number = 0;
  @deserializableProperty records_per_page: number = 0;
  @deserializableProperty display_name: string = '';
  @deserializableProperty name_type: string | 'individual' | 'organization' | 'any' = 'any';
  @deserializableProperty sort_field: string | 'relevancy' | 'full_title' | 'type_of_record' | 'representative_date' =
    'relevancy';

  @deserializableProperty sort_order: string | 'asc' | 'desc' = 'asc';

  constructor(json: any) {
    super(json);
    if (json && this['deserializablePropertyArray']) {
      this['deserializablePropertyArray'].forEach((p: any) => {
        if (json[p] !== undefined) {
          this[p] = json[p];
        }
      });
    }
  }
}
