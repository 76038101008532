import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { VoyagerProperty } from '../../services/transformer.service';
import { HyperlinkSchema } from '@src/app/cprs/shared/interfaces/types/types';

export class VoyagerHelper {
    createSimpleVoyagerProperty(label: string, jsonKey: string | string[], tags?: string[]): VoyagerProperty {
        return {
            label,
            jsonKey,
            tags
        }
    }

    createVoyagerProperty(label: string, parentKey: string, subfields: string[], tags?: string[]): VoyagerProperty {
        return {
            label,
            parentKey,
            subfields,
            tags
        }
    }

    createHTMLHyperlinks(label: string, parentKey: string, hyperlinkKey: string[]): VoyagerProperty {
        return {
            label,
            value: (record: ElasticSearchResult) => {
                const hyperlinkFields: HyperlinkSchema[] = [];
                if (Array.isArray(hyperlinkKey)) {
                    const parent_array: any[] = hyperlinkKey.map(key => {
                        return { value: record.get(key), source: key }
                    });

                    parent_array.forEach(a => {
                        if (a && Array.isArray(a.value)) {
                            a.value.forEach((value: any) => {
                                value.source = a.source;
                                hyperlinkFields.push(value)
                            })
                        }
                    })
                }

                const parentField: any[] = record.get(parentKey);

                const links: { id: string, token: string, source?: string }[] = [];

                hyperlinkFields?.forEach(hyperlinks => {
                    // Merging the arrays into an array of objects
                    const minLength = Math.min(hyperlinks.ids.length, hyperlinks.tokens.length);

                    for (let i = 0; i < minLength; i++) {
                        links.push({ id: hyperlinks.ids[i] ?? '', token: hyperlinks.tokens[i] ?? '', source: hyperlinks.source });
                    }
                })

                links.sort((a, b) => {
                    return a.token.length - b.token.length;
                })
                if (links) {
                    links.forEach(link => {
                        parentField?.forEach((note: string, index: number) => {
                            if (note.includes(link.token)) {
                                const search_type = link.source && link.source.includes('recordation') ? 'recordation_numbers' : 'registration_numbers';
                                const replaceStr = '<a href="' + record.createAdvancedSearchURL(link.id, 'AND', search_type, 'exact') + '">' + link.token + '</a>';
                                const replaced = note.replace(link.token, replaceStr);
                                parentField[index] = replaced;
                            }
                        })
                    })
                }

                return parentField;
            },
            tags: ['html']
        }
    }

    combineObject(object: any, keys: string[]) {
        return keys.map(key => object[key]).filter(v => v && v.length >= 1);
    }

    combineObjectArray(objectArray: any[], keys: string[]): any[] {
        return objectArray.map(object => {
            return this.combineObject(object, keys).join(' ');
        }).filter(v => v && v.length >= 1);
    }
}
